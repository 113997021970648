import React from 'react';
import styled from 'styled-components';
import Dotdotdot from 'react-dotdotdot';

const SingleView = styled.p`
  width: 100%;
  background: transparent;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledView = styled.p`
  width: 100%;
  background: transparent;
  text-align: center;
  white-space: pre-line;
  word-break: keep-all;
  overflow: hidden;
`;

const TextareaView = props => {
  return (
    <div className="textarea-view">
      {props.clamp ? (
        <Dotdotdot clamp={props.clamp}>
          <StyledView {...props}>{props.children || '-'}</StyledView>
        </Dotdotdot>
      ) : props.single ? (
        <SingleView {...props}>{props.children || '-'}</SingleView>
      ) : (
        <StyledView {...props}>{props.children || '-'}</StyledView>
      )}
    </div>
  );
};

export default TextareaView;
