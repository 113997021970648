import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import { store } from '../../../shared/store';

const Wrapper = styled.footer`
  .gdlr-icon {
    color: #fff;
    font-size: 16px;
  }

  .footer-container {
    padding-top: 30px;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    #footer-widget-1 {
      text-align: center;
      align-self: center;
    }
    #footer-widget-2,
    #footer-widget-3 {
      padding-top: 30px;
    }
  }

  .copyright-container {
    text-align: center;
  }

  ${breakpoint('xs', 'lg')`
    .footer-container {
      flex-direction: column;

      #footer-widget-1 {
        margin-bottom: 40px;
      }

      #footer-widget-2,
      #footer-widget-3 {
        padding-top: 0px;
      }

      .footer-wrapper .widget.gdlr-widget {
        margin-bottom: 15px;
      }
    }
  `};
`;

const Footer = observer(() => {
  return (
    <Wrapper className="footer-wrapper">
      <div className="footer-container container">
        <div className="footer-column five columns" id="footer-widget-1">
          <div className="logo">
            <img src={store.hotel.logo.url} alt="logo" />
          </div>
        </div>
        <div className="footer-column three columns" id="footer-widget-2">
          <div
            id="recent-posts-5"
            className="widget widget_recent_entries gdlr-item gdlr-widget"
          >
            <h3 className="gdlr-widget-title">Check-In/Out</h3>
            <div className="clear"></div>
            <ul>
              <li>
                Check-In <span>-</span> {store.hotel.checkInTime}
              </li>
              <li>
                Check-Out <span>-</span> {store.hotel.checkOutTime}
              </li>
            </ul>
          </div>
        </div>
        <div className="footer-column four columns" id="footer-widget-3">
          <div
            id="text-10"
            className="widget widget_text gdlr-item gdlr-widget"
          >
            <h3 className="gdlr-widget-title">Address</h3>
            <div className="textwidget">
              <p>{store.hotel.zipcode}</p>
              <p>{store.hotel.curIntro.address1}</p>
              <p>{store.hotel.curIntro.address2}</p>
              {store.hotel.phone && store.hotel.phone.length ? (
                <p>Tel. {store.hotel.phone}</p>
              ) : null}
              {store.hotel.fax && store.hotel.fax.length ? (
                <p>Fax. {store.hotel.fax}</p>
              ) : null}
            </div>
          </div>
        </div>
        <div className="clear"></div>
      </div>

      <div className="copyright-wrapper">
        <div className="copyright-container container">
          ©2019 BAROJABA Inc. all rights reserved.
          <div className="clear"></div>
        </div>
      </div>
    </Wrapper>
  );
});

export default Footer;
