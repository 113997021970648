/* eslint-disable react-hooks/exhaustive-deps, jsx-a11y/anchor-is-valid, no-script-url */
import React, { useEffect, useCallback } from 'react';
import { Modal, Checkbox } from 'antd';
import styled from 'styled-components';
import { observer, useLocalStore } from 'mobx-react-lite';
import Cookies from 'js-cookie';
import { useIntl } from 'react-intl';

import { store } from '../../../shared/store';

const StyledModal = styled(Modal)`
  background: transparent !important;
  width: ${props => props.width} !important;

  .ant-modal-body,
  .ant-modal-content,
  .ant-modal-footer {
    background: transparent !important;
  }

  .ant-modal-footer {
    width: 100%;
    background: rgba(0, 0, 0, 0.7) !important;
    color: white;
    border: none;

    label {
      color: white;
    }
  }
  .ant-modal-content {
    .ant-modal-close-x {
      background: rgba(0, 0, 0, 0.7) !important;
      color: white;
      border-radius: 50%;
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    }
  }
  .ant-modal-body {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const StyledImg = styled.img`
  width: 100%;
  height: auto;
  cursor: pointer;
`;

const Popup = observer(props => {
  const intl = useIntl();
  const localStore = useLocalStore(() => ({
    popups: [],
  }));

  const onChange = useCallback(
    imageName => e => {
      const str = imageName.replace(/\/|\./gi, '');
      if (e.target.checked) {
        const expires = new Date();
        expires.setHours(expires.getHours() + (23 - expires.getHours()));
        expires.setMinutes(expires.getMinutes() + (60 - expires.getMinutes()));
        expires.setSeconds(0);
        Cookies.set(`${str}_popup`, 'true', { expires });
      } else {
        Cookies.remove(`${str}_popup`);
      }
    },
    [],
  );

  useEffect(() => {
    if (store.hotel.homepage.popup && store.hotel.homepage.popup.length) {
      const popups = window.toJS(store.hotel.homepage.popup);
      for (let i = 0; i < popups.length; i++) {
        let str = popups[i].image.name;
        str = str.replace(/\/|\./gi, '');
        const isNotShow = Cookies.get(`${str}_popup`);
        popups[i].visible = !Boolean(isNotShow);
        if (popups[i].width > window.innerWidth) {
          popups[i].width = window.innerWidth - 80;
        }
      }
      localStore.popups = popups;
    }
  }, [store.hotel.popup]);

  return (
    <>
      {localStore.popups.map((item, idx) => (
        <StyledModal
          visible={item.visible}
          onCancel={() => (item.visible = false)}
          footer={
            <Checkbox onChange={onChange(item.image.name)}>
              {intl.formatMessage({ id: 'label.popup.notShowToday' })}
            </Checkbox>
          }
          centered={true}
          key={`popup_${idx}`}
          width={`${item.width}px`}
        >
          <a href={item.url ? item.url : '#!'} target="_blank">
            <StyledImg src={item.image.url} />
          </a>
        </StyledModal>
      ))}
    </>
  );
});

export default Popup;
