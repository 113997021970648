import React from 'react';
import { observer } from 'mobx-react-lite';

const Services = observer(() => {
  return (
    <div id="services">
      <div className="container">
        <div
          className="sixteen columns"
          data-scrollreveal="enter top and move 150px over 1s"
        >
          <div className="section-top">
            <div className="line-dark"></div>
            <h1>services</h1>
            <div className="subtext-top">
              Simplicity, attention to detail...
            </div>
            <div className="line-dark1"></div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="owl-wrap">
          <div id="sync5" className="owl-carousel">
            <div className="item">
              <div className="one-third column">
                <div className="services-box">
                  <img src="/themes/theme0/images/about1.png" alt="" />
                  <h5>NETWORKING</h5>
                  <p>
                    Nam libero tempore, cum soluta nobis est eligendi optio
                    cumque nihil.
                  </p>
                </div>
              </div>
              <div className="one-third column">
                <div className="services-box">
                  <img src="/themes/theme0/images/about2.png" alt="" />
                  <h5>ANALYTICS</h5>
                  <p>
                    Nam libero tempore, cum soluta nobis est eligendi optio
                    cumque nihil.
                  </p>
                </div>
              </div>
              <div className="one-third column">
                <div className="services-box">
                  <img src="/themes/theme0/images/about3.png" alt="" />
                  <h5>DEVELOPMENT</h5>
                  <p>
                    Nam libero tempore, cum soluta nobis est eligendi optio
                    cumque nihil.
                  </p>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="one-third column">
                <div className="services-box">
                  <img src="/themes/theme0/images/about4.png" alt="" />
                  <h5>RESPONSIVE DESIGN</h5>
                  <p>
                    Nam libero tempore, cum soluta nobis est eligendi optio
                    cumque nihil.
                  </p>
                </div>
              </div>
              <div className="one-third column">
                <div className="services-box">
                  <img src="/themes/theme0/images/about5.png" alt="" />
                  <h5>VIDEO MARKETING</h5>
                  <p>
                    Nam libero tempore, cum soluta nobis est eligendi optio
                    cumque nihil.
                  </p>
                </div>
              </div>
              <div className="one-third column">
                <div className="services-box">
                  <img src="/themes/theme0/images/about6.png" alt="" />
                  <h5>SEARCH ENGINE</h5>
                  <p>
                    Nam libero tempore, cum soluta nobis est eligendi optio
                    cumque nihil.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div id="sync6" className="owl-carousel">
            <div className="item"></div>
            <div className="item"></div>
          </div>
        </div>

        <div className="clear"></div>
      </div>
    </div>
  );
});

export default Services;
