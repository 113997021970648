/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useCallback } from 'react';
import { Modal, Form, Button, Divider } from 'antd';
import { observer, useLocalStore } from 'mobx-react-lite';
import { countries } from 'countries-list';
import { useMutation } from 'react-apollo-hooks';

import { store } from '../../../shared/store';
import { useIntl } from '../../../utils/intl';
import withFormComp from '../../../utils/withFormComp';
import { SAVE_MEMBER_USER } from './UserQueries';
import UserRegister from './UserRegister';
import UserDelete from './UserDelete';

const MyInfo = observer(props => {
  const intl = useIntl();
  const form = props.form;
  // const { getFieldDecorator }: WrappedFormUtils = form;
  const localStore = useLocalStore(() => ({
    loading: false,
    confirmDirty: false,
    selectedLang: 'kr',
    countryPhone: '82',
    isReset: false,
  }));

  const saveMemberUser = useMutation(SAVE_MEMBER_USER);

  const handleSubmit = useCallback(e => {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        localStore.loading = true;
        const data = {
          ...values,
          _id: store.user._id,
        };
        data.profile.countryPhone = localStore.countryPhone;
        delete data.profile.confirm;
        data.profile.phone = data.profile.phone.trim().replace(/\s/g, '');
        const result = await saveMemberUser({
          variables: {
            hotel: store.hotel._id,
            data,
          },
        });

        if (result.errors && result.errors.length) {
          window.alert({
            title: intl.formatMessage({ id: result.errors[0].message }),
          });
        } else {
          await store.getUser();
          window.success({
            title: `${intl.formatMessage(
              {
                id: `label.user.myInfo.save.success`,
              },
              { value: store.user.profile.fullName },
            )}`,
          });
          form.resetFields();
          localStore.confirmDirty = false;
          store.signUpVisible = false;
        }
      }
    });
  }, []);

  useEffect(() => {
    if (window.localStorage.lang) {
      localStore.selectedLang = window.localStorage.lang;
      localStore.countryPhone =
        countries[String(window.localStorage.lang).toUpperCase()].phone;
      form.getFieldDecorator('profile.nationality', {
        initialValue: localStore.selectedLang,
      });
    }
  }, [window.localStorage.lang]);

  return (
    <Modal
      title={intl.formatMessage(
        { id: 'label.user.myInfo.title' },
        { value: store.user.profile.fullName },
      )}
      visible={store.myInfoVisible}
      onCancel={() => (store.myInfoVisible = false)}
      footer={null}
      afterClose={() => (localStore.isReset = true)}
      centered={true}
    >
      <Form onSubmit={handleSubmit}>
        <UserRegister form={form} localStore={localStore} />

        <Form.Item style={{ margin: 0 }}>
          <Button
            className="submit"
            type="primary"
            htmlType="submit"
            block={true}
          >
            {intl.formatMessage({ id: 'label.save' })}
          </Button>
        </Form.Item>
      </Form>
      <Divider />
      <UserDelete />
    </Modal>
  );
});

export default withFormComp(Form.create({ name: 'myInfo_form' })(MyInfo));
