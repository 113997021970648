import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { isBrowser } from 'react-device-detect';

import { store } from '../../../shared/store';
import TextareaView from '../../Common/TextareaView';

const SpecialOffer = observer(props => {
  const intl = useIntl();

  useEffect(() => {
    if (isBrowser) {
      const timer = setInterval(() => {
        if (window.$('.owl-carousel.specialOffers').data('owlCarousel')) {
          clearInterval(timer);
          window
            .$('.owl-carousel.specialOffers')
            .on('translated.owl.carousel', function(e) {
              if (e.item.index > e.item.count) {
                e.relatedTarget.next();
              }
            });
        }
      }, 100);
    }
  }, []);

  return (
    <div id="specialOffers" {...props}>
      <div className="offer-section container-fluid">
        <div className="container">
          <div className="section-header section-header-2">
            <h3>Special Offer</h3>
          </div>
          <div className="offer-carousel specialOffers">
            {window.toJS(store.hotelPackages).map((p, pIdx) => (
              <div
                className="col-md-12 col-sm-12 col-xs-12"
                key={`package_list_${pIdx}`}
              >
                <div className="offer-box">
                  <i>
                    <img src={p.images[0].url} alt="" />
                  </i>
                  <div className="offer-content">
                    <h5>{p.curIntro.name}</h5>
                    <TextareaView single={true} style={{ textAlign: 'left' }}>
                      {p.curIntro.description}
                    </TextareaView>
                    <span>
                      {p.applyDate[0]} ~ {p.applyDate[1]}
                    </span>
                    <a
                      className="zoom"
                      href="#!"
                      onClick={store.handleClickMore(p, 'curIntro')}
                    >
                      <i className="fa fa-plus"></i>
                    </a>
                    <a
                      href="#!"
                      title="Book Now"
                      className="book-now"
                      onClick={() =>
                        store.goToLink(
                          `https://booking.barojaba.com/?h=${store.hotel._id}`,
                        )
                      }
                    >
                      {intl.formatMessage({
                        id: 'label.booking',
                      })}
                    </a>
                  </div>
                </div>
              </div>
            ))}

            {window.toJS(store.hotelPromotions).map((p, pIdx) => (
              <div
                className="col-md-12 col-sm-12 col-xs-12"
                key={`promotion_list_${pIdx}`}
              >
                <div className="offer-box">
                  <i>
                    <img src={p.images[0].url} alt="" />
                  </i>
                  <div className="offer-content">
                    <h5>{p.curIntro.name}</h5>
                    <TextareaView single={true} style={{ textAlign: 'left' }}>
                      {p.curIntro.description}
                    </TextareaView>
                    <span>
                      {p.applyDate[0]} ~ {p.applyDate[1]}
                    </span>
                    <a
                      className="zoom"
                      href="#!"
                      onClick={store.handleClickMore(p, 'curIntro')}
                    >
                      <i className="fa fa-plus"></i>
                    </a>
                    <a
                      href="#!"
                      title={intl.formatMessage({
                        id: 'label.booking',
                      })}
                      className="book-now"
                      onClick={() =>
                        store.goToLink(
                          `https://booking.barojaba.com/?h=${store.hotel._id}`,
                        )
                      }
                    >
                      {intl.formatMessage({
                        id: 'label.booking',
                      })}
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
});

export default SpecialOffer;
