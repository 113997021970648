import { ApolloClient } from 'apollo-client';
import { createUploadLink } from 'apollo-upload-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';
import { withClientState } from 'apollo-link-state';
import { ApolloLink, Observable } from 'apollo-link';
import fetch from 'isomorphic-unfetch';
import Cookies from 'js-cookie';

import auth from './Auth';
import theme from './StyledTheme';
import lang from './Lang';
import steps from './Steps';

const defaultOptions = {
  query: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  },
  mutate: {
    errorPolicy: 'all',
  },
};

const request = async operation => {
  const hotelId = window.localStorage.getItem('hotel');
  operation.setContext({
    headers: {
      'BRJB-JWT': Cookies.get(`${hotelId}_jwt`) || '',
      JWT: 'true',
    },
  });
};

const omitTypename = (key, value) => {
  return key === '__typename' ? undefined : value;
};

const requestLink = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    operation.variables = JSON.parse(
      JSON.stringify(operation.variables),
      omitTypename,
    );
  }
  return new Observable(observer => {
    let handle;
    Promise.resolve(operation)
      .then(oper => request(oper))
      .then(() => {
        handle = forward(operation).subscribe({
          next: observer.next.bind(observer),
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer),
        });
      })
      .catch(observer.error.bind(observer));

    return () => {
      if (handle) {
        handle.unsubscribe();
      }
    };
  });
});

const cache = new InMemoryCache();

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        // sendToLoggingService(graphQLErrors);
      }
      if (networkError) {
        // logoutUser();
      }
    }),
    requestLink,
    withClientState({
      defaults: {
        ...lang.defaults,
        ...auth.defaults,
        ...theme.defaults,
        ...steps.defaults,
      },
      resolvers: {},
      cache,
    }),
    createUploadLink({
      fetch,
      uri:
        process.env['NODE_ENV'] === 'production'
          ? 'https://api.barojaba.com/api'
          : 'http://localhost:4000/api',
      // uri: 'http://211.222.190.152:4000/api',
    }),
  ]),
  cache,
  resolvers: {
    Mutation: {
      ...lang.mutations,
      ...auth.mutations,
      ...theme.mutations,
      ...steps.mutations,
    },
  },
  defaultOptions,
});

export default client;
