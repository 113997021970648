import React from 'react';
import { observer } from 'mobx-react-lite';

const Parallax2 = observer(() => {
  return (
    <div id="sep2">
      <div className="parallax2"></div>
      <div className="container">
        <div className="four columns">
          <div className="facts-wrap">
            <div className="facts-wrap-num">
              <span className="counter">87</span>
            </div>
            <h5>Clients</h5>
          </div>
        </div>
        <div className="four columns">
          <div className="facts-wrap">
            <div className="facts-wrap-num">
              <span className="counter">25</span>
            </div>
            <h5>Awards</h5>
          </div>
        </div>
        <div className="four columns">
          <div className="facts-wrap">
            <div className="facts-wrap-num">
              <span className="counter">68</span>
            </div>
            <h5>Projects</h5>
          </div>
        </div>
        <div className="four columns">
          <div className="facts-wrap">
            <div className="facts-wrap-num">
              <span className="counter">46</span>
            </div>
            <h5>Mails</h5>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Parallax2;
