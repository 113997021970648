import React from 'react';
import { Tag } from 'antd';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

import { store } from '../../../shared/store';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
const Title = styled.div`
  font-weight: bold;
  font-size: 18px;
  text-transform: uppercase;
  margin-bottom: 5px;
`;
const SubTitle = styled.div`
  color: #a8a8a8;
  font-weight: bold;
  font-size: 100%;
  text-transform: uppercase;
  margin-bottom: 15px;
`;
const TagWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  .ant-tag {
    font-weight: bold;
    font-size: 100%;
  }
`;

const InstagramTitle = observer(() => {
  const intl = useIntl();

  return (
    <Wrapper>
      <Title className="title">
        {intl.formatMessage(
          { id: 'label.instagram.title' },
          { value: store.hotel.curIntro.name },
        )}
      </Title>
      <SubTitle className="sub-title">
        {intl.formatMessage(
          { id: 'label.instagram.subTitle' },
          { value: store.hotel.curIntro.name },
        )}
      </SubTitle>
      <TagWrapper>
        {window.toJS(store.hotel.instagramTags).map((item, idx) => (
          <Tag key={`tags_${idx}`}>#{item}</Tag>
        ))}
      </TagWrapper>
    </Wrapper>
  );
});

export default InstagramTitle;
