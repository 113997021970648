/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useCallback } from 'react';
import { Form, Input, Button, Modal } from 'antd';
import { observer, useLocalStore } from 'mobx-react-lite';
import moment from 'moment';

import withFormComp from '../../../utils/withFormComp';
import { useIntl } from '../../../utils/intl';
import { store } from '../../../shared/store';
import { useMutation } from 'react-apollo-hooks';
import { FORGOT_PASSWORD } from '../User/UserQueries';
import { randomString } from '../../../utils/common';
import Loading from '../Loading';

const ForgotPassword = observer(props => {
  const intl = useIntl();
  const localStore = useLocalStore(() => ({
    loading: false,
    emailLabel: {
      subject: intl.formatMessage(
        { id: 'label.sign.forgot.email.subject' },
        { hotelName: store.hotel.curIntro.name },
      ),
      hotelName: store.hotel.curIntro.name,
      title1: intl.formatMessage({ id: 'label.sign.forgot.email.title1' }),
      title2: intl.formatMessage({ id: 'label.sign.forgot.email.title2' }),
      title3: intl.formatMessage({ id: 'label.sign.forgot.email.title3' }),
      title4: intl.formatMessage({ id: 'label.sign.forgot.email.title4' }),
      title5: intl.formatMessage({ id: 'label.sign.forgot.email.title5' }),
      newPassword: intl.formatMessage({
        id: 'label.sign.forgot.email.newPassword',
      }),
      changeDateLabel: intl.formatMessage({
        id: 'label.sign.forgot.email.changeDateLabel',
      }),
    },
  }));
  const forgotPassword = useMutation(FORGOT_PASSWORD);

  const form = props.form;
  const { getFieldDecorator } = form;

  const handleSubmit = useCallback(e => {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        localStore.loading = true;
        const now = moment();
        const data = {
          ...values,
          password: randomString(8),
          changeDate: intl.formatMessage(
            { id: 'label.sign.forgot.email.changeDate' },
            {
              year: now.year(),
              month: now.month(),
              date: now.date(),
              hour: now.hour(),
              min: now.minute(),
              sec: now.second(),
            },
          ),
        };
        const result = await forgotPassword({
          variables: {
            hotel: store.hotel._id,
            data,
            src: window.localStorage.getItem('jimp_header'),
            label: JSON.stringify(localStore.emailLabel),
            fromEmail: store.hotel.email,
          },
        });

        if (result.errors && result.errors.length) {
          window.alert({
            title: intl.formatMessage({ id: result.errors[0].message }),
          });
        } else {
          window.success({
            title: intl.formatMessage({ id: 'label.sign.forgot.success' }),
          });
          form.resetFields();
          props.handleClose();
        }
        localStore.loading = false;
      }
    });
  }, []);

  if (localStore.loading) {
    return <Loading />;
  }

  return (
    <Modal
      wrapClassName="forgot-modal"
      visible={store.forgotVisible}
      onCancel={() => (store.forgotVisible = false)}
      footer={null}
      centered={true}
    >
      <Form onSubmit={handleSubmit} style={{ marginTop: '30px' }}>
        <Form.Item>
          {getFieldDecorator('email', {
            rules: [
              {
                type: 'email',
                message: intl.formatMessage({ id: 'error.email.valid' }),
              },
              {
                required: true,
                message: intl.formatMessage({ id: 'error.email.required' }),
              },
            ],
          })(
            <Input
              autoComplete="off"
              placeholder={intl.formatMessage({
                id: 'label.placeholder.forgot.email',
              })}
            />,
          )}
        </Form.Item>
        <Form.Item style={{ margin: 0 }}>
          <Button type="primary" htmlType="submit" block={true}>
            {intl.formatMessage({ id: 'label.sign.forgot.submit' })}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
});

export default withFormComp(
  Form.create({ name: 'forgot_password_form' })(ForgotPassword),
);
