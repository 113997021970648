/* eslint-disable react-hooks/exhaustive-deps, jsx-a11y/anchor-is-valid */
import React, { useEffect, useCallback } from 'react';
import { Button, Select, Modal, Icon } from 'antd';
import { observer, useLocalStore } from 'mobx-react-lite';
import styled from 'styled-components';
import { countries } from 'countries-list';
import { useIntl } from 'react-intl';
import breakpoint from 'styled-components-breakpoint';

import { store } from '../../../shared/store';
import { SET_LANG, SET_USER_LOGOUT } from '../../../shared/queries';
import SignUp from '../Sign/SignUp';
import SignIn from '../Sign/SignIn';
import { useMutation } from 'react-apollo-hooks';

const { Option } = Select;

const Wrapper = styled.div`
  max-width: 1140px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${props => (props.color ? props.color : '#fff')};

  .ant-select {
    min-width: 120px;
  }

  .phone {
    margin-left: 20px;
  }

  ${breakpoint('xs', 'lg')`
    margin-top: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .m-col {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  `};
`;

const Section = styled.div`
  display: flex;
  justify-content: ${props => (props.justify ? props.justify : 'flex-start')};
  align-items: center;
`;

const Col = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${breakpoint('xs', 'lg')`
    margin-bottom: 15px;
  `};
`;

const StyledBtn = styled(Button)`
  background: transparent !important;
  color: ${props => (props.btncolor ? props.btncolor : '#fff')} !important;
  border-color: ${props =>
    props.btncolor ? props.btncolor : '#fff'} !important;
  margin-right: 10px !important;

  &:hover {
    background: #fff !important;
    color: #555 !important;
  }
`;

const HeaderTop = observer(props => {
  const intl = useIntl();
  const localStore = useLocalStore(() => ({
    hotelCountryPhone: '',
  }));

  const setLang = useMutation(SET_LANG);
  const setUserLogout = useMutation(SET_USER_LOGOUT);

  const onClickLogout = useCallback(() => {
    window.ask({
      title: intl.formatMessage({ id: 'label.sign.out.confirm' }),
      onOk() {
        setUserLogout();
      },
      okText: intl.formatMessage({ id: 'label.ok' }),
      cancelText: intl.formatMessage({ id: 'label.cancel' }),
      centered: true,
    });
  }, []);

  const handleChangeLang = useCallback(value => {
    setLang({ variables: { current: value } });
  }, []);

  const isFollowUs = useCallback(() => {
    let visible = false;
    const sns = window.toJS(store.hotel.sns);
    if (sns) {
      const f = Object.keys(sns).filter(
        key => key !== '__typename' && sns[key] && sns[key].length,
      );
      if (f.length) {
        visible = true;
      }
    }
    return visible;
  }, []);

  useEffect(() => {
    const langs = window.toJS(store.hotel.langs);
    const defLang = String(langs[0]).toUpperCase();
    localStore.hotelCountryPhone = countries[defLang].phone;
  }, []);

  return (
    <Wrapper className="container">
      <Section className="m-col">
        <Col>
          <Icon type="mail" /> {store.hotel.email}
        </Col>
        <Col className="phone">
          <a
            href={`tel:(+${localStore.hotelCountryPhone})${store.hotel.phone}`}
            title="Phone"
          >
            <Icon type="phone" /> (+{localStore.hotelCountryPhone}){' '}
            {store.hotel.phone}
          </a>
        </Col>
      </Section>
      <Section>
        {store.user ? (
          <>
            <StyledBtn type="primary" onClick={onClickLogout} {...props}>
              {intl.formatMessage({ id: 'label.sign.out' })}
            </StyledBtn>
            <StyledBtn
              type="primary"
              onClick={() => (store.myInfoVisible = true)}
              {...props}
            >
              {intl.formatMessage({ id: 'label.user.myInfo' })}
            </StyledBtn>
          </>
        ) : (
          <>
            <StyledBtn
              type="primary"
              onClick={() => (store.signUpVisible = true)}
              {...props}
            >
              {intl.formatMessage({ id: 'label.sign.up' })}
            </StyledBtn>
            <StyledBtn
              type="primary"
              onClick={() => (store.signInVisible = true)}
              {...props}
            >
              {intl.formatMessage({ id: 'label.sign.in' })}
            </StyledBtn>
          </>
        )}
        <Select
          defaultValue={window.localStorage.getItem('lang') || 'kr'}
          onChange={handleChangeLang}
        >
          {window.toJS(store.hotel.langs).map(item => (
            <Option value={item} key={item}>
              {countries[String(item).toUpperCase()].emoji}&nbsp;
              {intl.formatMessage({ id: `label.${item}` })}
            </Option>
          ))}
        </Select>
      </Section>

      <Modal
        title={intl.formatMessage(
          { id: 'label.sign.in.title' },
          { value: store.hotel.curIntro.name },
        )}
        visible={store.signInVisible}
        onCancel={() => (store.signInVisible = false)}
        footer={null}
        centered={true}
      >
        <SignIn />
      </Modal>

      <SignUp localStore={localStore} />
    </Wrapper>
  );
});

export default HeaderTop;
