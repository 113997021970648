import React from 'react';
import { observer } from 'mobx-react-lite';

const Parallax3 = observer(() => {
  return (
    <div id="sep3">
      <div className="parallax3"></div>
      <div className="container">
        <div
          className="sixteen columns"
          data-scrollreveal="enter bottom and move 250px over 1s"
        >
          <p>BAROJABA</p>
          <h3>회사 소개서</h3>
          <div className="line-paralax3"></div>
          <div className="cl-effect-12">
            <a href="/barojaba-store/BAROJABA+Inc.pdf" target="_blank">
              다운로드
            </a>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Parallax3;
