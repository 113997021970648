/* eslint-disable react-hooks/exhaustive-deps, jsx-a11y/anchor-is-valid, no-script-url */
import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { Instagram, InstagramTitle } from '../../Common/Instagram';

const Wrapper = styled.div``;

const HashTag = observer(props => {
  return (
    <Wrapper id="hashtag" {...props}>
      <div className="custom-listing">
        <section id="listing">
          <div className="container">
            <div className="row">
              <div className="listing-content col-md-12">
                <div className="listing-pagination" style={{ zIndex: 1 }}>
                  <InstagramTitle />
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <Instagram />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Wrapper>
  );
});

export default HashTag;
