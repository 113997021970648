import Cookies from 'js-cookie';

const auth = {
  __typename: 'Auth',
  isLoggedIn: Boolean(
    Cookies.get(`${window.localStorage.getItem('hotel')}_jwt`),
  ),
};

export default auth;
