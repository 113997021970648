/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useCallback, useEffect } from 'react';
import { observer, useLocalStore } from 'mobx-react-lite';
import { Row, Col, Input, Form, Select } from 'antd';
import { countries } from 'countries-list';

import { useIntl } from '../../../utils/intl';
import { validateToPhone } from '../../../utils/formValidate';
import { store } from '../../../shared/store';

const { Option } = Select;

const UserRegister = observer(props => {
  const intl = useIntl();
  const form = props.form;
  const { getFieldDecorator } = form;
  const localStore = props.localStore;
  const registerStore = useLocalStore(() => ({
    selectedUser: { profile: {} },
    isUser: false,
  }));

  const handleConfirmBlur = useCallback(e => {
    const { value } = e.target;
    localStore.confirmDirty = localStore.confirmDirty || !!value;
  }, []);
  const validateToNextPassword = useCallback((rule, value, callback) => {
    if (value && !/^(?=.*[a-zA-Z])((?=.*\d)|(?=.*\W)).{6,20}$/.test(value)) {
      return callback(intl.formatMessage({ id: 'error.password.valid' }));
    }
    if (value && localStore.confirmDirty) {
      form.validateFields(['profile.confirm'], { force: true });
    }
    callback();
  }, []);
  const compareToFirstPassword = useCallback((rule, value, callback) => {
    if (value && value !== form.getFieldValue('profile.password')) {
      callback(intl.formatMessage({ id: 'error.password.incorrect' }));
    } else {
      callback();
    }
  }, []);
  const onChangeCountries = useCallback(value => {
    const key = String(value).toLocaleUpperCase();
    localStore.countryPhone = countries[key].phone;
  }, []);

  useEffect(() => {
    if (store.user && store.user._id) {
      registerStore.selectedUser = window.toJS(store.user);

      registerStore.isUser = true;
      if (localStore.isReset) {
        form.resetFields();
        localStore.isReset = false;
        const key = String(
          registerStore.selectedUser.profile.nationality,
        ).toUpperCase();
        localStore.countryPhone = countries[key].phone;
      }
    } else {
      registerStore.selectedUser = { profile: {} };
      registerStore.isUser = false;
    }
  }, [store.user, localStore.isReset]);

  return (
    <>
      <Row gutter={16}>
        <Col md={24} lg={12}>
          <Form.Item label={intl.formatMessage({ id: 'label.sign.up.email' })}>
            {getFieldDecorator('email', {
              initialValue: registerStore.selectedUser.email,
              rules: [
                {
                  type: 'email',
                  message: intl.formatMessage({
                    id: 'error.email.valid',
                  }),
                },
                {
                  required: true,
                  message: intl.formatMessage({
                    id: 'error.email.required',
                  }),
                },
              ],
            })(<Input autoComplete="off" disabled={registerStore.isUser} />)}
          </Form.Item>
          <Form.Item
            label={intl.formatMessage({ id: 'label.sign.up.password' })}
            hasFeedback={true}
          >
            {getFieldDecorator('profile.password', {
              rules: [
                {
                  required: !registerStore.isUser,
                  message: intl.formatMessage({
                    id: 'error.password.required',
                  }),
                },
                {
                  validator: validateToNextPassword,
                },
              ],
            })(<Input.Password autoComplete="off" />)}
          </Form.Item>
          <Form.Item
            label={intl.formatMessage({
              id: 'label.sign.up.passwordConfirm',
            })}
            hasFeedback={true}
          >
            {getFieldDecorator('profile.confirm', {
              rules: [
                {
                  required: Boolean(form.getFieldValue('profile.password')),
                  message: intl.formatMessage({
                    id: 'error.password.required',
                  }),
                },
                {
                  validator: compareToFirstPassword,
                },
              ],
            })(
              <Input.Password autoComplete="off" onBlur={handleConfirmBlur} />,
            )}
          </Form.Item>
        </Col>
        <Col md={24} lg={12}>
          {localStore.selectedLang === 'kr' ? (
            <>
              <Form.Item
                label={intl.formatMessage({
                  id: 'label.sign.up.lastName',
                })}
              >
                {getFieldDecorator('profile.lastName', {
                  initialValue: registerStore.selectedUser.profile.lastName,
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({
                        id: 'error.lastName.required',
                      }),
                    },
                  ],
                })(<Input autoComplete="off" />)}
              </Form.Item>
              <Form.Item
                label={intl.formatMessage({
                  id: 'label.sign.up.firstName',
                })}
              >
                {getFieldDecorator('profile.firstName', {
                  initialValue: registerStore.selectedUser.profile.firstName,
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({
                        id: 'error.firstName.required',
                      }),
                    },
                  ],
                })(<Input autoComplete="off" />)}
              </Form.Item>
            </>
          ) : (
            <>
              <Form.Item
                label={intl.formatMessage({
                  id: 'label.sign.up.firstName',
                })}
              >
                {getFieldDecorator('profile.firstName', {
                  initialValue: registerStore.selectedUser.profile.firstName,
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({
                        id: 'error.firstName.required',
                      }),
                    },
                  ],
                })(<Input autoComplete="off" />)}
              </Form.Item>
              <Form.Item
                label={intl.formatMessage({
                  id: 'label.sign.up.lastName',
                })}
              >
                {getFieldDecorator('profile.lastName', {
                  initialValue: registerStore.selectedUser.profile.lastName,
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({
                        id: 'error.lastName.required',
                      }),
                    },
                  ],
                })(<Input autoComplete="off" />)}
              </Form.Item>
            </>
          )}

          <Form.Item label={intl.formatMessage({ id: 'label.sign.up.phone' })}>
            {getFieldDecorator('profile.phone', {
              initialValue: registerStore.selectedUser.profile.phone,
              rules: [
                {
                  validator: validateToPhone(intl),
                },
                {
                  required: true,
                  message: intl.formatMessage({
                    id: 'error.phone.required',
                  }),
                },
              ],
            })(
              <Input
                addonBefore={localStore.countryPhone}
                placeholder={intl.formatMessage({
                  id: 'label.placeholder.phone',
                })}
                autoComplete="off"
              />,
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24}>
          <Form.Item
            label={intl.formatMessage({
              id: 'label.sign.up.nationality',
            })}
          >
            {getFieldDecorator('profile.nationality', {
              initialValue:
                registerStore.selectedUser.profile.nationality ||
                localStore.selectedLang,
            })(
              <Select
                onChange={onChangeCountries}
                showSearch={true}
                optionFilterProp="label"
              >
                {Object.keys(countries).map(key => (
                  <Option
                    value={key.toLocaleLowerCase()}
                    key={key}
                    label={countries[key].native}
                  >
                    {countries[key].emoji}&nbsp;{countries[key].native}
                  </Option>
                ))}
              </Select>,
            )}
          </Form.Item>
        </Col>
      </Row>
    </>
  );
});

export default UserRegister;
