import React from 'react';
import { observer } from 'mobx-react-lite';

const Solution = observer(() => {
  return (
    <div id="solution">
      <div className="container">
        <div
          className="sixteen columns"
          data-scrollreveal="enter top and move 150px over 1s"
        >
          <div className="section-top">
            <div className="line-dark"></div>
            <h1>solution</h1>
            <div className="subtext-top">
              트랜드를 선도하는 BAROJABA만의 최적의 솔루션
            </div>
            <div className="line-dark1"></div>
          </div>
        </div>
      </div>

      <div className="clear"></div>

      <div className="portfolio-wrap grid">
        <div className="grid-sizer"></div>
        <div className="portfolio-box photography web-design grid-item">
          <a className="expander" href="#!" title="">
            <img src="/themes/theme0/images/products/1.png" alt="" />
            <div className="mask"></div>
            {/* <div className="line-folio"></div> */}
            {/* <div className="line-folio1"></div> */}
            <br />
            <h4 className="description">
              <span className="title">
                홈페이지 예약 솔루션(Booking Engine)
              </span>
              <br />
              최근 호텔 홈페이지 방문 비율이 무려 70% 이며
              <br />
              그 중 95% 이상이 호텔 예약 시스템을 확인하고 있습니다.
              <br />
              실시간 OTA 가격비교 기능과 간단한 예약 기능으로 예약율을 높힙니다.
            </h4>
          </a>
        </div>
        <div className="portfolio-box photography web-design grid-item">
          <a className="expander" href="#!" title="">
            <img src="/themes/theme0/images/products/2.png" alt="" />
            <div className="mask"></div>
            {/* <div className="line-folio"></div> */}
            {/* <div className="line-folio1"></div> */}
            <br />
            <h4 className="description">
              <span className="title">홈페이지 구축</span>
              <br />
              예약율을 높힐 수 있도록 디자인된 2000여개의 템플릿을 보유하고
              있으며
              <br />
              트랜디한 홈페이지를 제공해 드립니다.
            </h4>
          </a>
        </div>
        <div className="portfolio-box photography web-design grid-item">
          <a className="expander" href="#!" title="">
            <img src="/themes/theme0/images/products/3.png" alt="" />
            <div className="mask"></div>
            {/* <div className="line-folio"></div> */}
            {/* <div className="line-folio1"></div> */}
            <br />
            <h4 className="description">
              <span className="title">비용부담 최소화</span>
              <br />
              유지 보수료가 없어 연간 최소 250만원정도를
              <br />
              절감하실 수 있습니다.
            </h4>
          </a>
        </div>
        <div className="portfolio-box photography web-design grid-item">
          <a className="expander" href="#!" title="">
            <img src="/themes/theme0/images/products/4.png" alt="" />
            <div className="mask"></div>
            {/* <div className="line-folio"></div> */}
            {/* <div className="line-folio1"></div> */}
            <br />
            <h4 className="description">
              <span className="title">회원관리 최적화</span>
              <br />
              BAROJABA만의 간편한 회원가입과 관리 기능을 제공해 드립니다.
            </h4>
          </a>
        </div>
        <div className="portfolio-box photography web-design grid-item">
          <a className="expander" href="#!" title="">
            <img src="/themes/theme0/images/products/5.png" alt="" />
            <div className="mask"></div>
            {/* <div className="line-folio"></div> */}
            {/* <div className="line-folio1"></div> */}
            <br />
            <h4 className="description">
              <span className="title">해시태그 연동</span>
              <br />
              SNS 해시태그를 홈페이지에 연동하여
              <br />
              호텔의 최근 Moment를 제공해 드립니다.
              <br />
              (호텔 SNS 계정 연동 방식 아님)
            </h4>
          </a>
        </div>
        <div className="portfolio-box photography web-design grid-item">
          <a className="expander" href="#!" title="">
            <img src="/themes/theme0/images/products/6.png" alt="" />
            <div className="mask"></div>
            {/* <div className="line-folio"></div> */}
            {/* <div className="line-folio1"></div> */}
            <br />
            <h4 className="description">
              <span className="title">CMS 연동</span>
              <br />
              TL-LINCOLN CMS 연동으로 관리가 용이하도록 구현되었습니다.
            </h4>
          </a>
        </div>
        <div className="portfolio-box photography web-design grid-item">
          <a className="expander" href="#!" title="">
            <img src="/themes/theme0/images/products/7.png" alt="" />
            <div className="mask"></div>
            {/* <div className="line-folio"></div> */}
            {/* <div className="line-folio1"></div> */}
            <br />
            <h4 className="description">
              <span className="title">회원 단체 메일 발송</span>
              <br />
              고객별 맞춤 특별 상품을 간편하게 홍보하실 수 있도록
              <br />
              단체 메일 기능을 제공해 드립니다.
            </h4>
          </a>
        </div>
        <div className="portfolio-box photography web-design grid-item">
          <a className="expander" href="#!" title="">
            <img src="/themes/theme0/images/products/8.png" alt="" />
            <div className="mask"></div>
            {/* <div className="line-folio"></div> */}
            {/* <div className="line-folio1"></div> */}
            <br />
            <h4 className="description">
              <span className="title">다중 호텔 운영사를 위한 솔루션</span>
              <br />
              다중 호텔 운영 시 호텔간의 회원 정보 연동으로
              <br />
              회원관리의 편의성을 극대화 하였습니다.
            </h4>
          </a>
        </div>
      </div>
    </div>
  );
});

export default Solution;
