/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useCallback } from 'react';
import { observer, useLocalStore } from 'mobx-react-lite';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { useIntl } from 'react-intl';
import { useQuery } from 'react-apollo-hooks';
import { isMobile } from 'react-device-detect';

import StyledText from '../../StyledComp/StyledText';
import BestPriceWrapper from './BestPriceWrapper';
import ColorTextFlowWrapper from './ColorTextFlowWrapper';
import { store } from '../../../shared/store';
import { GET_OTA_DATAS, GET_RANGE_MANAGEMENT } from './TodayPriceQueries';
import moment from 'moment';
import Loading from '../Loading';

const Wrapper = styled.div`
  color: #c8c8c8;
  border: 1px solid #555;
  background: rgba(0, 0, 0, 0.4);
  box-shadow: 0px 2px 4px 0px rgba(170, 170, 170, 0.3);
  margin-bottom: 10px;
  overflow: hidden;

  .today-price {
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
      1px 1px 0 #000;
  }

  .nowrap {
    white-space: nowrap;
  }

  .left-label {
    text-align: left;
  }

  ${props =>
    props.maxHeight &&
    `
    max-height: ${props.maxHeight};
    overflow-y: auto;
  `};

  ${breakpoint('xs', 'lg')`
    width: 100%;

    .left-label {
      text-align: center;
    }
  `};
`;

const Row = styled.div`
  display: flex;
  justify-content: ${props =>
    props.justify ? props.justify : 'space-between'};
  align-items: center;
  padding: 5px 10px;

  div {
    ${props => props.flex && `flex: 1`};
  }
`;

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  padding: 10px;
  border: 1px dashed #bf953f;
  font-size: 16px;
`;

const Arrow = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  float: right;

  span {
    border-top: 0.5px dotted #c8c8c8;
    border-collapse: collapse;
    width: 100%;
    height: 1px;
  }
`;

const OTAWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  ${props =>
    props.otaMaxHeight &&
    `
    max-height: ${props.otaMaxHeight};
    overflow-y: auto;
  `}
`;

const TodayPriceWidget = observer(props => {
  const intl = useIntl();

  const localStore = useLocalStore(() => ({
    loading: true,
    otaLoaded: false,
    managementLoaded: false,
    firstRoomLoaded: false,
    diff: store.startDate.diff(moment(moment().format('YYYY-MM-DD')), 'days'),
    room: null,
    todayPrice: 0,
  }));

  const otaData = useQuery(GET_OTA_DATAS, {
    variables: {
      hotel: store.hotel._id,
      checkInDate: store.startDate.format('YYYY-MM-DD'),
      isOTA: true,
      otaOptional: store.hotel.otaOptional || [],
    },
    fetchPolicy: 'network-only',
  });
  const managements = useQuery(GET_RANGE_MANAGEMENT, {
    variables: {
      hotel: store.hotel._id,
      startMonth: store.startDate.format('YYYY-MM'),
      endMonth: store.endDate.format('YYYY-MM'),
    },
    fetchPolicy: 'network-only',
  });

  const calcFirstRoom = useCallback(rooms => {
    localStore.room = null;
    if (localStore.managementLoaded) {
      let f = rooms.find(item => {
        let isVisible = true;
        const visibleCheck = { roomOnly: [], product: [] };
        const curDate = moment(store.startDate);
        for (let i = 0; i < store.stayDays; i++) {
          const dayIdx = curDate.date() - 1;
          const applyMonth = curDate.format('YYYY-MM');
          let isRoomCnt = false;

          if (item.managementData && item.managementData[applyMonth]) {
            if (item.managementData[applyMonth].rates[dayIdx] > 0) {
              isRoomCnt = true;
            }
          }
          visibleCheck.roomOnly[i] = isRoomCnt;

          const f = item.hotelProducts.find(pItem => {
            return (
              pItem.managementData &&
              pItem.managementData[applyMonth] &&
              pItem.managementData[applyMonth].rates[dayIdx] > 0
            );
          });
          visibleCheck.product[i] = Boolean(f);
          curDate.add(1, 'days');
        }

        let fRoomNA = visibleCheck.roomOnly.filter(item => item === false);
        let fProductNA = visibleCheck.product.filter(item => item === false);

        if (fRoomNA.length && fProductNA.length) {
          isVisible = false;
        }

        return isVisible;
      });

      if (f) {
        localStore.room = f;
        if (props.popupStore) {
          props.popupStore.visible = true;
        }
      }
    }
    localStore.firstRoomLoaded = true;
  }, []);

  useEffect(() => {
    if (!localStore.otaLoaded && otaData.data && otaData.data.crawlerDatas) {
      const crawlerDatas = otaData.data.crawlerDatas;
      const rooms = window.toJS(store.hotel.rooms);
      store.hotel.rooms = [];

      for (let i = 0; i < rooms.length; i++) {
        rooms[i].ota = [];

        for (const key in rooms[i].otaName) {
          const fCrawlerData = crawlerDatas.find(item => item.urlHost === key);
          if (fCrawlerData) {
            const roomName = rooms[i].otaName[key].replace(
              /[.*+?^${}()|[\]\\]/g,
              '\\$&',
            );
            const regExp = new RegExp(roomName, 'ig');
            const f = fCrawlerData.rooms.find(item => regExp.test(item.name));

            if (f && f.price) {
              rooms[i].ota.push({
                name: intl.formatMessage({ id: `label.ota.${key}` }),
                price: f.price,
              });
            }
          }
        }
      }
      store.hotel.rooms = rooms;
      localStore.otaLoaded = true;
    }
  }, [otaData.data]);

  useEffect(() => {
    if (
      localStore.otaLoaded &&
      !localStore.managementLoaded &&
      managements &&
      managements.data &&
      managements.data.rangeManagements
    ) {
      const rooms = window.toJS(store.hotel.rooms);
      const dayIdx = store.startDate.date() - 1;

      const rangeManagements = managements.data.rangeManagements;
      if (rangeManagements && rangeManagements.length) {
        for (let i = 0; i < rooms.length; i++) {
          rooms[i].managementData = {};
          for (let j = 0; j < rangeManagements.length; j++) {
            const applyMonth = rangeManagements[j].applyMonth;
            if (rangeManagements[j].managementData.roomOnly) {
              rooms[i].managementData[applyMonth] =
                rangeManagements[j].managementData.roomOnly[rooms[i]._id];
            }
          }

          if (store.hotel.isTest) {
            rooms[i].ota = [];
            const rates =
              rooms[i].managementData[store.startDate.format('YYYY-MM')].rates[
                dayIdx
              ];
            if (rates) {
              for (const key in rooms[i].otaName) {
                let price = rates;
                price += Math.floor(Math.random() * 30000) + 20000;
                price = Math.round(price * 0.1) * 10;
                rooms[i].ota.push({
                  name: intl.formatMessage({ id: `label.ota.${key}` }),
                  price,
                });
              }
            }
          }

          for (let j = 0; j < rooms[i].hotelProducts.length; j++) {
            const product = rooms[i].hotelProducts[j];
            rooms[i].hotelProducts[j].managementData = {};
            for (let k = 0; k < rangeManagements.length; k++) {
              const applyMonth = rangeManagements[k].applyMonth;
              if (
                rangeManagements[k].managementData[product.productType] &&
                rangeManagements[k].managementData[product.productType][
                  rooms[i]._id
                ]
              ) {
                rooms[i].hotelProducts[j].managementData[applyMonth] =
                  rangeManagements[k].managementData[product.productType][
                    rooms[i]._id
                  ][product._id];

                // roomOnly count 연동
                rooms[i].hotelProducts[j].managementData[applyMonth].count =
                  rooms[i].managementData[applyMonth].count;
              }
            }
          }
        }
      }
      localStore.managementLoaded = true;
      calcFirstRoom(rooms);
    }
  }, [localStore.otaLoaded, managements.data, store.hotel.rooms]);

  useEffect(() => {
    if (localStore.firstRoomLoaded) {
      if (localStore.room) {
        const room = window.toJS(localStore.room);
        const curDate = moment(store.startDate);
        let charge = 0;
        let tax = 0;
        let serviceCharge = 0;
        for (let i = 0; i < store.stayDays; i++) {
          const dayIdx = curDate.date() - 1;
          const applyMonth = curDate.format('YYYY-MM');

          if (
            !room.managementData ||
            !room.managementData[applyMonth] ||
            !room.managementData[applyMonth].rates[dayIdx]
          ) {
            for (let j = 0; j < room.hotelProducts.length; j++) {
              const product = window.toJS(room.hotelProducts[j]);
              if (
                !product.managementData ||
                !product.managementData[applyMonth] ||
                !product.managementData[applyMonth].rates[dayIdx]
              ) {
                continue;
              }

              let dayCharge = product.managementData[applyMonth].rates[dayIdx];
              if (product.productType === 'promotion') {
                let isPromotionDiscount = true;
                if (product.offerType === 'once' && j > 0) {
                  isPromotionDiscount = false;
                }

                if (isPromotionDiscount) {
                  switch (product.discountType) {
                    case 'per':
                      dayCharge =
                        dayCharge -
                        Math.floor((dayCharge * product.discount) / 100);
                      break;
                    case 'fixed':
                      dayCharge = dayCharge - product.discount;
                      break;
                    case 'stay':
                      const f = product.stayDiscount.find(
                        sd => sd.stayDay === j + 1,
                      );
                      if (f) {
                        if (f.isPer) {
                          dayCharge =
                            dayCharge -
                            Math.floor((dayCharge * f.discount) / 100);
                        } else {
                          dayCharge = dayCharge - f.discount;
                        }
                      }
                      break;
                    default:
                      break;
                  }
                }
              }
              charge += dayCharge;

              break; // 첫번째 알고리즘 완료 시
            }
          } else {
            charge += room.managementData[applyMonth].rates[dayIdx];
          }

          if (store.hotel.optional.find(opt => opt === 'tax')) {
            tax = Math.floor(charge * 0.1);
          }
          if (store.hotel.optional.find(opt => opt === 'serviceCharge')) {
            serviceCharge = Math.floor(charge * 0.1);
          }
          charge = charge - tax - serviceCharge;
          curDate.add(1, 'days');
        }

        localStore.todayPrice = charge;
      }

      localStore.loading = false;
    }
  }, [localStore.firstRoomLoaded, localStore.room]);

  useEffect(() => {
    if (!localStore.loading) {
      const timer = setInterval(() => {
        let el = document.querySelector('#todayPriceTitle');
        if (el) {
          clearInterval(timer);
          let chars1 = intl.formatMessage({ id: 'label.todayPrice.title1' });
          let chars2 = intl.formatMessage({ id: 'label.todayPrice.title2' });
          chars1 = chars1.trim().split('');
          chars2 = chars2.trim().split('');
          el.innerHTML =
            '<span>' +
            (!isMobile ? chars1.join('</span><span>') + '&nbsp;' : '') +
            chars2.join('</span><span>') +
            '</span>';
        }
        el = document.querySelector('#todayPrice');
        if (el) {
          let chars1 = intl.formatMessage({ id: 'label.todayPrice.today' });
          let chars2 = intl.formatMessage({ id: 'label.todayPrice.price' });
          chars1 = chars1.trim().split('');
          chars2 = chars2.trim().split('');
          el.innerHTML =
            '<span>' +
            chars1.join('</span><span>') +
            '&nbsp;' +
            chars2.join('</span><span>') +
            '</span>';
        }
      }, 10);
    }
  }, [localStore.loading]);

  if (
    !localStore.otaLoaded ||
    !localStore.managementLoaded ||
    !localStore.firstRoomLoaded ||
    localStore.loading
  ) {
    return '';
  }

  if (!localStore.todayPrice) {
    return <div style={{ height: '80px', zIndex: -1 }} />;
  }

  return (
    <Wrapper {...props} className="wow fadeIn">
      <Row>
        <ColorTextFlowWrapper>
          <TitleWrapper>
            <div id="todayPriceTitle" className="color-text-flow" />
          </TitleWrapper>
        </ColorTextFlowWrapper>
      </Row>
      <Row>
        <StyledText className="today-price left-label" color="#ffffff">
          {intl.formatMessage({ id: 'label.todayPrice.leftLabel' })}
        </StyledText>
        <StyledText color="#f00" style={{ display: 'flex' }}>
          <BestPriceWrapper>
            <Row>
              <div
                id="todayPrice"
                className="animate one today-price nowrap"
                style={{ fontSize: '12px' }}
              />
              <StyledText
                className="animated infinite tada today-price nowrap"
                color="#bf953f"
                shadow
                size="16px"
              >
                {localStore.todayPrice
                  ? `￦ ${localStore.todayPrice.toLocaleString(
                      navigator.language,
                      {
                        minimumFractionDigits: 0,
                      },
                    )}`
                  : null}
              </StyledText>
            </Row>
          </BestPriceWrapper>
        </StyledText>
      </Row>
      {store.hotel.isDiscount &&
        store.hotel.isDiscountVisible &&
        store.hotel.memberLevel &&
        store.hotel.memberLevel.length && (
          <Row justify="center">
            <StyledText className="today-price" color="#d8d8d8" size="12px">
              {intl.formatMessage(
                { id: 'label.todayPrice.memberDiscount' },
                {
                  value:
                    store.hotel.memberLevel[store.hotel.memberLevel.length - 1]
                      .discount,
                },
              )}
            </StyledText>
          </Row>
        )}
      <hr style={{ margin: '5px 0' }} />

      <OTAWrapper {...props}>
        {localStore.room && localStore.room.ota && localStore.room.ota.length
          ? localStore.room.ota.map((item, key) => (
              <Row flex key={`todayPrice_ota_${key}`}>
                <StyledText color="#e8e8e8" shadow size="13px">
                  {item.name}
                </StyledText>
                <Arrow>
                  <span />
                </Arrow>
                <StyledText color="#e8e8e8" shadow size="13px">
                  {item.price
                    ? `￦ ${item.price.toLocaleString(navigator.language, {
                        minimumFractionDigits: 0,
                      })}`
                    : null}
                </StyledText>
              </Row>
            ))
          : null}
      </OTAWrapper>
    </Wrapper>
  );
});

export default TodayPriceWidget;
