/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { Parallax } from 'react-parallax';
import Swiper from 'swiper';

const Wrapper = styled.div`
  ${props => props.margin && `margin: ${props.margin}`};

  .swiper-container {
    position: absolute;
    width: 100%;
    height: ${props => (props.height ? props.height : '250px')};
    top: 0;
    left: 0;
  }
  .swiper-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
  }
  .swiper-pagination-bullet {
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    font-size: 12px;
    color: #000;
    opacity: 1;
    background: rgba(0, 0, 0, 0.2);
  }
  .swiper-pagination-bullet-active {
    color: #fff;
    background: #007aff;
  }
`;

const Dummy = styled.div`
  height: ${props => (props.height ? props.height : '250px')};
  background: rgba(0, 0, 0, 0.6);
`;

const TextWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const StyledText = styled.div`
  color: ${props => (props.color ? props.color : '#fff')};
  font-size: ${props => (props.size ? props.size : '22px')};
`;

const ParallaxContainer = observer(props => {
  useEffect(() => {
    const opt = {
      slidesPerView: 1,
      spaceBetween: 10,
      autoplay: props.textData && props.textData.length > 1,
      loop: props.textData && props.textData.length > 1,
    };
    new Swiper(`#${props.id} .swiper-container`, opt);
  }, [props]);

  return (
    <Wrapper {...props}>
      <Parallax
        blur={{ min: -15, max: 25 }}
        bgImage={props.url}
        bgImageAlt=""
        bgImageSizes="cover"
        strength={200}
      >
        <div className="swiper-container">
          <div className="swiper-wrapper">
            {props.textData &&
              props.textData.map((item, idx) => (
                <div className="swiper-slide" key={`${props.id}_${idx}`}>
                  {(item.title || item.subtitle) && (
                    <TextWrapper>
                      {item.title && <StyledText>{item.title}</StyledText>}

                      {item.subtitle && (
                        <StyledText size="14px" color="#c8c8c8">
                          {item.subtitle}
                        </StyledText>
                      )}
                    </TextWrapper>
                  )}
                </div>
              ))}
          </div>
        </div>

        <Dummy {...props} />
      </Parallax>
    </Wrapper>
  );
});

export default ParallaxContainer;
