/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import { store } from '../../../shared/store';
import TextareaView from '../../Common/TextareaView';

const Wrapper = styled.div`
  .thumbnail,
  .thumbnail img {
    height: 250px !important;
  }
  ${breakpoint('xs', 'lg')`
    .thumbnail,
    .thumbnail img {
      height: auto !important;
    }
  `}
`;

const Facility = observer(props => {
  const intl = useIntl();

  return (
    <Wrapper id="facilities" {...props}>
      <div className="custom-listing">
        <section id="listing">
          <div className="container">
            <div className="row">
              <div className="listing-content col-md-12">
                <div className="listing-pagination">
                  <h5 className="title pull-left">Facilities</h5>
                </div>
                <div className="row">
                  {window
                    .toJS(store.hotel.homepage.curFacility)
                    .map((item, idx) => {
                      return (
                        <div className="col-md-4" key={`facility_list_${idx}`}>
                          <div className="listing-room-grid">
                            <div className="thumbnail">
                              <div className="thumbnail-slider">
                                {item.images.map((image, imgIdx) => (
                                  <img
                                    src={image.url}
                                    alt=""
                                    className="img-responsive"
                                    key={`facility_list_${idx}_img_${imgIdx}`}
                                  />
                                ))}
                              </div>
                            </div>
                            <div className="listing-room-content">
                              <div className="row">
                                <div className="col-md-12">
                                  <header>
                                    <h5 className="title">{item.post.title}</h5>
                                  </header>

                                  <TextareaView single>
                                    {item.post.content}
                                  </TextareaView>
                                  <button
                                    className="btn btn-transparent-gray"
                                    onClick={store.handleClickMore(
                                      item,
                                      'post',
                                    )}
                                    style={{
                                      marginBottom: '15px',
                                      width: '90%',
                                    }}
                                  >
                                    {intl.formatMessage({
                                      id: 'label.readMore',
                                    })}
                                  </button>
                                  {item.url && (
                                    <button
                                      className="btn btn-transparent-gray"
                                      onClick={() => store.goToLink(item.url)}
                                      style={{ width: '90%' }}
                                    >
                                      {intl.formatMessage({
                                        id: 'label.goToLink',
                                      })}
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Wrapper>
  );
});

export default Facility;
