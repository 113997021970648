/* eslint-disable react-hooks/exhaustive-deps, jsx-a11y/anchor-is-valid, no-script-url */
import React, { useCallback } from 'react';
import { Form, Icon, Input } from 'antd';
import { observer } from 'mobx-react-lite';
import { useMutation } from 'react-apollo-hooks';

import withFormComp from '../../../utils/withFormComp';
import { useIntl } from '../../../utils/intl';
import { store } from '../../../shared/store';
import { LOGIN, SET_USER_LOGIN } from '../../../shared/queries';
import ForgotPassword from '../../Common/Sign/ForgotPassword';
import SignUp from '../../Common/Sign/SignUp';

const SignIn = observer(props => {
  const intl = useIntl();
  const login = useMutation(LOGIN);
  const setUserLogin = useMutation(SET_USER_LOGIN);

  const form = props.form;
  const { getFieldDecorator } = form;

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();
      form.validateFields(async (err, values) => {
        if (!err) {
          const hotel = store.hotel._id;
          const result = await login({
            variables: {
              ...values,
              isMember: true,
              hotel,
            },
          });

          if (!result.data || !result.data.login || !result.data.login.ok) {
            window.alert({
              title: intl.formatMessage({ id: result.data.login.error }),
            });
          } else {
            await setUserLogin({
              variables: { token: result.data.login.token },
            });
          }
          form.resetFields();
        }
      });
    },
    [form, intl, login, setUserLogin],
  );

  return (
    <>
      <Form className="default-form" onSubmit={handleSubmit}>
        <Form.Item>
          {getFieldDecorator('email', {
            rules: [
              {
                required: true,
                message: intl.formatMessage({
                  id: 'error.username.required',
                }),
              },
            ],
          })(
            <Input
              className="email"
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder={intl.formatMessage({
                id: 'label.placeholder.email',
              })}
              autoComplete="off"
            />,
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('password', {
            rules: [
              {
                required: true,
                message: intl.formatMessage({
                  id: 'error.password.required',
                }),
              },
            ],
          })(
            <Input
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder={intl.formatMessage({
                id: 'label.placeholder.password',
              })}
              autoComplete="off"
            />,
          )}
        </Form.Item>
        <Form.Item style={{ margin: 0 }}>
          <p className="form-row">
            <button className="submit-btn button btn-blue" type="submit">
              <i className="fa fa-power-off"></i> Login
            </button>
          </p>
          <p className="form-row forgot-password">
            <a
              href="#!"
              onClick={() => {
                store.forgotVisible = true;
              }}
            >
              Forgot Password?
            </a>
          </p>
          <p className="form-row register">
            <a
              href="#!"
              onClick={() => {
                store.signUpVisible = true;
              }}
            >
              Register
            </a>
          </p>
        </Form.Item>
      </Form>
      <ForgotPassword handleClose={() => (store.forgotVisible = false)} />
      <SignUp />
    </>
  );
});

export default withFormComp(Form.create({ name: 'signIn_form' })(SignIn));
