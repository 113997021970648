import styled, { css } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

const Style = css`
  @import url('/themes/theme2/revolution/css/settings.css');
  @import url('/themes/theme2/revolution/css/layers.css');
  @import url('/themes/theme2/revolution/css/navigation.css');
  @import url('/themes/theme2/libraries/lib.css');
  @import url('/themes/theme2/css/plugins.css');
  @import url('/themes/theme2/css/navigation-menu.css');
  @import url('/themes/theme2/style.css');
`;

const ThemeStyle = styled.div`
  ${Style}

  .navbar-brand {
    img {
      max-height: 80px;
    }
  }

  .offer-carousel {
    i img {
      width: 100%;
      min-height: 280px;
      max-height: 280px;
    }
  }

  #facilities {
    img {
      width: 100%;
      min-height: 280px;
      max-height: 280px;
    }
  }

  .facilities-list {
    padding: 0;
  }

  .facilities-list li {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    float: left;
    width: 33%;

    span {
      margin: 0;
    }
  }

  /* 여백 제거 */
  .booking-section-1.container-fluid {
    margin: 0;
  }
  .whychooseus-section.container-fluid {
    padding: 50px 0;
    margin: 0;
  }
  .offer-section.container-fluid {
    margin-bottom: 80px;
  }
  .service-section.container-fluid {
    padding: 80px 0;
  }
  .footer-main .top-footer {
    padding: 30px 0;

    .container {
      display: flex;
      justify-content: center;
      align-items: center;

      .widget_hours,
      .widget_destination {
        align-self: flex-start;
      }
    }

    .ftr-widget p {
      line-height: 26px !important;
    }
  }

  ${breakpoint('xs', 'lg')`
    .footer-main .top-footer {
      .container {
        display: block;
      }
    }
    .choose-us-box {
      max-width: 100%;
    }
  `};
`;

export default ThemeStyle;
