/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import ScriptLoader from 'react-script-loader-hoc';
import { observer, useLocalStore } from 'mobx-react-lite';
import { isBrowser } from 'react-device-detect';

import { store } from '../../../shared/store';
import { useScript } from '../../../utils/useScript';
import Loading from '../../Common/Loading';
import ThemeStyle from './ThemeStyle';
import Header from './Header';
import About from './About';
import Banner from './Banner';
import Footer from './Footer';
import SpecialOffer from './SpecialOffer';
import Room from './Room';
import Facility from './Facility';
import HashTag from './HashTag';
import Parallax from '../../Common/Parallax/Parallax';
import Location from '../../Common/Location';

const scripts = [
  '/themes/theme2/libraries/lib.js',
  '/themes/theme2/revolution/js/jquery.themepunch.tools.min.js?rev=5.0',
  '/themes/theme2/revolution/js/jquery.themepunch.revolution.min.js?rev=5.0',
  '/themes/theme2/revolution/js/extensions/revolution.extension.video.min.js',
  '/themes/theme2/revolution/js/extensions/revolution.extension.slideanims.min.js',
  '/themes/theme2/revolution/js/extensions/revolution.extension.layeranimation.min.js',
  '/themes/theme2/revolution/js/extensions/revolution.extension.navigation.min.js',
  '/themes/theme2/revolution/js/extensions/revolution.extension.actions.min.js',
  '/themes/theme2/js/functions.js',
];

const ContentContainer = observer(() => {
  useScript(scripts);

  if (!store.hotel.homepage || !store.hotel.homepage.menus) return <Loading />;

  return (
    <>
      <div data-offset="200" data-spy="scroll" data-target=".ow-navigation">
        <Header />
        <main>
          <Banner className={isBrowser ? 'wow fadeIn' : ''} />

          {store.hotel.homepage.menus['about'] && (
            <About className={isBrowser ? 'wow fadeIn' : ''} />
          )}

          {store.hotel.homepage.parallax &&
            store.hotel.homepage.parallax[0] &&
            store.hotel.homepage.parallax[0].isAvailable && (
              <Parallax
                id="barojaba_parallax1"
                url={store.hotel.homepage.parallax[0].image.url}
                textData={store.hotel.homepage.parallax[0].textData}
                margin="0 0 50px 0"
              />
            )}

          {store.hotel.homepage.menus['specialOffers'] && (
            <SpecialOffer className={isBrowser ? 'wow fadeIn' : ''} />
          )}

          {store.hotel.homepage.menus['rooms'] && (
            <Room className={isBrowser ? 'wow fadeIn' : ''} />
          )}

          {store.hotel.homepage.menus['facilities'] && (
            <Facility className={isBrowser ? 'wow fadeIn' : ''} />
          )}

          {store.hotel.homepage.parallax &&
            store.hotel.homepage.parallax[1] &&
            store.hotel.homepage.parallax[1].isAvailable && (
              <Parallax
                id="barojaba_parallax2"
                url={store.hotel.homepage.parallax[1].image.url}
                textData={store.hotel.homepage.parallax[1].textData}
                margin="0 0 20px 0"
              />
            )}

          {store.hotel.instagrams && store.hotel.instagrams.length ? (
            <HashTag className={isBrowser ? 'wow fadeIn' : ''} />
          ) : null}

          <Location />
          <Footer />
        </main>
      </div>
    </>
  );
});

const Container = observer(({ scriptsLoadedSuccessfully }) => {
  const localStore = useLocalStore(() => ({
    loaded: false,
  }));

  useEffect(() => {
    if (scriptsLoadedSuccessfully) {
      setTimeout(() => {
        localStore.loaded = true;
      }, 100);
    }
  }, [scriptsLoadedSuccessfully]);

  if (!scriptsLoadedSuccessfully) return <Loading />;

  return (
    <>
      <ThemeStyle>
        <Helmet>
          {store.hotel.logo && (
            <link
              rel="shortcut icon"
              href={store.hotel.logo.url}
              type="image/x-icon"
            />
          )}
          {!/localhost/.test(window.location.host) &&
          store.hotel.images &&
          store.hotel.images[0] ? (
            <>
              <meta property="og:image" content={store.hotel.images[0].url} />
              <meta
                property="og:description"
                content={`${store.hotel.name}에 오신 것을 환영합니다. 홈페이지에서 예약하는 것이 가장 저렴합니다.`}
              />
              <meta
                property="og:title"
                content={`${store.hotel.name} 공식 홈페이지`}
              />
            </>
          ) : (
            <meta
              name="description"
              content={`${store.hotel.name}에 오신 것을 환영합니다. 홈페이지에서 예약하는 것이 가장 저렴합니다.`}
            />
          )}
          <title>{store.hotel.curIntro.name}</title>
        </Helmet>
        {localStore.loaded && <ContentContainer />}
      </ThemeStyle>
    </>
  );
});

export default ScriptLoader('/themes/theme2/js/jquery.min.js')(Container);
