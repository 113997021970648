/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import GoogleMap from './GoogleMap';

const Wrapper = styled.section`
  padding: 0;
  width: 100%;
`;

const Location = observer(props => {
  return (
    <Wrapper id="location">
      <GoogleMap mapHeight={props.height ? props.height : '400px'} />
    </Wrapper>
  );
});

export default Location;
