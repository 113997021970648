import { Divider } from 'antd';
import styled from 'styled-components';

const StyledDivider = styled(Divider)`
  margin: ${props => (props.margin ? props.margin : '10px 0')} !important;
  .ant-typography {
    margin: 0;
  }
  .ant-divider-inner-text {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export default StyledDivider;
