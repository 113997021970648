/* eslint-disable react-hooks/exhaustive-deps, jsx-a11y/anchor-is-valid, no-script-url */
import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import Swiper from 'swiper';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { useIntl } from 'react-intl';
import { isMobile } from 'react-device-detect';

import { store } from '../../../shared/store';
import TextareaView from '../../Common/TextareaView';

const Wrapper = styled.div`
  #about .swiper-wrapper {
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
  #about .swiper-pagination-bullet {
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    font-size: 12px;
    color: #000;
    opacity: 1;
    background: rgba(0, 0, 0, 0.2);
  }
  #about .swiper-pagination-bullet-active {
    color: #fff;
    background: #007aff;
  }

  ${breakpoint('xs', 'lg')`
    #about .swiper-wrapper {
      display: flex;
      justify-content: flex-start !important;
      align-items: flex-end !important;
    }
  `}
`;

const About = observer(props => {
  const intl = useIntl();

  useEffect(() => {
    const opt = {
      slidesPerView: 1,
      spaceBetween: 10,
      // init: false,
      autoplay: true,
      // pagination: {
      //   el: '#about .swiper-pagination',
      //   clickable: true,
      //   renderBullet: function(index, className) {
      //     return '<span class="' + className + '">' + (index + 1) + '</span>';
      //   },
      // },
    };
    if (!isMobile) {
      opt.breakpoints = {
        1024: {
          slidesPerView: 4,
          spaceBetween: 40,
        },
      };
    }
    new Swiper('#about .swiper-container', opt);
  }, [store.hotel.homepage.curAbout]);

  return (
    <Wrapper {...props}>
      {/* <!-- Start About --> */}
      <section id="about" className="about">
        <div className="container">
          <div className="row">
            <div className="text-center col-md-12">
              <h3 style={{ fontSize: '20px', marginBottom: 0 }}>
                {store.hotel.curIntro.name}
              </h3>
              <h3>{store.hotel.stars}</h3>
              <TextareaView>{store.hotel.curIntro.description}</TextareaView>
            </div>
            <div className="text-center col-md-12">
              <div className="swiper-container">
                <div className="swiper-wrapper">
                  {window
                    .toJS(store.hotel.homepage.curAbout)
                    .map((item, idx) => {
                      return (
                        <div
                          className="swiper-slide"
                          key={`homepage_about_${idx}`}
                        >
                          <div
                            className="feature text-center"
                            style={{ width: '100%' }}
                          >
                            <div className="overlay">
                              <img
                                src={item.images[0].url}
                                alt=""
                                className="img-responsive"
                              />
                              <div className="overlay-shadow">
                                <div className="overlay-content">
                                  <a
                                    href="#!"
                                    className="btn btn-transparent-white"
                                    onClick={store.handleClickMore(
                                      item,
                                      'post',
                                    )}
                                  >
                                    {intl.formatMessage({
                                      id: 'label.readMore',
                                    })}
                                  </a>
                                </div>
                              </div>
                            </div>
                            {item.url && (
                              <button
                                className="btn btn-transparent-gray"
                                onClick={() => store.goToLink(item.url)}
                                style={{ marginTop: '15px', width: '100%' }}
                              >
                                {intl.formatMessage({ id: 'label.goToLink' })}
                              </button>
                            )}

                            <h4>{item.post.title}</h4>
                            <TextareaView single>
                              {item.post.content}
                            </TextareaView>
                          </div>
                        </div>
                      );
                    })}
                </div>

                {/* <div className="swiper-pagination"></div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End About --> */}
    </Wrapper>
  );
});

export default About;
