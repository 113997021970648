/* eslint-disable react-hooks/exhaustive-deps, jsx-a11y/anchor-is-valid, no-script-url */
import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import { observer, useLocalStore } from 'mobx-react-lite';
import ScriptLoader from 'react-script-loader-hoc';
import styled from 'styled-components';
import { isIE, isOpera } from 'react-device-detect';

import { useScript } from '../../../utils/useScript';
import { GOOGLE_MAP_API_KEY } from '../../../utils/constants';
import Loading from '../../Common/Loading';
import ThemeStyle from './ThemeStyle';
import Parallax1 from './Parallax1';
import Parallax2 from './Parallax2';
import Parallax3 from './Parallax3';
import Header from './Header';
import Home from './Home';
import About from './About';
import Solution from './Solution';
import Services from './Services';
import ContactUs from './ContactUs';
import Footer from './Footer';

const Background = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: #000000;
`;

const scripts = [
  '/themes/theme0/js/modernizr.custom.js',
  '/themes/theme0/js/classie.js',
  '/themes/theme0/js/cbpAnimatedHeader.min.js',
  '/themes/theme0/js/retina-1.1.0.min.js',
  '/themes/theme0/js/jquery.fitvids.js',
  '/themes/theme0/js/flexSlider.js',
  '/themes/theme0/js/owl.carousel.min.js',
  '/themes/theme0/js/carousels.js',
  '/themes/theme0/js/jquery.parallax-1.1.3.js',
  '/themes/theme0/js/jquery.easing.js',
  '/themes/theme0/js/plugins.js',
  // '/themes/theme0/js/gmaps.js',
  '/themes/theme0/js/isotope.pkgd.min.js',
  '/themes/theme0/js/template.js',
];

const ContentContainer = observer(() => {
  useScript(scripts);

  return (
    <>
      <Header />
      <Home />
      <About />
      <Parallax1 />
      <Solution />
      {/* <Parallax2 /> */}
      {/* <Services /> */}
      <Parallax3 />
      <ContactUs />
      <Footer />
    </>
  );
});

const PreLoadContainer = observer(({ scriptsLoadedSuccessfully }) => {
  const localStore = useLocalStore(() => ({
    loaded: false,
  }));

  useEffect(() => {
    if (scriptsLoadedSuccessfully) {
      window.Royal_Preloader.config({
        mode: 'text', // 'number', "text" or "logo"
        text: 'BAROJABA',
        timeout: 0,
        showInfo: true,
        opacity: 1,
        background: ['#000000'],
      });

      setTimeout(() => {
        localStore.loaded = true;
      }, 100);
    } else {
      setTimeout(() => {
        localStore.loaded = true;
      }, 3000);
    }
  }, [scriptsLoadedSuccessfully]);

  if (!scriptsLoadedSuccessfully) return <Background />;

  return localStore.loaded && <ContentContainer />;
});

const PreLoad = ScriptLoader('/themes/theme0/js/royal_preloader.min.js')(
  PreLoadContainer,
);

const Container = observer(({ scriptsLoadedSuccessfully }) => {
  if (!scriptsLoadedSuccessfully) return <Background />;

  return (
    <ThemeStyle className="royal_loader">
      <Helmet>
        <link rel="shortcut icon" href="/favicon.ico" type="image/x-icon" />
        <meta
          name="description"
          content={`호텔 다이렉트 부킹 활성화를 위한 최고의 솔루션. BAROJABA는 예약자와 관리자 모두 사용하기 편리한 시스템을 추구하는 호텔 IT 통합 솔루션 전문 기업입니다.`}
        />
        <title>BAROJABA Inc.</title>
      </Helmet>
      {isIE || isOpera ? <ContentContainer /> : <PreLoad />}
    </ThemeStyle>
  );
});

export default ScriptLoader(
  '/themes/theme0/js/jquery.js',
  `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}`,
)(Container);
