/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useCallback, useEffect } from 'react';
import { Modal, Form, Icon, Input, Button } from 'antd';
import { observer } from 'mobx-react-lite';
import { useMutation } from 'react-apollo-hooks';

import withFormComp from '../../../utils/withFormComp';
import { useIntl } from '../../../utils/intl';
import { store } from '../../../shared/store';
import { LOGIN, SET_USER_LOGIN } from '../../../shared/queries';
import ForgotPassword from './ForgotPassword';

const SignIn = observer(props => {
  const intl = useIntl();
  const login = useMutation(LOGIN);
  const setUserLogin = useMutation(SET_USER_LOGIN);

  const form = props.form;
  const { getFieldDecorator } = form;

  const handleSubmit = useCallback(e => {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        const hotel = store.hotel._id;
        const result = await login({
          variables: {
            ...values,
            isMember: true,
            hotel,
          },
        });

        if (!result.data || !result.data.login || !result.data.login.ok) {
          window.alert({
            title: intl.formatMessage({ id: result.data.login.error }),
          });
        } else {
          await setUserLogin({
            variables: { token: result.data.login.token },
          });
          store.signInVisible = false;
        }
        form.resetFields();
      }
    });
  }, []);

  useEffect(() => {
    if (store.signInVisible) {
      form.resetFields();
    }
  }, [store.signInVisible]);

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Form.Item>
          {getFieldDecorator('email', {
            rules: [
              {
                required: true,
                message: intl.formatMessage({
                  id: 'error.username.required',
                }),
              },
            ],
          })(
            <Input
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder={intl.formatMessage({
                id: 'label.placeholder.email',
              })}
              autoComplete="off"
            />,
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('password', {
            rules: [
              {
                required: true,
                message: intl.formatMessage({
                  id: 'error.password.required',
                }),
              },
            ],
          })(
            <Input
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder={intl.formatMessage({
                id: 'label.placeholder.password',
              })}
              autoComplete="off"
            />,
          )}
        </Form.Item>
        <Form.Item style={{ margin: 0 }}>
          <Button
            type="link"
            style={{ float: 'right' }}
            onClick={() => {
              store.signInVisible = false;
              store.forgotVisible = true;
            }}
          >
            {intl.formatMessage({ id: 'label.sign.forgot.password' })}
          </Button>
          <br />
          <Button type="primary" htmlType="submit" block={true}>
            {intl.formatMessage({ id: 'label.sign.in' })}
          </Button>
          <Button
            block={true}
            onClick={() => {
              store.signInVisible = false;
              store.signUpVisible = true;
            }}
          >
            {intl.formatMessage({ id: 'label.sign.up' })}
          </Button>
        </Form.Item>
      </Form>
      <ForgotPassword handleClose={() => (store.forgotVisible = false)} />
    </>
  );
});

export default withFormComp(Form.create({ name: 'signIn_form' })(SignIn));
