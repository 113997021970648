/* eslint-disable react-hooks/exhaustive-deps, jsx-a11y/anchor-is-valid */
import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { useIntl } from 'react-intl';

import { store } from '../../../shared/store';
import TextareaView from '../../Common/TextareaView';

const Wrapper = styled.section`
  margin-bottom: 60px;
  .gdlr-room-title {
    margin-bottom: 15px;
  }

  .gdlr-room-thumbnail {
    img {
      width: 100%;
      height: 300px;
    }
  }

  ${breakpoint('xs', 'lg')`
   .gdlr-room-thumbnail {
      img {
        height: 220px;
      }
    }     
  `};
`;

const ParallaxWrapper = styled.div`
  padding-top: 80px;
  padding-bottom: 10px;

  &&:after {
    ${props => props.url && `background-image: url(${props.url})`};
    top: 0;
    left: 0;
    position: absolute;
    background-size: 100%;
    opacity: 0.2 !important;
    filter: grayscale(100%);
    z-index: -1;
    content: '';
    width: 100%;
    height: 100%;
  }
`;

const SpecialOffer = observer(() => {
  const intl = useIntl();

  return (
    <Wrapper id="specialOffers">
      <ParallaxWrapper
        className="gdlr-parallax-wrapper gdlr-background-image gdlr-show-all gdlr-skin-light-grey"
        id="gdlr-parallax-wrapper-1"
        data-bgspeed="0.1"
        url={store.hotel.images[0] ? store.hotel.images[0].url : ''}
      >
        <div className="container">
          <div className="gdlr-item-title-wrapper gdlr-item pos-center gdlr-nav-container ">
            <div className="gdlr-item-title-head">
              <h3 className="gdlr-item-title gdlr-skin-title gdlr-skin-border">
                Special Offers
              </h3>
              <div className="gdlr-item-title-carousel">
                <i className="icon-angle-left gdlr-flex-prev"></i>
                <i className="icon-angle-right gdlr-flex-next"></i>
              </div>
              <div className="clear"></div>
            </div>
          </div>
          <div className="room-item-wrapper type-modern">
            <div className="room-item-holder ">
              <div className="gdlr-room-carousel-item gdlr-item">
                <div
                  className="flexslider"
                  data-type="carousel"
                  data-nav-container="room-item-wrapper"
                  data-columns="3"
                >
                  <ul className="slides">
                    {window.toJS(store.hotelPackages).map((p, pIdx) => (
                      <li
                        className="gdlr-item gdlr-modern-room"
                        key={`package_${pIdx}`}
                      >
                        <div className="gdlr-room-thumbnail">
                          <a
                            href="#!"
                            onClick={store.handleClickMore(p, 'curIntro')}
                          >
                            <img src={p.images[0].url} alt="" />
                          </a>
                        </div>
                        <h3 className="gdlr-room-title">
                          <a
                            href="#!"
                            onClick={store.handleClickMore(p, 'curIntro')}
                          >
                            {p.curIntro.name}
                          </a>
                          <TextareaView
                            single={true}
                            style={{ textAlign: 'left' }}
                          >
                            {p.curIntro.description}
                          </TextareaView>
                        </h3>
                        <a
                          href="#!"
                          className="gdlr-room-detail booking"
                          onClick={() =>
                            store.goToLink(
                              `https://booking.barojaba.com/?h=${store.hotel._id}`,
                            )
                          }
                        >
                          {intl.formatMessage({
                            id: 'label.booking',
                          })}
                          &nbsp;
                          <i className="fa fa-long-arrow-right icon-long-arrow-right"></i>
                        </a>
                      </li>
                    ))}

                    {window.toJS(store.hotelPromotions).map((p, pIdx) => (
                      <li
                        className="gdlr-item gdlr-modern-room"
                        key={`package_${pIdx}`}
                      >
                        <div className="gdlr-room-thumbnail">
                          <a
                            href="#!"
                            onClick={store.handleClickMore(p, 'curIntro')}
                          >
                            <img src={p.images[0].url} alt="" />
                          </a>
                        </div>
                        <h3 className="gdlr-room-title">
                          <a
                            href="#!"
                            onClick={store.handleClickMore(p, 'curIntro')}
                          >
                            {p.curIntro.name}
                          </a>
                          <TextareaView
                            single={true}
                            style={{ textAlign: 'left' }}
                          >
                            {p.curIntro.description}
                          </TextareaView>
                        </h3>
                        <a
                          href="#!"
                          className="gdlr-room-detail booking"
                          onClick={() =>
                            store.goToLink(
                              `https://booking.barojaba.com/?h=${store.hotel._id}`,
                            )
                          }
                        >
                          {intl.formatMessage({
                            id: 'label.booking',
                          })}
                          &nbsp;
                          <i className="fa fa-long-arrow-right icon-long-arrow-right"></i>
                        </a>
                      </li>
                    ))}
                  </ul>
                  <div className="clear"></div>
                </div>
              </div>
              <div className="clear"></div>
            </div>
          </div>
          <div className="clear"></div>
          <div className="clear"></div>
        </div>
      </ParallaxWrapper>
      <div className="clear"></div>
    </Wrapper>
  );
});

export default SpecialOffer;
