/* eslint-disable react-hooks/exhaustive-deps, jsx-a11y/anchor-is-valid, no-script-url */
import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import { Instagram, InstagramTitle } from '../../Common/Instagram';

const Wrapper = styled.div`
  padding-top: 25px;
  margin-bottom: 50px;

  .section-header {
    margin-bottom: 20px;
  }
  .section-header h3 {
    font-size: 100%;
    color: white;
  }
  .title {
    font-size: 30px;
  }
  .sub-title {
    font-size: 100%;
  }

  ${breakpoint('xs', 'lg')`
   .title {
      font-size: 22px;
    }
    .sub-title{
      font-size: 12px;
    }
  `};
`;

const HashTag = observer(props => {
  return (
    <Wrapper id="hashtag" {...props}>
      <div className="container">
        <div className="section-header">
          <h3>
            <InstagramTitle />
          </h3>
        </div>
        <div>
          <Instagram />
        </div>
      </div>
    </Wrapper>
  );
});

export default HashTag;
