/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Modal, Row, Col, Tag, Typography, Button } from 'antd';
import { observer, useLocalStore } from 'mobx-react-lite';
import styled from 'styled-components';
import Swiper from 'swiper';
import { useIntl } from 'react-intl';

import { store } from '../../../shared/store';
import TextareaView from '../TextareaView';
import StyledText from '../../StyledComp/StyledText';
import StyledDivider from '../../StyledComp/StyledDivider';
import FlexRow from '../../StyledComp/FlexRow/FlexRow';

const { Title } = Typography;

const DetailWrapper = styled.div`
  .swiper-container {
    margin-bottom: 15px;
  }
  .swiper-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;

    img {
      width: 100%;
    }
  }
  .facilities-list li {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 33%;
    text-align: center;
    margin-bottom: 8px;
  }
`;

const DetailModal = observer(props => {
  const intl = useIntl();

  const localStore = useLocalStore(() => ({
    detailSwiper: null,
  }));

  useEffect(() => {
    if (store.selectedDetail) {
      setTimeout(() => {
        localStore.detailSwiper = new Swiper(
          '#detail_modal .swiper-container',
          {
            slidesPerView: 1,
            spaceBetween: 10,
            // init: false,
            pagination: {
              el: '#detail_modal .swiper-pagination',
              clickable: true,
            },
            loop: true,
            navigation: {
              nextEl: '#detail_modal .swiper-button-next',
              prevEl: '#detail_modal .swiper-button-prev',
            },
          },
        );
      }, 10);
    }
  }, [store.selectedDetail]);

  return (
    <Modal
      visible={store.detailVisible}
      footer={null}
      onCancel={() => (store.detailVisible = false)}
      bodyStyle={{ paddingTop: '50px' }}
      centered={true}
      forceRender={true}
      afterClose={() => {
        store.selectedDetail = null;
        if (localStore.detailSwiper) {
          localStore.detailSwiper.destroy();
          localStore.detailSwiper = null;
        }
      }}
    >
      {store.selectedDetail && (
        <DetailWrapper id="detail_modal">
          <div className="swiper-container">
            <div className="swiper-wrapper">
              {store.selectedDetail.images.map((image, imgIdx) => (
                <div className="swiper-slide" key={`image_${imgIdx}`}>
                  <img src={image.url} alt="" className="img-responsive" />
                </div>
              ))}
            </div>
            <div className="swiper-pagination"></div>
            <div className="swiper-button-next"></div>
            <div className="swiper-button-prev"></div>
          </div>

          <Row gutter={16}>
            <Col xs={24}>
              <StyledText>
                <StyledDivider>
                  <Title level={4}>{store.selectedDetail.title}</Title>
                </StyledDivider>
                {store.selectedDetail.content &&
                store.selectedDetail.content.length ? (
                  <TextareaView>{store.selectedDetail.content}</TextareaView>
                ) : null}
              </StyledText>
            </Col>
          </Row>

          {store.selectedDetail.facilities && (
            <>
              <StyledDivider>FACILITIES</StyledDivider>

              <FlexRow justify="flex-start" align="center" flexwrap={true}>
                {window
                  .toJS(store.selectedDetail.facilities)
                  .map((items, itemsIdx) => {
                    return items.map((item, itemIdx) => (
                      <Tag key={`facilities_${itemsIdx}_${itemIdx}`}>
                        {item}
                      </Tag>
                    ));
                  })}
              </FlexRow>
            </>
          )}

          {store.selectedDetail.url && (
            <>
              <StyledDivider />
              <Button
                type="primary"
                block
                onClick={() => store.goToLink(store.selectedDetail.url)}
              >
                {intl.formatMessage({ id: 'label.goToLink' })}
              </Button>
            </>
          )}
        </DetailWrapper>
      )}
    </Modal>
  );
});

export default DetailModal;
