import React from 'react';
import { observer } from 'mobx-react-lite';

const About = observer(() => {
  return (
    <div id="about">
      <div className="container">
        <div
          className="sixteen columns"
          data-scrollreveal="enter top and move 150px over 1s"
        >
          <div className="section-top">
            <div className="line-dark"></div>
            <h1>about us</h1>
            <div className="subtext-top">
              BAROJABA가 최고의 호텔 홈페이지 다이렉트 부킹 솔루션을 제공해
              드리겠습니다.
            </div>
            <div className="line-dark1"></div>
          </div>
        </div>
      </div>
      <div className="container">
        <div
          className="sixteen columns"
          data-scrollreveal="enter bottom and move 150px over 1s"
        >
          <div className="about-top">
            <div className="biger-text">
              <span className="padding-left-text-top">BAROJABA</span>는 호텔의
              Needs를 적극 수용하여 만든 차별화된 시스템으로 꼭 필요하지만
              비용부담으로 접근이 어려웠던 시스템을 사용성과 접근성이 용이하도록
              직접 개발하여 제공해 드리며, 지속적으로 호텔의 의견을 적극
              반영하여 호텔에게 유용한 시스템을 제공해 드리길 도모하고 상호
              발전하고자 하는 호텔 IT 통합 솔루션 전문 기업입니다.
              <br />
              <br />
              OTA 및 호텔 관련 전문 기획파트와 국내 최고의 기술력을 가진
              개발파트를 기반으로 호텔에서 필요한 시스템을 제공해 드리기 위하여
              불철주야 노력하고 있습니다.
            </div>
          </div>
        </div>
        <div className="sixteen columns">
          <div className="separ"></div>
        </div>
      </div>
    </div>
  );
});

export default About;
