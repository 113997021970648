/* eslint-disable react-hooks/exhaustive-deps, jsx-a11y/anchor-is-valid */
import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { useIntl } from 'react-intl';

import { store } from '../../../shared/store';
import TextareaView from '../../Common/TextareaView';

const Wrapper = styled.section`
  .facilities {
    margin-bottom: 15px;
  }

  .gdlr-room-thumbnail {
    img {
      width: 100%;
      height: 300px;
    }
  }

  ${breakpoint('xs', 'lg')`
   .gdlr-room-thumbnail {
      img {
        height: 220px;
      }
    }     
  `};
`;

const ParallaxWrapper = styled.div`
  padding-top: 80px;
  padding-bottom: 10px;

  &&:after {
    ${props => props.url && `background-image: url(${props.url})`};
    top: 0;
    left: 0;
    position: absolute;
    background-size: 100%;
    opacity: 0.2 !important;
    filter: grayscale(100%);
    z-index: -1;
    content: '';
    width: 100%;
    height: 100%;
  }
`;

const Room = observer(() => {
  const intl = useIntl();

  return (
    <Wrapper id="rooms">
      <ParallaxWrapper
        className="gdlr-parallax-wrapper gdlr-background-image gdlr-show-all gdlr-skin-light-grey"
        id="gdlr-parallax-wrapper-1"
        data-bgspeed="0.1"
        url={store.hotel.images[1] ? store.hotel.images[1].url : ''}
      >
        <div className="container">
          <div className="gdlr-item-title-wrapper gdlr-item pos-center gdlr-nav-container ">
            <div className="gdlr-item-title-head">
              <h3 className="gdlr-item-title gdlr-skin-title gdlr-skin-border">
                Rooms
              </h3>
              <div className="gdlr-item-title-carousel">
                <i className="icon-angle-left gdlr-flex-prev"></i>
                <i className="icon-angle-right gdlr-flex-next"></i>
              </div>
              <div className="clear"></div>
            </div>
          </div>
          <div className="room-item-wrapper type-modern">
            <div className="room-item-holder ">
              <div className="gdlr-room-carousel-item gdlr-item">
                <div
                  className="flexslider"
                  data-type="carousel"
                  data-nav-container="room-item-wrapper"
                  data-columns="3"
                >
                  <ul className="slides">
                    {window.toJS(store.hotel.rooms).map((room, rIdx) => {
                      if (room.curFacility) {
                        room.facilities = [];
                        let idx = 0;
                        for (let i = 0; i < room.curFacility.length; i++) {
                          if (
                            room.facilities[idx] &&
                            room.facilities[idx].length === 3
                          ) {
                            idx++;
                          }

                          if (!room.facilities[idx]) {
                            room.facilities[idx] = [];
                          }
                          room.facilities[idx].push(room.curFacility[i].label);
                          if (room.facilities[idx].length === 3) {
                            idx++;
                          }
                        }
                      }

                      return (
                        <li
                          className="gdlr-item gdlr-modern-room"
                          key={`room_${rIdx}`}
                        >
                          <div className="gdlr-room-thumbnail">
                            <a
                              href="#!"
                              onClick={store.handleClickMore(
                                room,
                                'curIntro',
                                true,
                              )}
                            >
                              {room.images && room.images[0] && (
                                <img src={room.images[0].url} alt="" />
                              )}
                            </a>
                          </div>
                          <h3 className="gdlr-room-title">
                            <a
                              href="#!"
                              onClick={store.handleClickMore(
                                room,
                                'curIntro',
                                true,
                              )}
                            >
                              {room.curIntro.name}
                            </a>
                          </h3>
                          <TextareaView single style={{ textAlign: 'left' }}>
                            {room.curIntro.description}
                          </TextareaView>
                          <div className="facilities">
                            {room.facilities.map((items, itemsIdx) => {
                              if (itemsIdx >= 2) {
                                return null;
                              }
                              return (
                                <div
                                  className="col-xs-12"
                                  key={`${rIdx}_facilities_${itemsIdx}`}
                                >
                                  <ul className="facilities-list">
                                    {items.map((item, itemIdx) => (
                                      <li
                                        key={`${rIdx}_facilities_${itemsIdx}_${itemIdx}`}
                                      >
                                        <span>{item}</span>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              );
                            })}
                          </div>
                          <a
                            href="#!"
                            className="gdlr-room-detail booking"
                            onClick={() =>
                              store.goToLink(
                                `https://booking.barojaba.com/?h=${store.hotel._id}`,
                              )
                            }
                          >
                            {intl.formatMessage({
                              id: 'label.booking',
                            })}
                            &nbsp;
                            <i className="fa fa-long-arrow-right icon-long-arrow-right"></i>
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                  <div className="clear"></div>
                </div>
              </div>
              <div className="clear"></div>
            </div>
          </div>
          <div className="clear"></div>
          <div className="clear"></div>
        </div>
      </ParallaxWrapper>
      <div className="clear"></div>
    </Wrapper>
  );
});
export default Room;
