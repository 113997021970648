import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import Themes from './Themes';

import Theme0 from './Container';
import { Theme1, Theme2, Theme3 } from '../';

const Router = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path={'/'} exact={true} component={Theme0} />
        <Route path={'/themes'} exact={true} component={Themes} />
        <Route path={'/themes/1'} exact={true} component={Theme1} />
        <Route path={'/themes/2'} exact={true} component={Theme2} />
        <Route path={'/themes/3'} exact={true} component={Theme3} />
        <Redirect from={'*'} to={'/'} />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
