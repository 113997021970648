/* eslint-disable react-hooks/exhaustive-deps, jsx-a11y/anchor-is-valid, no-script-url */
import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { Instagram, InstagramTitle } from '../../Common/Instagram';

const Wrapper = styled.div`
  padding-top: 50px;

  .section-header h3 {
    font-size: 100%;
  }

  .title {
    font-size: 30px;
  }
`;

const HashTag = observer(props => {
  return (
    <Wrapper id="hashtag" {...props}>
      <div className="offer-section container-fluid">
        <div className="container">
          <div className="section-header section-header-2">
            <h3>
              <InstagramTitle />
            </h3>
          </div>
          <div>
            <Instagram />
          </div>
        </div>
      </div>
    </Wrapper>
  );
});

export default HashTag;
