/* eslint-disable react-hooks/exhaustive-deps*/
import React from 'react';
import { observer } from 'mobx-react-lite';
import GoogleMapReact from 'google-map-react';
import styled, { keyframes } from 'styled-components';

import { GOOGLE_MAP_API_KEY } from '../../../utils/constants';
import { store } from '../../../shared/store';

const Wrapper = styled.div`
  width: 100%;
  height: ${props => (props.mapHeight ? props.mapHeight : '100vh')};
  border-top: 1px solid #c8c8c8;
  border-bottom: 1px solid #c8c8c8;
`;

const bounce = keyframes`
0% {
		opacity: 0;
		transform: translateY(-2000px) rotate(-45deg);
	}

	60% {
		opacity: 1;
		transform: translateY(30px) rotate(-45deg);
	}

	80% {
		transform: translateY(-10px) rotate(-45deg);
	}

	100% {
		transform: translateY(0) rotate(-45deg);
	}
`;

const pulsate = keyframes`
  0% {
		transform: scale(0.1, 0.1);
		opacity: 0;
	}

	50% {
		opacity: 1;
	}

	100% {
		transform: scale(1.2, 1.2);
		opacity: 0;
	}
`;

const Marker = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0 !important;
  background: #e74c3c;
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
  animation-name: ${bounce};
  animation-fill-mode: both;
  animation-duration: 1s;

  &:after {
    content: '';
    width: 14px;
    height: 14px;
    margin: 8px 0 0 8px;
    background: white;
    position: absolute;
    border-radius: 50% !important;
  }
`;
const MarkerPulse = styled.div`
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50% !important;
  height: 14px;
  width: 14px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: 11px 0px 0px -12px;
  transform: rotateX(55deg);
  z-index: -2;

  &:after {
    content: '';
    border-radius: 50% !important;
    height: 40px;
    width: 40px;
    position: absolute;
    margin: -13px 0 0 -13px;
    animation: ${pulsate} 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 0;
    box-shadow: 0 0 1px 2px #e74c3c;
    animation-delay: 1.1s;
  }
`;

const MarkerWrapper = styled.div`
  width: 100%;
  position: relative;
`;
const MarkerText = styled.div`
  width: 300px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  position: absolute;
  left: -155px;
  margin-top: 30px;
  color: white;
  text-shadow: 2px 2px 4px black;
`;

const MarkerComp = ({ text }) => (
  <MarkerWrapper>
    <Marker />
    <MarkerPulse />
    <MarkerText>{text}</MarkerText>
  </MarkerWrapper>
);

const GoogleMap = observer(props => {
  if (!store.hotel.location || !store.hotel.location.coordinates) {
    return <div />;
  }
  return (
    <Wrapper {...props}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: String(GOOGLE_MAP_API_KEY),
          language: window.localStorage.getItem('googleLang'),
          region: 'KR',
        }}
        defaultCenter={{
          lat: store.hotel.location.coordinates[1],
          lng: store.hotel.location.coordinates[0],
        }}
        defaultZoom={17}
        // onGoogleApiLoaded={() => (localStore.loading = false)}
        yesIWantToUseGoogleMapApiInternals={true}
      >
        <MarkerComp
          lat={store.hotel.location.coordinates[1]}
          lng={store.hotel.location.coordinates[0]}
          text={store.hotel.curIntro.name}
        />
      </GoogleMapReact>
    </Wrapper>
  );
});

export default GoogleMap;
