import styled from 'styled-components';

const FlexRow = styled.div`
  display: flex;
  width: ${props => (props.width ? props.width : '100%')};
  justify-content: ${props => props.justify};
  align-items: ${props => props.align};
  flex-direction: ${props => (props.direction ? props.direction : 'row')};
  ${props => props.flexwrap && `flex-wrap: wrap`};
`;

export default FlexRow;
