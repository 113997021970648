import { Modal } from 'antd';
import { toJS } from 'mobx';

export const globalModal = () => {
  window.alert = Modal.error;
  window.ask = Modal.confirm;
  window.info = Modal.info;
  window.success = Modal.success;
  window.warning = Modal.warning;
  window.toJS = toJS;
};
