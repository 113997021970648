/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useCallback, useEffect, useRef } from 'react';
import { Modal, Button, Form, Row, Col, Checkbox, Card } from 'antd';
import { observer, useLocalStore } from 'mobx-react-lite';
import { useMutation } from 'react-apollo-hooks';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';
import { countries } from 'countries-list';
import styled from 'styled-components';

import withFormComp from '../../../utils/withFormComp';
import { store } from '../../../shared/store';
import { useIntl } from '../../../utils/intl';
import { SAVE_MEMBER_USER } from '../User/UserQueries';
import SignUpResult from './SignUpResult';
import Loading from '../Loading';
import UserRegister from '../User/UserRegister';

const RECAPTCHA_SITE_KEY = '6LcNpMMUAAAAAAM7Qy5yz04r-EcJdAwrCR0ANvZg';
const RECAPTCHA_SECRET = '6LcNpMMUAAAAAFIRpNukTXb2JWgNrC2p0bgjNzWr';

const PolicyWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SignUp = observer(props => {
  const intl = useIntl();
  const recaptchaRef = useRef();
  const form = props.form;
  const { getFieldDecorator } = form;
  const localStore = useLocalStore(() => ({
    loading: false,
    confirmDirty: false,
    recaptchaLang: null,
    selectedLang: 'kr',
    countryPhone: '82',
    isSubmit: false,
    policy: {
      idx: 0,
      visible: false,
      checked: [false, false, false, false],
      checkAll: false,
    },
    resultVisible: false,
    emailLabel: {
      subject: intl.formatMessage(
        { id: 'label.sign.up.email.subject' },
        { hotelName: store.hotel.curIntro.name },
      ),
      hotelName: store.hotel.curIntro.name,
      title1: intl.formatMessage({ id: 'label.sign.up.email.title1' }),
      title2: intl.formatMessage({ id: 'label.sign.up.email.title2' }),
      title3: intl.formatMessage({ id: 'label.sign.up.email.title3' }),
      title4: intl.formatMessage({ id: 'label.sign.up.email.title4' }),
      title5: intl.formatMessage({ id: 'label.sign.up.email.title5' }),
      title6: intl.formatMessage({ id: 'label.sign.up.email.title6' }),
      title7: intl.formatMessage({ id: 'label.sign.up.email.title7' }),
      userName: intl.formatMessage({ id: 'label.sign.up.email.userName' }),
      userId: intl.formatMessage({ id: 'label.sign.up.email.userId' }),
    },
  }));
  const saveMemberUser = useMutation(SAVE_MEMBER_USER);

  const validateToPolicy = useCallback((rule, value, callback) => {
    if (!Boolean(value)) {
      callback(intl.formatMessage({ id: 'error.select.required' }));
    } else {
      callback();
    }
  }, []);

  const onChangeReCAPTCHA = useCallback(async value => {
    const form = new FormData();
    form.append('secret', RECAPTCHA_SECRET);
    form.append('response', value);

    const result = await axios.post('/recaptcha/api/siteverify', form);
    if (result && result.data && result.data.success) {
      localStore.isSubmit = Boolean(value);
    }
  }, []);

  const onChangePolicyAll = useCallback(
    e => {
      localStore.policy.checkAll = e.target.checked;
      const formVal = {};
      for (let i = 0; i < localStore.policy.checked.length; i++) {
        localStore.policy.checked[i] = e.target.checked;
        form.getFieldDecorator(`policy.${i}`, {
          initialValue: e.target.checked,
        });
        formVal[`policy.${i}`] = e.target.checked;
      }
      form.setFieldsValue(formVal);
    },
    [form],
  );
  const onChangePolicy = useCallback(
    idx => e => {
      localStore.policy.checked[idx] = e.target.checked;
      form.setFieldsValue({
        [`policy.${idx}`]: null,
      });
      let isCheckAll = true;
      for (let i = 0; i < localStore.policy.checked.length; i++) {
        if (!localStore.policy.checked[i]) {
          isCheckAll = false;
          break;
        }
      }
      localStore.policy.checkAll = isCheckAll;
    },
    [],
  );
  const togglePolicy = useCallback(
    idx => () => {
      localStore.policy.idx = idx;
      localStore.policy.visible = true;
    },
    [],
  );

  const handleSubmit = useCallback(e => {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        localStore.loading = true;
        const data = {
          ...values,
          roles: ['MEMBER'],
        };
        data.profile.countryPhone = localStore.countryPhone;
        data.profile.isPush = Boolean(localStore.policy.checked[3]);
        delete data.policy;
        delete data.profile.confirm;
        data.profile.phone = data.profile.phone.trim().replace(/\s/g, '');
        data.profile.useCnt = 0;
        data.profile.stayCnt = 0;
        data.profile.memberLevel = 1;

        const result = await saveMemberUser({
          variables: {
            hotel: store.hotel._id,
            data,
            src: window.localStorage.getItem('jimp_header'),
            label: JSON.stringify(localStore.emailLabel),
            fromEmail: store.hotel.email,
          },
        });

        if (result.errors && result.errors.length) {
          window.alert({
            title: intl.formatMessage({ id: result.errors[0].message }),
          });
        } else {
          store.signUpVisible = false;
          localStore.resultVisible = true;
        }
        form.resetFields();
        localStore.confirmDirty = false;
        localStore.isSubmit = false;
        localStore.policy = {
          idx: 0,
          visible: false,
          checked: [false, false, false, false],
          checkAll: false,
        };
        localStore.loading = false;
        if (recaptchaRef && recaptchaRef.current) {
          setTimeout(() => {
            recaptchaRef.current.reset();
          }, 50);
        }
      }
    });
  }, []);

  useEffect(() => {
    if (window.localStorage.lang) {
      localStore.recaptchaLang = null;
      localStore.selectedLang = window.localStorage.lang;
      localStore.countryPhone =
        countries[String(window.localStorage.lang).toUpperCase()].phone;
      form.getFieldDecorator('profile.nationality', {
        initialValue: localStore.selectedLang,
      });
      setTimeout(() => {
        const key = String(window.localStorage.lang).toUpperCase();
        localStore.recaptchaLang = countries[key].languages[0];
      }, 1000);
    }
  }, [window.localStorage.lang]);

  return (
    <>
      <Modal
        wrapClassName="signup-modal"
        title={intl.formatMessage(
          { id: 'label.sign.up.title' },
          { value: store.hotel.curIntro.name },
        )}
        visible={store.signUpVisible}
        onCancel={() => (store.signUpVisible = false)}
        footer={null}
        centered={true}
      >
        {localStore.loading ? (
          <Loading />
        ) : (
          <Form onSubmit={handleSubmit}>
            <UserRegister form={form} localStore={localStore} />

            <Row gutter={8}>
              <Col span={24}>
                <Form.Item style={{ margin: 0 }}>
                  <PolicyWrapper>
                    {getFieldDecorator('policy.0', {
                      rules: [
                        {
                          validator: validateToPolicy,
                        },
                      ],
                    })(
                      <Checkbox
                        checked={localStore.policy.checked[0]}
                        onClick={onChangePolicy(0)}
                      >
                        {intl.formatMessage({ id: 'label.sign.up.policy1' })}
                      </Checkbox>,
                    )}

                    <Button type="link" onClick={togglePolicy(0)}>
                      {intl.formatMessage({ id: 'label.sign.up.policy.more' })}
                    </Button>
                  </PolicyWrapper>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item style={{ margin: 0 }}>
                  <PolicyWrapper>
                    {getFieldDecorator('policy.1', {
                      rules: [
                        {
                          validator: validateToPolicy,
                        },
                      ],
                    })(
                      <Checkbox
                        checked={localStore.policy.checked[1]}
                        onClick={onChangePolicy(1)}
                      >
                        {intl.formatMessage({ id: 'label.sign.up.policy2' })}
                      </Checkbox>,
                    )}
                    <Button type="link" onClick={togglePolicy(1)}>
                      {intl.formatMessage({ id: 'label.sign.up.policy.more' })}
                    </Button>
                  </PolicyWrapper>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item style={{ margin: 0 }}>
                  <PolicyWrapper>
                    {getFieldDecorator('policy.2', {
                      rules: [
                        {
                          validator: validateToPolicy,
                        },
                      ],
                    })(
                      <Checkbox
                        checked={localStore.policy.checked[2]}
                        onClick={onChangePolicy(2)}
                      >
                        {intl.formatMessage({ id: 'label.sign.up.policy3' })}
                      </Checkbox>,
                    )}
                    <Button type="link" onClick={togglePolicy(2)}>
                      {intl.formatMessage({ id: 'label.sign.up.policy.more' })}
                    </Button>
                  </PolicyWrapper>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item style={{ margin: 0 }}>
                  <PolicyWrapper>
                    <Checkbox
                      checked={localStore.policy.checked[3]}
                      onClick={onChangePolicy(3)}
                    >
                      {intl.formatMessage({ id: 'label.sign.up.policy4' })}
                    </Checkbox>
                    <Button type="link" onClick={togglePolicy(3)}>
                      {intl.formatMessage({ id: 'label.sign.up.policy.more' })}
                    </Button>
                  </PolicyWrapper>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item style={{ margin: 0 }}>
                  <PolicyWrapper>
                    <Checkbox
                      checked={localStore.policy.checkAll}
                      onClick={onChangePolicyAll}
                    >
                      {intl.formatMessage({ id: 'label.sign.up.policy.all' })}
                    </Checkbox>
                  </PolicyWrapper>
                </Form.Item>
              </Col>
            </Row>

            {localStore.recaptchaLang && (
              <Row gutter={16}>
                <Col xs={24}>
                  <ReCAPTCHA
                    id="recaptcha"
                    ref={recaptchaRef}
                    sitekey={RECAPTCHA_SITE_KEY}
                    onChange={onChangeReCAPTCHA}
                    hl={localStore.recaptchaLang}
                  />
                </Col>
              </Row>
            )}

            <Form.Item style={{ margin: 0 }}>
              <Button
                className="submit"
                type="primary"
                htmlType="submit"
                block={true}
                disabled={!localStore.isSubmit}
              >
                {intl.formatMessage({ id: 'label.sign.up' })}
              </Button>
            </Form.Item>
          </Form>
        )}
      </Modal>
      <Modal
        visible={localStore.policy.visible}
        onCancel={() => (localStore.policy.visible = false)}
        footer={null}
      >
        <Card size="small" style={{ marginTop: '30px' }}>
          <pre>
            {intl.formatMessage(
              {
                id: `signup.policy${localStore.policy.idx + 1}`,
              },
              {
                hotelName: store.hotel.curIntro.name,
                homepageUrl: store.hotel.homepageUrl,
                hotelPhone: store.hotel.phone,
                hotelEmail: store.hotel.email,
              },
            )}
          </pre>
        </Card>
      </Modal>
      <SignUpResult
        resultVisible={localStore.resultVisible}
        handleResultClose={() => (localStore.resultVisible = false)}
      />
    </>
  );
});

export default withFormComp(Form.create({ name: 'sign_up_form' })(SignUp));
