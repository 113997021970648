/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useCallback } from 'react';
import { Button, Modal, Select } from 'antd';
import { observer, useLocalStore } from 'mobx-react-lite';
import { countries } from 'countries-list';
import { useMutation } from 'react-apollo-hooks';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { store } from '../../../shared/store';
import { SET_LANG, SET_USER_LOGOUT } from '../../../shared/queries';
import SignUp from '../../Common/Sign/SignUp';
import SignIn from '../../Common/Sign/SignIn';

const { Option } = Select;

const Wrapper = styled.header`
  .ant-select {
    margin-right: 15px;
  }
`;

const StyledBtn = styled(Button)`
  background: transparent !important;
  color: white !important;
  border-color: white !important;
  margin-right: 10px !important;

  &:hover {
    background: white !important;
    color: #555 !important;
  }
`;

const Header = observer(() => {
  const intl = useIntl();
  const localStore = useLocalStore(() => ({
    hotelCountryPhone: '',
  }));

  const setLang = useMutation(SET_LANG);
  const setUserLogout = useMutation(SET_USER_LOGOUT);

  const onClickLogout = useCallback(() => {
    window.ask({
      title: intl.formatMessage({ id: 'label.sign.out.confirm' }),
      onOk() {
        setUserLogout();
      },
      okText: intl.formatMessage({ id: 'label.ok' }),
      cancelText: intl.formatMessage({ id: 'label.cancel' }),
      centered: true,
    });
  }, []);

  const handleChangeLang = useCallback(value => {
    setLang({ variables: { current: value } });
  }, []);

  const isFollowUs = useCallback(() => {
    let visible = false;
    const sns = window.toJS(store.hotel.sns);
    if (sns) {
      const f = Object.keys(sns).filter(
        key => key !== '__typename' && sns[key] && sns[key].length,
      );
      if (f.length) {
        visible = true;
      }
    }
    return visible;
  }, []);

  const menus = useCallback(() => {
    const value = [];
    const menus = window.toJS(store.hotel.homepage.menus);
    if (menus['about']) {
      value.push(
        <li key="menu_about">
          <a href="#about">About</a>
        </li>,
      );
    }
    if (menus['specialOffers']) {
      value.push(
        <li key="menu_specialOffers">
          <a href="#specialOffers">Special Offers</a>
        </li>,
      );
    }
    if (menus['rooms']) {
      value.push(
        <li key="menu_rooms">
          <a href="#rooms">Rooms</a>
        </li>,
      );
    }
    if (menus['facilities']) {
      value.push(
        <li key="menu_facilities">
          <a href="#facilities">Facilities</a>
        </li>,
      );
    }
    return value;
  }, []);

  useEffect(() => {
    const langs = window.toJS(store.hotel.langs);
    const defLang = String(langs[0]).toUpperCase();
    localStore.hotelCountryPhone = countries[defLang].phone;
  }, []);

  return (
    <Wrapper className="header-section container-fluid no-padding">
      <div className="top-header container-fluid no-padding">
        <div className="container">
          <div className="col-md-6 col-sm-6 col-xs-12 info">
            <p>
              <i className="fa fa-envelope-o"></i>
              {store.hotel.email}
            </p>
            <p>
              <i className="icon icon-Phone2"></i>
              <a
                href={`tel:(+${localStore.hotelCountryPhone})${store.hotel.phone}`}
                title="Phone"
                className="phone"
              >
                (+{localStore.hotelCountryPhone}) {store.hotel.phone}
              </a>
            </p>
          </div>
          <div className="col-md-6 col-sm-6 col-xs-12 header-social">
            {store.user ? (
              <>
                <StyledBtn type="primary" onClick={onClickLogout}>
                  {intl.formatMessage({ id: 'label.sign.out' })}
                </StyledBtn>
                <StyledBtn
                  type="primary"
                  onClick={() => (store.myInfoVisible = true)}
                >
                  {intl.formatMessage({ id: 'label.user.myInfo' })}
                </StyledBtn>
              </>
            ) : (
              <>
                <StyledBtn
                  type="primary"
                  onClick={() => (store.signUpVisible = true)}
                >
                  {intl.formatMessage({ id: 'label.sign.up' })}
                </StyledBtn>
                <StyledBtn
                  type="primary"
                  onClick={() => (store.signInVisible = true)}
                >
                  {intl.formatMessage({ id: 'label.sign.in' })}
                </StyledBtn>
              </>
            )}
            <Select
              defaultValue={window.localStorage.getItem('lang') || 'kr'}
              onChange={handleChangeLang}
            >
              {window.toJS(store.hotel.langs).map(item => (
                <Option value={item} key={item}>
                  {countries[String(item).toUpperCase()].emoji}&nbsp;
                  {intl.formatMessage({ id: `label.${item}` })}
                </Option>
              ))}
            </Select>
            {isFollowUs() && (
              <>
                <h5>Follow Us</h5>
                <ul>
                  {store.hotel.sns &&
                    Object.keys(store.hotel.sns).map((key, idx) =>
                      key !== '__typename' &&
                      store.hotel.sns[key] &&
                      store.hotel.sns[key].length ? (
                        <li key={`sns_${idx}`}>
                          <a
                            href={store.hotel.sns[key]}
                            title={`${key}`.toUpperCase()}
                          >
                            <i className={`fa fa-${key}`}></i>
                          </a>
                        </li>
                      ) : null,
                    )}
                </ul>
              </>
            )}
          </div>
        </div>
      </div>

      <div className="menu-block container-fluid">
        <div className="container">
          <nav className="navbar ow-navigation">
            <div className="navbar-header">
              <button
                aria-controls="navbar"
                aria-expanded="false"
                data-target="#navbar"
                data-toggle="collapse"
                className="navbar-toggle collapsed"
                type="button"
              >
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>
              <a title="Logo1" href="/" className="navbar-brand">
                <img src={store.hotel.logo.url} />
              </a>
            </div>
            <div className="navbar-collapse collapse" id="navbar">
              <ul className="nav navbar-nav">
                <li className="active">
                  <a title="Home" href="/">
                    Home
                  </a>
                </li>
                {menus()}
                {store.hotel.instagrams && store.hotel.instagrams.length ? (
                  <li>
                    <a href="#hashtag">HashTags</a>
                  </li>
                ) : null}
                <li>
                  <a href="#location">Location</a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>

      <Modal
        title={intl.formatMessage(
          { id: 'label.sign.in.title' },
          { value: store.hotel.curIntro.name },
        )}
        visible={store.signInVisible}
        onCancel={() => (store.signInVisible = false)}
        footer={null}
        centered={true}
      >
        <SignIn />
      </Modal>

      <SignUp localStore={localStore} />
    </Wrapper>
  );
});

export default Header;
