import gql from 'graphql-tag';

export const manualFragment = gql`
  fragment ManualFragment on Manual {
    _id
    groupNameId
    title
    contents
    group {
      _id
    }
  }
`;
export const manualGroupFragment = gql`
  fragment ManualGroupFragment on ManualGroup {
    _id
    lang
    names {
      uid
      name
    }
  }
`;

export const hotelFragment = gql`
  fragment HotelFragment on Hotel {
    _id
    isTest
    isHomepage
    homepageTheme
    logo {
      name
      url
    }
    name
    email
    adminEmail
    password
    homepageUrl
    fax
    phone
    langs
    location {
      type
      coordinates
    }
    zipcode
    roomCnt
    floorCnt
    rating
    weekend
    checkInTime
    checkOutTime
    isDiscount
    isDiscountVisible
    memberLevel {
      name
      discount
      autoLevelUp
    }
    minCancelDay
    cancelDay {
      day
      feeType
      fee
    }
    optional
    facilityOptional
    otaOptional
    otaName
    intro {
      type
      name
      address1
      address2
      cancellationPolicy
      nearbyPlace
      description
    }
    images {
      uid
      name
      status
      url
    }
    sns {
      facebook
      twitter
      instagram
    }
    instagramTags
    stars
    createdAt
    updatedAt
  }
`;

export const roomFragment = gql`
  fragment RoomFragment on HotelRoom {
    _id
    code
    name
    baseCharge
    roomCnt
    basePersonCnt
    maxPersonCnt
    adultSurcharge
    childSurcharge
    order
    isAvailable
    facilityOptional {
      label
      selected
    }
    facilityExtra {
      type
      content
    }
    otaName
    intro {
      type
      name
      description
    }
    images {
      uid
      name
      status
      url
    }
    createdAt
    updatedAt
  }
`;

export const serviceFragment = gql`
  fragment ServiceFragment on HotelService {
    _id
    name
    charge
    baseCnt
    maxCnt
    isActivity
    isAvailable
    isAutoCalc
    intro {
      type
      name
      description
    }
    images {
      uid
      name
      status
      url
    }
    createdAt
    updatedAt
  }
`;

export const productFragment = gql`
  fragment ProductFragment on HotelProduct {
    _id
    name
    productType
    applyDate
    isAvailable
    isActivity
    minStayDay
    maxStayDay
    offerType
    availableDay
    discountType
    discount
    stayDiscount {
      stayDay
      isPer
      discount
    }
    intro {
      type
      name
      description
    }
    images {
      uid
      name
      status
      url
    }
    hotelRooms {
      hotelRoom {
        _id
      }
      name
      charge
    }
    hotelBenefits {
      _id
    }
    createdAt
    updatedAt
  }
`;

export const benefitFragment = gql`
  fragment BenefitFragment on HotelBenefit {
    _id
    name
    benefitType
    discount
    offerType
    isMultiple
    intro {
      type
      name
      description
    }
    images {
      uid
      name
      status
      url
    }
    hotelProducts {
      _id
    }
    createdAt
    updatedAt
  }
`;

export const codeFragment = gql`
  fragment CodeFragment on HotelCode {
    _id
    name
    applyDate
    code
    codeType
    discount
    useCnt
    isAvailable
    isMultiple
    usedEmails
    createdAt
    updatedAt
  }
`;

export const managementFragment = gql`
  fragment ManagementFragment on HotelManagement {
    _id
    applyMonth
    managementType
    managementData
    createdAt
    updatedAt
  }
`;

export const crawlerDataFragment = gql`
  fragment CrawlerDataFragment on CrawlerData {
    _id
    checkInDate
    hotelName
    urlHost
    rooms {
      name
      price
    }
    data
    isOTA
    createdAt
    updatedAt
  }
`;

export const reservationFragment = gql`
  fragment ReservationFragment on Reservation {
    _id
    orderId
    booker {
      name
      email
      phone
    }
    guest {
      name
      email
      phone
    }
    card {
      type
      number
      expirationYear
      expirationMonth
    }
    memo
    checkIn
    checkOut
    rooms {
      name
      oriName
      roomId
      roomName
      oriRoomName
      dailyCharge
      dailyOptionAmount
      benefits {
        name
        oriName
        charge
        count
        type
        isDaily
        optionId
      }
      services {
        name
        oriName
        charge
        count
        type
        isDaily
        optionId
      }
    }
    allRoomAmount
    allBenefitAmount
    allServiceAmount
    code {
      name
      applyDate
      code
      codeType
      discount
    }
    adult
    stayDays
    status
    isConfirm
    createdAt
    updatedAt
  }
`;

export const adminUserFragment = gql`
  fragment AdminUserFragment on AdminUser {
    _id
    email
    name
    roles
  }
`;

export const memberUserFragment = gql`
  fragment MemberUserFragment on MemberUser {
    _id
    email
    profile {
      firstName
      lastName
      fullName
      phone
      nationality
      isPush
      useCnt
      stayCnt
      memberLevel
    }
    loginTime
    roles
  }
`;

export const homepageFragment = gql`
  fragment HomepageFragment on Homepage {
    _id
    about {
      posts {
        type
        title
        content
      }
      images {
        uid
        name
        status
        url
      }
      isAvailable
      url
      order
    }
    facility {
      posts {
        type
        title
        content
      }
      images {
        uid
        name
        status
        url
      }
      isAvailable
      url
      order
    }
    popup {
      image {
        name
        status
        url
      }
      applyDate
      width
      height
      isAvailable
      url
      order
    }
    menus {
      about
      specialOffers
      rooms
      facilities
    }
    parallax {
      isAvailable
      image {
        name
        status
        url
      }
      textData {
        title
        subtitle
      }
    }
    createdAt
    updatedAt
  }
`;
