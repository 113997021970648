/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useCallback, useEffect } from 'react';
import { Button, Modal, Input, Typography, Icon } from 'antd';
import { observer, useLocalStore } from 'mobx-react-lite';
import { useMutation, useQuery } from 'react-apollo-hooks';
import { useIntl } from 'react-intl';
import Cookies from 'js-cookie';

import { store } from '../../../shared/store';
import { SET_USER_LOGOUT } from '../../../shared/queries';
import { DELETE_MEMBER_USER, USER_RESERVATIONS } from './UserQueries';

const { Paragraph, Text } = Typography;

const UserDelete = observer(props => {
  const intl = useIntl();
  const localStore = useLocalStore(() => ({
    confirmVisible: false,
    confirm: false,
    isReservation: false,
    regExp: new RegExp(intl.formatMessage({ id: 'label.delete' }), 'i'),
  }));

  const query = useQuery(USER_RESERVATIONS, {
    variables: {
      hotel: store.hotel._id,
      memberUser: store.user._id,
      status: 'added',
    },
  });
  const deleteMemberUser = useMutation(DELETE_MEMBER_USER);
  const setUserLogout = useMutation(SET_USER_LOGOUT);

  const handleDeleteAccount = useCallback(() => {
    if (!localStore.isReservation) {
      window.ask({
        title: intl.formatMessage({ id: 'label.deleteAccount.ask.title' }),
        content: (
          <>
            - {intl.formatMessage({ id: 'label.deleteAccount.ask.content.1' })}
            <br />
            <br />-{' '}
            {intl.formatMessage({ id: 'label.deleteAccount.ask.content.2' })}
          </>
        ),
        centered: true,
        okText: intl.formatMessage({ id: 'label.deleteAccount' }),
        onOk() {
          store.myInfoVisible = false;
          localStore.confirmVisible = true;
        },
      });
    } else {
      window.ask({
        title: intl.formatMessage({ id: 'label.deleteAccount.ask.pre.title' }),
        content: (
          <>
            {intl.formatMessage({
              id: 'label.deleteAccount.ask.pre.content.1',
            })}
            <br />
            {intl.formatMessage({
              id: 'label.deleteAccount.ask.pre.content.2',
            })}
          </>
        ),
        centered: true,
        onOk() {
          store.myInfoVisible = false;
          store.goToLink(
            `https://booking.barojaba.com/reservation/?h=${
              store.hotel._id
            }&session=${
              Cookies.get(`${store.hotel._id}_jwt`)
                ? Cookies.get(`${store.hotel._id}_jwt`)
                : ''
            }`,
          );
        },
      });
    }
  }, []);

  const handleChange = useCallback(e => {
    localStore.confirm = localStore.regExp.test(e.target.value);
  }, []);

  const handleSubmit = useCallback(async e => {
    const result = await deleteMemberUser({
      variables: {
        hotel: store.hotel._id,
        userId: store.user._id,
      },
    });

    if (result.errors && result.errors.length) {
      window.alert({
        title: intl.formatMessage({ id: result.errors[0].message }),
      });
    } else {
      window.success({
        title: intl.formatMessage({ id: 'label.deleteAccount.success.title' }),
        content: intl.formatMessage({
          id: 'label.deleteAccount.success.content',
        }),
        onOk() {
          setUserLogout();
        },
        onCancel() {
          setUserLogout();
        },
      });
    }
  }, []);

  useEffect(() => {
    if (query.data && query.data.userReservations) {
      if (query.data.userReservations.length) {
        localStore.isReservation = true;
      }
    }
  }, [query.data]);

  return (
    <>
      <Button type="default" block={true} onClick={handleDeleteAccount}>
        {intl.formatMessage({ id: 'label.deleteAccount' })}
      </Button>
      <Modal
        visible={localStore.confirmVisible}
        onCancel={() => (localStore.confirmVisible = false)}
        footer={null}
        centered={true}
        bodyStyle={{ paddingTop: '50px' }}
      >
        <Paragraph>
          <Text>
            <Icon style={{ color: '#e74c3c' }} type="info-circle" />
            &nbsp;
            {intl.formatMessage({ id: 'label.deleteAccount.ask.content.1' })}
          </Text>
        </Paragraph>
        <Paragraph>
          <Text>
            <Icon style={{ color: '#e74c3c' }} type="info-circle" />
            &nbsp;
            {intl.formatMessage({ id: 'label.deleteAccount.ask.content.2' })}
          </Text>
        </Paragraph>
        <Paragraph>
          <Text>
            <Icon style={{ color: '#e74c3c' }} type="info-circle" />
            &nbsp;
            {intl.formatMessage(
              { id: 'label.deleteAccount.ask.content.3' },
              { value: intl.formatMessage({ id: 'label.delete' }) },
            )}
          </Text>
        </Paragraph>
        <Input
          placeholder={intl.formatMessage({ id: 'label.delete' })}
          autoComplete="off"
          onChange={handleChange}
        />
        <Button
          type="danger"
          onClick={handleSubmit}
          disabled={!localStore.confirm}
          style={{ marginTop: '15px', marginBottom: '15px', float: 'right' }}
        >
          {intl.formatMessage({ id: 'label.delete' })}
        </Button>
      </Modal>
    </>
  );
});

export default UserDelete;
