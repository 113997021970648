/* eslint-disable react-hooks/exhaustive-deps, jsx-a11y/anchor-is-valid, no-script-url */
import React, { useEffect, useCallback } from 'react';
import { observer, useLocalStore } from 'mobx-react-lite';
import { countries } from 'countries-list';
import { useIntl } from 'react-intl';
import { useMutation } from 'react-apollo-hooks';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import { store } from '../../../shared/store';
import { SET_LANG, SET_USER_LOGOUT } from '../../../shared/queries';
import SignIn from './SignIn';

const Wrapper = styled.div`
  #header {
    z-index: 1;
  }

  ${breakpoint('xs', 'lg')`
    #header {
      z-index: 2;
    }    
  `}
`;

const Header = observer(props => {
  const intl = useIntl();
  const localStore = useLocalStore(() => ({
    hotelCountryPhone: '',
  }));

  const setLang = useMutation(SET_LANG);
  const setUserLogout = useMutation(SET_USER_LOGOUT);

  const onClickLogout = useCallback(() => {
    window.ask({
      title: intl.formatMessage({ id: 'label.sign.out.confirm' }),
      onOk() {
        setUserLogout();
      },
      okText: intl.formatMessage({ id: 'label.ok' }),
      cancelText: intl.formatMessage({ id: 'label.cancel' }),
      centered: true,
    });
  }, []);

  const handleChangeLang = useCallback(
    value => () => {
      setLang({ variables: { current: value } });
    },
    [],
  );

  const leftMenus = useCallback(() => {
    const value = [];
    const menus = window.toJS(store.hotel.homepage.menus);
    if (menus['about']) {
      value.push(
        <li key="menu_about">
          <a href="#about">About</a>
        </li>,
      );
    }
    if (menus['specialOffers']) {
      value.push(
        <li key="menu_specialOffers">
          <a href="#specialoffers">Special Offers</a>
        </li>,
      );
    }
    return value;
  }, []);
  const rightMenus = useCallback(() => {
    const value = [];
    const menus = window.toJS(store.hotel.homepage.menus);
    if (menus['rooms']) {
      value.push(
        <li key="menu_rooms">
          <a href="#room">Rooms</a>
        </li>,
      );
    }
    if (menus['facilities']) {
      value.push(
        <li key="menu_facilities">
          <a href="#facilities">Facilities</a>
        </li>,
      );
    }
    return value;
  }, []);

  useEffect(() => {
    const langs = window.toJS(store.hotel.langs);
    const defLang = String(langs[0]).toUpperCase();
    localStore.hotelCountryPhone = countries[defLang].phone;
  }, []);

  return (
    <Wrapper {...props}>
      <header id="header">
        <div className="header-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 col-md-5 col-sm-12">
                <ul className="contact-info custom-list list-inline">
                  <li>
                    <i className="fa fa-phone"></i>
                    <span>
                      +{localStore.hotelCountryPhone} {store.hotel.phone}
                    </span>
                  </li>
                  <li>
                    <i className="fa fa-envelope"></i>
                    <span style={{ textTransform: 'lowercase' }}>
                      {store.hotel.email}
                    </span>
                  </li>
                </ul>
              </div>
              <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12 text-right pull-right">
                <div className="contact-right">
                  <ul className="social custom-list list-inline">
                    {store.hotel.sns &&
                      Object.keys(store.hotel.sns).map((key, idx) =>
                        key !== '__typename' &&
                        store.hotel.sns[key] &&
                        store.hotel.sns[key].length ? (
                          <a
                            href={store.hotel.sns[key]}
                            target="_blank"
                            key={`sns_${idx}`}
                          >
                            <i className={`fa fa-${key}-square`}></i>
                          </a>
                        ) : null,
                      )}
                    {/* {store.hotel.sns && store.hotel.sns.facebook && (
                      <li>
                        <a href={store.hotel.sns.facebook} target="_blank">
                          <i className="fa fa-facebook-square"></i>
                        </a>
                      </li>
                    )}
                    {store.hotel.sns && store.hotel.sns.twitter && (
                      <li>
                        <a href={store.hotel.sns.twitter} target="_blank">
                          <i className="fa fa-twitter-square"></i>
                        </a>
                      </li>
                    )}
                    {store.hotel.sns && store.hotel.sns.instagram && (
                      <li>
                        <a href={store.hotel.sns.instagram} target="_blank">
                          <i className="fa fa-instagram"></i>
                        </a>
                      </li>
                    )} */}
                  </ul>
                  {store.user ? (
                    <>
                      <a
                        href="#!"
                        className="header-btn"
                        onClick={() => (store.myInfoVisible = true)}
                      >
                        Profile
                      </a>
                      <a
                        href="#!"
                        className="header-btn"
                        onClick={onClickLogout}
                      >
                        <i className="fa fa-power-off"></i> Logout
                      </a>
                    </>
                  ) : (
                    <div className="header-login">
                      <button className="login-toggle header-btn">
                        <i className="fa fa-power-off"></i> Login
                      </button>
                      <div className="header-form">
                        <SignIn />
                        {/* <form className="default-form">
                        <p className="alert-message warning">
                          <i className="ico fa fa-exclamation-circle"></i> All
                          fields are required!{' '}
                          <i className="fa fa-times close"></i>
                        </p>
                        <p className="form-row">
                          <input
                            className="required email"
                            type="text"
                            placeholder="Email"
                          />
                        </p>
                        <p className="form-row">
                          <input
                            className="required"
                            type="password"
                            placeholder="Password"
                          />
                        </p>
                        <p className="form-row">
                          <button className="submit-btn button btn-blue">
                            <i className="fa fa-power-off"></i> Login
                          </button>
                        </p>
                        <p className="form-row forgot-password">
                          <a href="#">Forgot Password?</a>
                        </p>
                        <p className="form-row register">
                          <a href="#">Register</a>
                        </p>
                      </form> */}
                      </div>
                    </div>
                  )}
                  <div className="header-language">
                    <button className="header-btn">
                      {countries[String(store.curLang).toUpperCase()].emoji}
                      &nbsp;
                      {intl.formatMessage({ id: `label.${store.curLang}` })}
                    </button>
                    <nav className="header-nav">
                      <ul className="custom-list">
                        {window.toJS(store.hotel.langs).map((item, idx) => (
                          <li
                            className={idx === 0 ? 'active' : ''}
                            key={item}
                            onClick={handleChangeLang(item)}
                          >
                            <a href="#">
                              {countries[String(item).toUpperCase()].emoji}
                              &nbsp;
                              {intl.formatMessage({ id: `label.${item}` })}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="header-navi">
          <div className="container">
            <div className="row">
              <div className="col-md-12" id="bs-example-navbar-collapse-1">
                <div className="row">
                  <div className="col-lg-5 col-md-5 col-sm-12">
                    <ul className="nav navbar-nav main-nav pull-right text-right">
                      <li className="active">
                        <a href="/">Home</a>
                      </li>
                      {leftMenus()}
                    </ul>
                  </div>
                  <div className="col-lg-5 col-lg-offset-2 col-md-5 col-md-offset-2 col-sm-12 col-sm-offset-0">
                    <ul className="nav navbar-nav main-nav pull-left text-left">
                      {rightMenus()}
                      {store.hotel.instagrams &&
                      store.hotel.instagrams.length ? (
                        <li>
                          <a href="#hashtag">HashTags</a>
                        </li>
                      ) : null}
                      <li>
                        <a href="#location">Location</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* <!-- End Header --> */}

      {/* <!-- Start Header-Toggle --> */}
      <div id="header-toggle">
        <i className="fa fa-bars"></i>
      </div>
      {/* <!-- End Header-Toggle --> */}

      {/* <!-- Start Header-Logo --> */}
      {store.hotel.logo && (
        <div className="header-logo">
          <div className="header-logo-inner">
            <div className="css-table">
              <div className="css-table-cell">
                <img
                  src={store.hotel.logo.url}
                  alt=""
                  className="img-responsive center-block"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </Wrapper>
  );
});

export default Header;
