/* eslint-disable react-hooks/exhaustive-deps*/
import React from 'react';

import styled from 'styled-components';

const Header = styled.div`
  border: 1px solid #bdc3c7;
  width: 100%;
  height: ${props => (props.headerHeight ? props.headerHeight : '120px')};
  background: #2c3e50;
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  background-image: url(${props => props.src});
`;

const ResultHeader = props => {
  const src = window.localStorage.getItem('jimp_header');

  return (
    <Header src={src} headerHeight={props.headerHeight}>
      <table style={{ width: '100%', height: '100%' }}>
        <tbody>
          <tr>
            <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
              <div style={{ color: 'white', fontSize: '30px' }}>
                {props.title}
              </div>
              {props.subTitle ? (
                <div
                  style={{
                    color: 'white',
                    fontSize: '14px',
                    marginTop: '8px',
                  }}
                >
                  {props.subTitle}
                </div>
              ) : null}
            </td>
          </tr>

          {props.children && (
            <tr>
              <td>{props.children}</td>
            </tr>
          )}
        </tbody>
      </table>
    </Header>
  );
};

export default ResultHeader;
