import breakpoint from 'styled-components-breakpoint';

import { createGlobalStyle } from 'styled-components';

import 'swiper/css/swiper.min.css';
import 'animate.css';
import './utils/mask/mask.css';

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Maven+Pro');
  @font-face {
    font-family: 'Roger';
    src: local('Roger'), url('/fonts/roger-sans_bold-webfont.woff') format('woff');
    src: local('Roger'), url('/fonts/Roger-Sans-Bold.otf') format('opentype');
  }   
  * {
    box-sizing: border-box;
  }
  body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
  a{
    color: inherit;
    text-decoration: none;
  }
  input, button, div, span {
    &:focus, &:active {
      outline: none;
    }
  }
  pre {
    overflow: hidden;
    white-space: pre-line;
    background: none !important;
    border: none !important;
    font-family: inherit !important;
    color: #808080 !important;
  }

  .ant-calendar-range-left .ant-calendar-column-header, .ant-calendar-range-left .ant-calendar-tbody tr td  {
    &:first-child {
      color: #e74c3c;

      &:not(.ant-calendar-disabled-cell):not(.ant-calendar-next-month-btn-day){
        .ant-calendar-date {
          color: #e74c3c;
        }
      }
    }
    &:last-child {
      color: #3498db;

      &:not(.ant-calendar-disabled-cell):not(.ant-calendar-next-month-btn-day){
        .ant-calendar-date {
          color: #3498db;
        }
      }      
    }
  }
  .ant-calendar-range-right .ant-calendar-table .ant-calendar-column-header, .ant-calendar-range-right .ant-calendar-tbody tr td {
    &:first-child {
      color: #e74c3c;
      
      &:not(.ant-calendar-disabled-cell):not(.ant-calendar-next-month-btn-day){
        .ant-calendar-date {
          color: #e74c3c;
        }
      }
    }
    &:last-child {
      color: #3498db;

      &:not(.ant-calendar-disabled-cell):not(.ant-calendar-next-month-btn-day){
        .ant-calendar-date {
          color: #3498db;
        }
      }      
    }
  }

  .hotel-info {
    .ant-modal {
      width: 100vw !important;
      height: 100vh !important;
      top: 0;
      left: 0;
      margin: 0;
      padding: 0;
      position: fixed;
    }
    .ant-modal-content {
      overflow-y: auto;
      width: 100% !important;
      height: 100% !important;
      padding: 0;
      background: rgba(0, 0, 0, 0.95);
      ${breakpoint('xs', 'lg')`
        width: 100vw !important;
      `}
    }
    .ant-modal-body {
      width: 100% !important;
      height: 100% !important;
      padding: 0;
    }
    .ant-modal-close {
      color: white;
      &:hover {
        color: #aaa;
      }
    }    
    .ant-modal-footer {
      display: none;
    }
    .ant-descriptions-item-content {
      color: #aaa;
    }
  }

  .ant-popover {
    .option-content {
      min-width: 150px;
      .row {        
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-left: 2px solid ${props => props.theme.primaryColor};
        padding-left: 8px;
        padding-right: 8px;
        margin-top: 10px;
        margin-bottom: 10px;
        height: 25px;
      }
      .ant-divider {
        margin: 10px 0;
      }
      p {
        max-width: 300px;      
        line-height: 25px;
        i {
          color: ${props => props.theme.primaryColor};
        }
      }
    }
  }

  .shadow {
    box-shadow: ${props => props.theme.shadow};
  }

  .setting-room-modal {
    .ant-select {
      width: 70px;
    }
  }

  .complete-table {
    border: 1px dashed #bdc3c7;
    border-top: 3px solid #bdc3c7;
    border-collapse: collapse;
    width: 100%;
    font-size: 14px;
    color: black;

    tr {
      &:first-child {
        th,
        td {
          border-top: none;
        }
      }
      &:last-child {
        th,
        td {
          border-bottom: none;
        }
      }
    }

    th {
      border: 1px solid #bdc3c7;
      padding: 15px;
      border-left: none;
      border-right: none;
      background: #f1efeb !important;
      font-weight: bold;
      text-align: center;
      vertical-align: middle;
    }
    td {
      border: 1px solid #bdc3c7;
      padding: 15px;
      border-left: none;
      border-right: none;
      vertical-align: middle;
    }
  }

  .complete-dropdown {
    ul {
      text-align: center;
    }
  }

  .ant-modal-content {
    overflow-y: auto;
  }

  .signup-modal {
    .ant-form-item {
      margin-bottom: 10px;
    }    
    #recaptcha {
      display: table;
      margin: 15px auto;
    }
    .submit {
      height: 50px;
      font-size: 24px;
    }
  }

  .full-screen-modal {
    .ant-modal {
      width: 100% !important;
      top: 0;
      left: 0;
      margin: 0;
      padding: 0;
    }
    .ant-modal-content {
      width: 100% !important;
      padding: 0;      
    }
    .ant-modal-body {
      width: 100% !important;
      height: 100% !important;
      padding: 0;
      background: white;      
    }
  }

  .ant-table {
    overflow-x: auto;
  }

  .single-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .ant-calendar-panel {
    width: 100% !important;    
  }
  .ant-calendar-date-panel {
    width: 100%;    
  }
  .ant-calendar-header {
    width: 100%;      
  }
  .ant-calendar-footer {
    width: 100%;
  }
  .ant-calendar-input-wrap {
    display: none !important;
  }

  ${breakpoint('xs', 'lg')`
    .ant-calendar-picker {
      width: 100%;
    }
    .ant-calendar-panel {
      width: 100vw;
    }
    .ant-calendar-date-panel {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .ant-calendar-range-part {
      width: 100% !important;
    }
    .ant-calendar-range-middle {
      display: none;
    }
    .ant-calendar-date-input-wrap {
      margin-left: 0 !important;
    }
  `}
`;

export default GlobalStyle;
