/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { store } from '../../../shared/store';

const Wrapper = styled.section`
  .gdlr-full-size-wrapper {
    padding-bottom: 0px;
    background-color: #ffffff;

    .gdlr-master-slider-item {
      margin-bottom: 0px;
    }

    .msp-cn-1-3 {
      width: 48px;
      height: 2px;
    }
    .msp-cn-1-9 {
      width: 509px;
    }
  }
`;

const Banner = observer(() => {
  useEffect(() => {
    const timer = setInterval(() => {
      if (window.MasterSlider) {
        clearInterval(timer);
        const masterslider_7f3f = new window.MasterSlider();

        // slider controls
        masterslider_7f3f.control('arrows', {
          autohide: true,
          overVideo: true,
        });
        masterslider_7f3f.control('bullets', {
          autohide: false,
          overVideo: true,
          dir: 'h',
          align: 'bottom',
          space: 8,
          margin: 25,
        });
        // slider setup
        masterslider_7f3f.setup('MS5af5c0e887f3f', {
          width: 1140,
          height: 530,
          minHeight: 0,
          space: 0,
          start: 1,
          grabCursor: true,
          swipe: false,
          mouse: true,
          keyboard: false,
          layout: 'fullwidth',
          wheel: false,
          autoplay: true,
          instantStartLayers: false,
          mobileBGVideo: false,
          loop: true,
          shuffle: false,
          preload: 0,
          heightLimit: true,
          autoHeight: false,
          smoothHeight: true,
          endPause: false,
          overPause: true,
          fillMode: 'fill',
          centerControls: true,
          startOnAppear: false,
          layersMode: 'center',
          autofillTarget: '',
          hideLayers: false,
          fullscreenMargin: 0,
          speed: 20,
          dir: 'h',
          parallaxMode: 'swipe',
          view: 'fadeFlow',
        });

        window.MSScrollParallax.setup(masterslider_7f3f, 30, 50, true);

        window.masterslider_instances = window.masterslider_instances || [];
        window.masterslider_instances.push(masterslider_7f3f);
      }
    }, 100);
  }, []);

  return (
    <Wrapper id="content-section-1">
      <div className="gdlr-full-size-wrapper gdlr-show-all">
        <div className="gdlr-master-slider-item gdlr-slider-item gdlr-item">
          <div
            id="P_MS5af5c0e887f3f"
            className="master-slider-parent ms-parent-id-2"
          >
            <div id="MS5af5c0e887f3f" className="master-slider ms-skin-default">
              {window.toJS(store.hotel.images).map((item, idx) => (
                <div
                  className="ms-slide"
                  data-delay="3"
                  data-fill-mode="fill"
                  key={`hotel_img_${idx}`}
                >
                  <img
                    src="/themes/theme3/plugins/masterslider/public/assets/css/blank.gif"
                    alt=""
                    title=""
                    data-src={item.url}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="clear"></div>
        <div className="clear"></div>
      </div>
      <div className="clear"></div>
    </Wrapper>
  );
});

export default Banner;
