/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { useMutation } from 'react-apollo-hooks';
import breakpoint from 'styled-components-breakpoint';

import { SAVE_HOMEPAGE_CONTACT } from './Queries';

const Wrapper = styled.div`
  #sync8 .owl-wrapper-outer .owl-wrapper {
    width: 100% !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  ${breakpoint('xs', 'lg')`
    .subtext-top {
      font-size: 14px;
    }
  `}
`;

const ContactUs = observer(() => {
  const $ = window.$;
  const saveHomepageContact = useMutation(SAVE_HOMEPAGE_CONTACT);

  const handleSubmit = useCallback(async e => {
    e.preventDefault();
    if ($) {
      $('.error').fadeOut('slow'); // reset the error messages (hides them)

      let error = false; // we will set this true if the form isn't valid

      const name = $('input#name')
        .val()
        .trim(); // get the value of the input field
      if (name === '' || name === ' ') {
        $('#err-name').fadeIn('slow'); // show the error message
        error = true; // change the error state to true
      }

      const email_compare = /^([a-z0-9_.-]+)@([da-z.-]+).([a-z.]{2,6})$/; // Syntax to compare against input
      const email = $('input#email')
        .val()
        .trim(); // get the value of the input field
      if (email === '' || email === ' ') {
        // check if the field is empty
        $('#err-email').fadeIn('slow'); // error - empty
        error = true;
      } else if (!email_compare.test(email)) {
        // if it's not empty check the format against our email_compare variable
        $('#err-emailvld').fadeIn('slow'); // error - not right format
        error = true;
      }

      if (error == true) {
        $('#err-form').slideDown('slow');
        return false;
      }

      const memo = $('textarea#message')
        .val()
        .trim();

      const data = {
        name,
        email,
        memo,
      };

      window.maskOn();
      let result;
      try {
        result = await saveHomepageContact({
          variables: { data },
        });
      } catch (error) {
      } finally {
        $('input#name').val('');
        $('input#email').val('');
        $('textarea#message').val('');
        window.maskOff();
      }

      if (result) {
        window.success({
          title: (
            <>
              문의 전송이 완료되었습니다.
              <br />
              신속히 확인하여 답변드리겠습니다.
            </>
          ),
        });
      } else {
        window.alert({
          title: (
            <>
              문의 전송 중 오류가 발생하였습니다.
              <br />
              담당자에게 직접 연락해 주세요.
            </>
          ),
          onOk() {
            document.querySelector('#sync8 #direct').click();
          },
          onCancel() {
            document.querySelector('#sync8 #direct').click();
          },
        });
      }
    }
  }, []);

  return (
    <Wrapper id="contact">
      <div className="container">
        <div
          className="sixteen columns"
          data-scrollreveal="enter top and move 150px over 1s"
        >
          <div className="section-top">
            <div className="line-dark"></div>
            <h1>contact us</h1>
            <div className="subtext-top">BAROJABA가 기다리고 있습니다.</div>
            <div className="line-dark1"></div>
          </div>
        </div>
      </div>

      <div className="owl-wrap-contact">
        <div id="sync7" className="owl-carousel">
          <div className="item">
            <div className="container">
              <div className="sixteen columns padding-bottom-contact">
                <p>담당자</p>
                <h3>함승균</h3>
              </div>
              <div className="sixteen columns padding-bottom-contact">
                <p>support</p>
                <h3>+82 10 9941 1773</h3>
              </div>
              <div className="sixteen columns">
                <p>e-mail</p>
                <h3>cmo@barojaba.com</h3>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="container">
              <div
                className="sixteen columns"
                data-scrollreveal="enter bottom and move 250px over 1s"
              >
                <form
                  name="ajax-form"
                  id="ajax-form"
                  method="post"
                  onSubmit={handleSubmit}
                >
                  <label htmlFor="name">
                    호텔명: *
                    <span className="error" id="err-name">
                      please enter hotel name
                    </span>
                  </label>
                  <input name="name" id="name" type="text" autoComplete="off" />
                  <label htmlFor="email">
                    E-Mail: *
                    <span className="error" id="err-email">
                      please enter e-mail
                    </span>
                    <span className="error" id="err-emailvld">
                      e-mail is not a valid format
                    </span>
                  </label>
                  <input
                    name="email"
                    id="email"
                    type="text"
                    autoComplete="off"
                  />
                  <label htmlFor="message">Message:</label>
                  <textarea
                    name="message"
                    id="message"
                    placeholder="담당자명 / 연락처 / 문의내용"
                    autoComplete="off"
                  ></textarea>
                  <button type="submit" className="send_message" id="send">
                    Submit
                  </button>
                  <div className="error" id="err-form">
                    There was a problem validating the form please check!
                  </div>
                  <div className="error" id="err-timedout">
                    The connection to the server timed out!
                  </div>
                  <div className="error" id="err-state"></div>
                </form>
                <div id="ajaxsuccess">Successfully sent!!</div>
              </div>
            </div>
          </div>
          {/* <div className="item">
            <div id="map"></div>
          </div> */}
        </div>

        <div id="sync8" className="owl-carousel">
          <div className="item" id="direct">
            <div className="icon-contact">&#xf095;</div>
          </div>
          <div className="item">
            <div className="icon-contact">&#xf003;</div>
          </div>
          {/* <div className="item">
            <div className="icon-contact">&#xf041;</div>
          </div> */}
        </div>
      </div>
    </Wrapper>
  );
});

export default ContactUs;
