import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

const Text = styled.div`
  text-align: ${props => (props.textAlign ? props.textAlign : 'center')};
  ${props => props.color && `color: ${props.color}`};
  ${props => props.size && `font-size: ${props.size}`};
  ${props => props.bold && `font-weight: bold`};
  ${props =>
    props.shadow &&
    `text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
      1px 1px 0 #000`};
`;

const StyledText = observer(props => {
  return <Text {...props}>{props.children}</Text>;
});

export default StyledText;
