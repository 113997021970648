/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect } from 'react';
import { DatePicker } from 'antd';
import { observer, useLocalStore } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import moment from 'moment';
import Cookies from 'js-cookie';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import { store } from '../../../shared/store';
import { TodayPricePopup } from '../../Common/TodayPrice';

const Wrapper = styled.div`
  .ant-calendar-picker {
    div,
    input {
      height: 100%;
      background: transparent;
      border: none;
      color: #fff;
    }
    .glyphicon {
      font-size: 18px;
      color: #fff;
    }
  }

  ${breakpoint('xs', 'lg')`
    .group {
      .input-group,
      .form-group {
        margin-left: 0;
        padding: 0;
      }      
    }
    .book-now {
      width: 100% !important;
      text-align: center !important;
      margin-left: 0 !important;
    }
  `};
`;

const disabledCheckIn = current => {
  // Can not select days before today and today
  return current && current < moment().subtract(1, 'days');
};

const Banner = observer(() => {
  const intl = useIntl();
  const localStore = useLocalStore(() => ({
    checkIn: moment(),
    checkOut: moment().add(1, 'days'),
    adult: intl.formatMessage({ id: 'label.booking.adults' }, { value: 2 }),
    room: intl.formatMessage({ id: 'label.booking.rooms' }, { value: 1 }),
    options: {
      adults: [],
      rooms: [],
    },
  }));

  const disabledCheckOut = useCallback(
    current => {
      // Can not select days before today and today
      return (
        current &&
        current.format('YYYY-MM-DD') <= localStore.checkIn.format('YYYY-MM-DD')
      );
    },
    [localStore.checkIn],
  );

  const handleChangeDate = useCallback(
    type => date => {
      localStore[type] = date;
      if (type === 'checkIn') {
        localStore.checkOut = null;
      }
    },
    [],
  );
  const handleChangeSelect = useCallback(
    type => e => {
      localStore[type] = e.target.value;
    },
    [],
  );

  const handleSubmit = useCallback(e => {
    if (!localStore.checkIn) {
      return window.alert({
        title: intl.formatMessage({ id: 'error.checkIn.required' }),
        centered: true,
      });
    }
    if (!localStore.checkOut) {
      return window.alert({
        title: intl.formatMessage({ id: 'error.checkOut.required' }),
        centered: true,
      });
    }
    if (!localStore.adult) {
      return window.alert({
        title: intl.formatMessage({ id: 'error.adult.required' }),
        centered: true,
      });
    }
    if (!localStore.room) {
      return window.alert({
        title: intl.formatMessage({ id: 'error.room.required' }),
        centered: true,
      });
    }

    const checkIn = localStore.checkIn.format('YYYY-MM-DD');
    const checkOut = localStore.checkOut.format('YYYY-MM-DD');
    const adult = localStore.adult.replace(/[^\d]/g, '');
    const room = localStore.room.replace(/[^\d]/g, '');
    store.goToLink(
      `https://booking.barojaba.com/?h=${
        store.hotel._id
      }&chkIn=${checkIn}&chkOut=${checkOut}&adult=${adult}&room=${room}&session=${
        Cookies.get(`${store.hotel._id}_jwt`)
          ? Cookies.get(`${store.hotel._id}_jwt`)
          : ''
      }`,
    );
  }, []);

  useEffect(() => {
    localStore.options.adults = [];
    localStore.options.rooms = [];

    for (let i = 0; i < 10; i++) {
      localStore.options.adults.push(i + 1);
      localStore.options.rooms.push(i + 1);
    }
    // window.handleChangeSelect = handleChangeSelect;
  }, []);

  return (
    <Wrapper
      id="home-revslider"
      className="slider-section container-fluid no-padding"
    >
      <TodayPricePopup />

      <div className="rev_slider_wrapper">
        <div id="home-slider1" className="rev_slider" data-version="5.0">
          <ul>
            {window.toJS(store.hotel.images).map((image, idx) => (
              <li
                data-transition="zoomout"
                data-slotamount="default"
                data-easein="easeInOut"
                data-easeout="easeInOut"
                data-masterspeed="2000"
                data-rotate="0"
                data-fstransition="fade"
                data-fsmasterspeed="1500"
                data-fsslotamount="7"
                key={`hotel_image_${idx}`}
              >
                <img
                  src={image.url}
                  alt="slider"
                  data-bgposition="center center"
                  data-bgfit="cover"
                  data-bgrepeat="no-repeat"
                  data-bgparallax="10"
                  className="rev-slidebg"
                  data-no-retina
                />
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="booking-section-1 container-fluid">
        <div className="container">
          <div className="col-md-4 col-sm-4 col-xs-6">
            <div className="booking-content-block">
              <h5>
                Book <span>your rooms</span>
              </h5>
            </div>
          </div>
          <div className="col-md-8 col-sm-8 col-xs-6">
            {/* <div
              id="datepicker3"
              className="input-group col-md-3 col-sm-3 col-xs-12"
            >
              <input
                className="form-control datepicker"
                placeholder="Arrival Date"
                type="text"
              />
              <span className="input-group-addon add-on">
                <span className="glyphicon glyphicon-calendar"></span>
              </span>
            </div> */}
            <div className="group">
              <div className="input-group col-md-3 col-sm-12 col-xs-12">
                <DatePicker
                  className="form-control"
                  value={localStore.checkIn}
                  onChange={handleChangeDate('checkIn')}
                  placeholder={intl.formatMessage({
                    id: 'label.checkIn',
                  })}
                  suffixIcon={
                    <span className="glyphicon glyphicon-calendar"></span>
                  }
                  allowClear={false}
                  disabledDate={disabledCheckIn}
                />
              </div>
              <div className="input-group col-md-3 col-sm-12 col-xs-12">
                <DatePicker
                  className="form-control"
                  value={localStore.checkOut}
                  onChange={handleChangeDate('checkOut')}
                  placeholder={intl.formatMessage({
                    id: 'label.checkOut',
                  })}
                  suffixIcon={
                    <span className="glyphicon glyphicon-calendar"></span>
                  }
                  allowClear={false}
                  disabledDate={disabledCheckOut}
                />
              </div>
              <div className="form-group col-md-3 col-sm-12 col-xs-12">
                <select
                  onChange={handleChangeSelect('room')}
                  value={localStore.room}
                >
                  {window.toJS(localStore.options.rooms).map((value, idx) => (
                    <option value={value} key={`booking_rooms_${idx}`}>
                      {intl.formatMessage(
                        { id: 'label.booking.rooms' },
                        { value },
                      )}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <a
              href="#!"
              title={intl.formatMessage({ id: 'label.booking' })}
              className="book-now"
              onClick={handleSubmit}
            >
              {intl.formatMessage({ id: 'label.booking' })}
            </a>
          </div>
        </div>
      </div>
    </Wrapper>
  );
});

export default Banner;
