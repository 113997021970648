import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

const ThemeStyle = styled.div`
  @import url('/themes/theme0/css/base.css');
  @import url('/themes/theme0/css/skeleton.css');
  @import url('/themes/theme0/css/layout.css?v=2');
  @import url('/themes/theme0/css/font-awesome.css');
  @import url('/themes/theme0/css/owl.carousel.css');
  @import url('/themes/theme0/css/animate.css');
  @import url('/themes/theme0/css/retina.css');
  @import url('/themes/theme0/css/royal_preloader.min.css');

  ul.slimmenu li {
    background: none;
  }

  .logo {
    width: 105px;
    height: 80px;
    background-size: 105px 80px;
  }
  .cbp-af-header.cbp-af-header-shrink .logo {
    width: 78px;
    height: 60px;
    background: url('/themes/theme0/images/logo.png') no-repeat center center;
    background-size: 78px 60px;
  }

  #home {
    text-shadow: -1px -1px 0 #333, 1px -1px 0 #333, -1px 1px 0 #333,
      1px 1px 0 #333;

    .home-text {
      opacity: 0;
      animation-duration: 0.5s;
      animation-fill-mode: forwards;
      .home-small-text {
        color: #fff;
      }
    }
  }

  #video_background {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  #solution {
    .grid-sizer,
    .grid-item {
      width: 25%;
    }
    .title {
      font-weight: bold;
      font-size: 18px;
      font-style: normal;
    }
    .description {
      line-height: 20px;
      transform: translateY(-50%);
      margin: 0;
      word-break: keep-all;
    }
  }

  #sep1,
  #sep2,
  #sep3 {
    background: rgba(0, 0, 0, 0.5);
  }
  #sep1 .container p a {
    font-size: 20px;
  }
  #sep1 .container p {
    font-size: 16px;
  }

  #ajax-form {
    input {
      width: 100%;
      margin-bottom: 15px;
    }
    textarea {
      width: 100%;
    }
  }

  #footer {
    padding-top: 50px;
    padding-bottom: 0;
  }

  ${breakpoint('xs', 'lg')`
    #solution {
      .grid-sizer,
      .grid-item {
        width: 100%;
      } 
    }    
  `};
`;

export default ThemeStyle;
