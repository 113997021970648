import { observable } from 'mobx';
import moment from 'moment';

export const store = observable({
  forceUpdate: false,
  curLang: '',
  hotel: {},
  hotelPackages: [],
  hotelPromotions: [],
  user: null,
  forgotVisible: false,
  myInfoVisible: false,
  detailVisible: false,
  selectedDetail: null,
  goToLink(url) {
    const win = window.open(url, '_blank');
    win.focus();
  },
  startDate: moment(),
  endDate: moment().add(1, 'days'),
  stayDays: 1,

  settingRoomData: {
    room: 1,
    adult: 2,
  },
  getUser: () => {},
  signInVisible: false,
  signUpVisible: false,
  reservationListVisible: false,

  handleClickMore: (item, key, isShowFacility) => e => {
    e.preventDefault();
    e.stopPropagation();
    store.detailVisible = false;
    const selectedDetail = {
      title: item[key].name || item[key].title,
      content: item[key].description || item[key].content,
      images: item.images,
    };
    if (isShowFacility) {
      selectedDetail.facilities = item.facilities;
    }
    store.selectedDetail = selectedDetail;
    store.detailVisible = true;
  },
});
