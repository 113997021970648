import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  .ant-modal {
    input {
      background: transparent !important;
      border: 1px solid #d9d9d9;
      color: rgba(0, 0, 0, 0.65);
    }
  }
  .ant-select-dropdown {
    ul,
    ol {
      margin: 0 !important;
    } 
  }    
`;

export default GlobalStyle;
