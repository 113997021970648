import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { useIntl } from 'react-intl';

import { store } from '../../../shared/store';
import TextareaView from '../../Common/TextareaView';

const Wrapper = styled.section`
  .portfolio-item-wrapper {
    margin-bottom: 20px;
  }
  .portfolio-excerpt {
    color: #a3a3a3;
  }

  .slides {
    li {
      img {
        width: 100%;
        height: auto;
      }
    }
  }

  ${breakpoint('xs', 'lg')`
   .slides {
      li {
        img {          
          height: 220px;
        }
      }
    }   
  `};
`;

const Facility = observer(() => {
  const intl = useIntl();

  return (
    <Wrapper id="facilities">
      <div className="section-container container">
        <div className="portfolio-item-wrapper type-classic-portfolio">
          <div className="portfolio-item-holder  gdlr-portfolio-column-3">
            <div
              className="gdlr-isotope"
              data-type="portfolio"
              data-layout="fitRows"
            >
              <div className="clear"></div>
              {window
                .toJS(store.hotel.homepage.curFacility)
                .map((item, idx) => (
                  <div className="four columns" key={`facility_${idx}`}>
                    <div className="gdlr-item gdlr-portfolio-item gdlr-classic-portfolio">
                      <div className="gdlr-ux gdlr-classic-portfolio-ux">
                        <div className="portfolio-thumbnail gdlr-slider">
                          <div
                            className="flexslider"
                            data-pausetime="7000"
                            data-slidespeed="600"
                            data-effect="fade"
                          >
                            <ul className="slides">
                              {item.images.map((img, imgIdx) => (
                                <li key={`facility_${idx}_img_${imgIdx}`}>
                                  <img src={img.url} alt="" />
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                        <h3 className="portfolio-title">
                          <a
                            href="#!"
                            onClick={store.handleClickMore(item, 'post')}
                          >
                            {item.post.title}
                          </a>
                        </h3>
                        <div className="portfolio-excerpt">
                          <TextareaView single>
                            {item.post.content}
                          </TextareaView>
                          <div className="clear"></div>
                          <a
                            href="#!"
                            className="excerpt-read-more"
                            onClick={store.handleClickMore(item, 'post')}
                          >
                            {intl.formatMessage({
                              id: 'label.readMore',
                            })}
                            &nbsp;
                            <i className="fa fa-long-arrow-right icon-long-arrow-right"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            <div className="clear"></div>
          </div>
        </div>
        <div className="clear"></div>
      </div>
    </Wrapper>
  );
});

export default Facility;
