export default {
  'label.lang': 'Language',

  'label.kr': 'Korean',
  'label.us': 'English',
  'label.jp': 'Japanese',
  'label.cn': 'Chinese',

  'label.zipcode': 'Zipcode',
  'label.address': 'Address',
  'label.fax': 'Fax',
  'label.tel': 'Tel',

  'label.thanks': 'Thank you.',
  'label.main': 'Go to Main',
  'label.none': 'None',
  'label.room': 'Room',
  'label.back': 'Back',
  'label.booking': 'Booking',
  'label.detail': 'Details',
  'label.amount': 'Amount',
  'label.name': 'Name',
  'label.email': 'E-mail',
  'label.phone': 'Phone number',
  'label.userId': 'ID',
  'label.password': 'Password',
  'label.memberPrice': 'Member Price',

  'label.all': 'All',
  'label.ok': 'Comfirm',
  'label.cancel': 'Cancel',
  'label.save': 'Save',
  'label.save.success': 'The save has been completed.',
  'label.add': 'Add',
  'label.remove': 'Remove',
  'label.delete': 'Delete',
  'label.modify': 'Modify',
  'label.select': 'Select',
  'label.search': 'Search',
  'label.new': 'New',
  'label.apply': 'Apply',

  'label.year': 'Year',
  'label.month': 'Month',
  'label.day': 'Day',
  'label.days': '{value}Day(s)',
  'label.day.0': 'Sunday',
  'label.day.1': 'Monday',
  'label.day.2': 'Tuesday',
  'label.day.3': 'Wednesday',
  'label.day.4': 'Thursday',
  'label.day.5': 'Friday',
  'label.day.6': 'Saturday',

  'label.goToLink': 'Shortcut',
  'label.readMore': 'Read More',
  'label.more': 'More',

  'label.checkIn': 'Check-In',
  'label.checkOut': 'Check-Out',
  'label.personnel': 'Personnel',
  'label.booking': 'Booking',
  'label.booking.adults': '{value} Adult(s)',
  'label.booking.rooms': '{value} Room(s)',

  // popup
  'label.popup.notShowToday': 'Do not show today',
  'label.popup.notShowAgain': 'Do not show again',

  // today price
  'label.todayPrice.today': 'TODAY',
  'label.todayPrice.price': 'PRICE',
  'label.todayPrice.title1': 'Today Price.',
  'label.todayPrice.title2': 'Most reasonable price!',
  'label.todayPrice.leftLabel': 'Official Website',
  'label.todayPrice.memberDiscount':
    'Up to {value}% extra discount when you booking a member!',

  // instagram
  'label.instagram.title': 'Recent photos of the {value}',
  'label.instagram.subTitle':
    'Share your moments with the {value} on Instagram',

  // ota
  'label.ota.expedia': 'Expedia',
  'label.ota.agoda': 'Agoda',
  'label.ota.bookingdotcom': 'Booking.com',
  'label.ota.interpark': 'Interpark',
  'label.ota.hotelnow': 'Hotelnow',

  // placeholder
  'label.placeholder.phone': 'Please enter without - .',
  'label.placeholder.email': 'E-mail',
  'label.placeholder.password': 'Password',
  'label.placeholder.forgot.email': 'Please enter E-mail when you sign in.',

  // sign
  'label.sign.member': 'Members',
  'label.sign.member.none': 'Non-Members',
  'label.sign.in': 'Login',
  'label.sign.in.title': '{value} Login',
  'label.sign.in.reservation':
    'If you make a reservation as a member, you can confirm your reservation by log in.',
  'label.sign.none.reservation':
    'For non-member reservation inquiry, you must enter a E-mail and booking number.',

  'label.sign.up': 'Join',
  'label.sign.up.title': '{value} Join',
  'label.sign.up.easy': 'Easy Join',
  'label.sign.up.firstName': 'First Name',
  'label.sign.up.lastName': 'Last Name',
  'label.sign.up.email': 'E-mail',
  'label.sign.up.phone': 'Phone',
  'label.sign.up.nationality': 'Nationality',
  'label.sign.up.password': 'Password',
  'label.sign.up.passwordConfirm': 'Password Confirm',
  'label.sign.up.success': 'Sign up is succeed.',
  'label.sign.up.policy1': 'Terms and conditions of use of the site',
  'label.sign.up.policy2':
    'Consent on the collection and utilization of personal information',
  'label.sign.up.policy3':
    'Consent on the purpose of personal information collection and utilization',
  'label.sign.up.policy4':
    'Agreement to receive advertising information (optional)',
  'label.sign.up.policy.more': 'more',
  'label.sign.up.policy.all': 'Entire agreement on the above agreement',
  'label.sign.up.email.subject':
    '[{hotelName}] Congratulations on your membership.',
  'label.sign.up.email.title1': ' ',
  'label.sign.up.email.title2': ' ',
  'label.sign.up.email.title3': 'Membership',
  'label.sign.up.email.title4': 'Congratulation.',
  'label.sign.up.email.title5': " You're a member.",
  'label.sign.up.email.title6':
    'we will always do our best for the best service.',
  'label.sign.up.email.title7': 'Your membership information is as follows.',
  'label.sign.up.email.userName': 'Name',
  'label.sign.up.email.userId': 'ID',
  'label.sign.up.result.info1': 'Welcome to our {hotelName}.',
  'label.sign.up.result.info2': "We'll provide the best service.",
  'label.sign.up.result.info3':
    '{hotelName} The information you have entered is limited to our site.',

  'label.sign.out': 'Log out',
  'label.sign.out.confirm': 'Are you sure you want to Log out?',

  'label.sign.forgot.userId': 'Forgot ID?',
  'label.sign.forgot.password': 'Forgot Password?',
  'label.sign.forgot.submit': 'Initialization',
  'label.sign.forgot.email.subject': '[{hotelName}] Password is initialized. ',
  'label.sign.forgot.email.title1': ' ',
  'label.sign.forgot.email.title2': ' ',
  'label.sign.forgot.email.title3': 'Your',
  'label.sign.forgot.email.title4': 'Password',
  'label.sign.forgot.email.title5': 'is initialized.',
  'label.sign.forgot.email.newPassword': 'New password',
  'label.sign.forgot.email.changeDateLabel': 'Change date',
  'label.sign.forgot.email.changeDate':
    '{year}-{month}-{date} {hour}:{min}:{sec} ',
  'label.sign.forgot.success': 'A new password has been sent to E-mail.',

  // user
  'label.user.myInfo': 'Profile',
  'label.user.myInfo.title': '{value} Members Information',
  'label.user.myInfo.save.success': '{value}. The save has been completed.',

  // delete account
  'label.deleteAccount': 'Delete Account',
  'label.deleteAccount.success.title': 'Delete your account is succeed.',
  'label.deleteAccount.success.content': 'Thank you for your use.',
  'label.deleteAccount.ask.title':
    'Are you sure you want to delete your account?',
  'label.deleteAccount.ask.content.1':
    'All information will be deleted and you will no longer have access to your membership benefits.',
  'label.deleteAccount.ask.content.2':
    'Deleted information cannot be recovered.',
  'label.deleteAccount.ask.content.3':
    'If you agree, typing {value} in the box below.',
  'label.deleteAccount.ask.pre.title': 'Your booking has been viewed.',
  'label.deleteAccount.ask.pre.content.1':
    'You must cancel your booking before delete your account.',
  'label.deleteAccount.ask.pre.content.2': 'Do you want to proceed?',

  // error
  'error.login': 'An error occurred while log in.',
  'error.user.exist': 'Existing E-mail address.',
  'error.user.none': 'User not found.',
  'error.add.room': 'Only {cnt} rooms will be added.',
  'error.access': 'Wrong access',
  'error.select.required': 'Required option!',
  'error.username.required': 'Please enter login E-mail!',
  'error.password.required': 'Please enter the password!',
  'error.password.incorrect': 'Password incorrect.',
  'error.firstName.required': 'Please enter your first name!',
  'error.lastName.required': 'Please enter your last name!',
  'error.name.required': 'Please enter your name!',
  'error.email.required': 'Please enter your email!',
  'error.phone.required': 'Please enter your phone!',

  'error.forgotPassword': 'An error occurred while resetting password.',
  'error.email.template':
    'An error occurred in the email template while sending.',
  'error.email.send': 'An error occurred while sending email.',

  'error.checkIn.required': 'Please enter Check-In date!',
  'error.checkOut.required': 'Please enter Check-Out date!',
  'error.adult.required': 'Please enter Adult(s)!',
  'error.room.required': 'Please enter Room(s)!',

  'error.deleteAccount': 'An error occurred while delete your account.',

  // error valid
  'error.email.valid': 'Email form incorrect!',
  'error.phone.valid': 'Phone number form incorrect!',
  'error.password.valid':
    'Please Enter 6 to 20, alpha + special characters or numeric characters!',

  // policy
  'signup.policy1': `
  제 1 조 (목적)
  이 약관은 {hotelName}(이하 "회사"라 합니다)이 제공하는 인터넷 서비스 (영문명 {homepageUrl} 이하 "서비스"라 한다)의 이용 조건 및 절차에 관한 기본적인 사항에 관련 정의를 목적으로 합니다.

  제 2 조 (약관의 효력 및 변경)
  ① 이 약관은 서비스 메뉴 및 회사에 게시하여 공시함으로써 효력을 발생합니다. 
  ② 회사는 이 약관의 내용을 변경할 수 있으며, 변경된 약관은 제1항과 같은 방법으로 공지 또는 통지함으로써 효력을 발생합니다. 
  ③ 회원제서비스 이용고객은 변경된 약관 사항에 동의하지 않으면 서비스 이용을 중단하고 이용계약을 해지할 수 있습니다. 
  ④ 약관의 효력발생일 이후의 계속적인 서비스 이용은 약관의 변경사항에 동의한 것으로 간주 됩니다.

  제 3 조 (약관 이외의 준칙)
  이 약관에서 언급되지 않은 사항이 전기통신기본법, 전기통신사업법, 기타 관련법정에 규정되어 있는 경우 그 규정에 따라 적용할 수 있습니다.

  제 4 조 (용어의 정의)
  이 약관에서 사용하는 용어의 정의는 다음과 같습니다. 
  ① 회원 : 서비스를 제공 받기 위하여 회사와 이용계약을 체결하거나 이용자 아이디(ID)를 부여 받은 자를 말합니다. 
  ② 아이디(ID) : 회원의 식별과 서비스를 이용을 위하여 회원이 정하고 회사가 승인하는 문자와 숫자의 조합을 말합니다. 
  ③ 비밀번호 : 회원이 부여 받은 아이디(ID)와 일치된 회원임을 회원 자신의 비밀을 보호하기 위하여 회원이 정한 문자와 숫자의 조합을 말합니다. 
  ④ 해지 : 회사 또는 회원이 서비스를 개통 후 이용계약을 해약 하는 것을 말합니다.

  제 5 조 (이용계약의 성립)
  ① "이용약관에 동의하십니까?"라는 이용신청시의 물음에 회원이 "동의" 버튼을 클릭하면 이 약관에 동의하는 것으로 간주됩니다. 
  ② 이용계약은 서비스 이용희망자의 이용약관 동의 후 신청에 대하여 회사가 승낙함으로써 성립합니다.

  제 6 조 (이용신청)
  ① 본 서비스를 이용하기 위해서는 회사 소정의 가입신청 양식에서 요구하는 모든 이용자 정보를 기록하여 신청합니다. 
  ② 가입신청 양식에 기재하는 모든 이용자 정보는 모두 실제 데이터인 것으로 간주됩니다. 
  ③ 실명이나 실제 정보를 입력하지 않은 사용자는 법적으로 보호를 받을 수 없으며 서비스의 제한을 받을 수 있습니다. 
  ④ 만 14세 미만 아동은 본 서비스를 이용하실 수 없습니다.

  제 7 조 (이용신청의 승낙)
  ① 회사는 제 6 조에 다른 이용 신청 고객에 대하여 제 2 호, 제 3 호의 경우를 예외로 하여 서비스 이용신청을 승낙합니다. 
  ② 회사는 다음에 해당하는 경우에 그 신청에 대한 승낙을 제한사유가 해소 될 때까지 승낙을 유보할 수 있습니다.
    가. 서비스 관련 설비에 여유가 없는 경우
    나. 기술상 지장이 있는 경우
    다. 기타 회사가 필요하다고 인정되는 경우    
  ③ 회사는 다음에 해당하는 경우에는 이를 승낙하지 아니 할 수 있습니다.
    가. 다른 사람의 명의를 사용하여 신청한 경우
    나. 이용신청 시 이용자정보를 허위로 기재하여 신청한 경우
    다. 사회의 안녕질서 또는 미풍양속을 저해할 목적으로 신청한 경우
    라. 기타 회사 소정의 이용신청요건을 충족하지 못하는 경우

  제 8 조 (이용자정보의 변경)
  회원은 이용신청 시 기재한 이용자정보가 변경되었을 경우에는, 온라인으로 수정을 하여야 하며 미변경으로 인하여 발생되는 문제의 책임은 이용자에게 있습니다.

  제 9 조 (회사의 의무)
  ① 회사는 서비스 제공과 관련하여 취득한 회원의 개인정보를 본인의 사전 승낙 없이 타인에게 공개 또는 배포할 수 없습니다. 단, 다음 각 호의 1에 해당하는 경우는 예외입니다.
    가. 전기통신기본법 등의 법률의 규정에 의해 국가기관의 요구가 있는 경우
    나. 범죄에 대한 수사상의 목적이 있거나 정보통신윤리 위원회의 요청이 있는 경우
    다. 기타 관계법령에서 정한 절차에 따른 요청이 있는 경우
  ② 1 항의 범위 내에서, 회사는 업무와 관련하여 회원 전체 또는 일부의 개인 정보에 관한 통계 자료를 작성하여 이를 사용할 수 있고, 서비스를 통하여 회원의 컴퓨터 쿠키를 전송할 수 있습니다. 이 경우 회원은 쿠키의 수신을 거부하거나 쿠키의 수신에 대하여 경고하도록 사용하는 컴퓨터의 브라우저의 설정을 변경할 수 있습니다.

  제 10 조 (회원의 의무)
  ① 회원은 서비스 이용 시 다음 각 호의 행위를 하지 않아야 합니다.
    가. 다른 회원의 ID를 부정하게 사용하는 행위
    나. 서비스에서 얻은 정보를 회사의 사전승낙 없이 회원의 이용 이외의 목적으로 복제하거나 이를 변경, 출판 및 방송 등에 사용하거나 타인에게 제공하는 행위
    다. 회사의 저작권, 타인이 저작권 등 기타 권리를 침해하는 행위
    라. 공공질서 및 미풍양속에 위반되는 내용이 정보, 문장, 도형 등을 타인에게 유포하는 행위
    마. 범죄와 결부된다고 객관적으로 판단하는 행위
    바. 기타 관계법령에 위배되는 행위
  ② 회원은 관계법령, 이 약관에서 규정하는 사항, 서비스 이용 안내 및 주의 사항을 준수 하여야 합니다. 
  ③ 회원은 내용별로 회사가 서비스 공지사항에 게시하거나 별도로 공지한 이용 제한 사항을 준수하여야 합니다. 
  ④ 회원은 회사의 사전 승낙 없이 서비스를 이용하여 어떠한 영리행위도 할 수 없습니다.

  제 11 조 (회원 아이디(ID)와 비밀번호 관리에 대한 회원의 의무)
  ① 아이디(ID)와 비밀번호에 대한 모든 관리는 회원에게 책임이 있습니다. 
  ② 회원에게 부여된 아이디 (ID)와 비밀번호의 관리소홀, 부정사용에 의하여 발생하는 모든 결과에 대한 전적인 책임은 회원에게 있습니다. 
  ③ 비밀번호는 회원의 정보 보호를 위해 숫자와 문자 등을 포함해야 합니다. 
  ④ 자신의 아이디(ID)가 부정하게 사용된 경우 또는 기타 보안 위반에 대하여, 회원은 반드시 회사에 그 사실을 통보해야 합니다.

  제 12 조 (정보의 제공)
  회사는 회원의 서비스 이용 중 필요가 있다고 인정되는 다양한 정보에 대해서 전자메일이나 서신우편 등의 방법으로 회원에게 제공할 수 있으며, 회원은 원하지 않을 경우 가입신청 메뉴와 회원정보수정 메뉴에서 정보수신거부를 할 수 있습니다.

  제 13 조 (회원의 게시물)
  회사는 회원이 본 서비스를 통하여 게시, 게재, 전자메일 또는 달리 전송한 내용물에 대하여 책임을 지지 않으며, 다음 각 호의 1 에 해당한다고 판단되는 경우에 사전 통지 없이 삭제할 수 있습니다. 
  ① 다른 회원이나 타인을 비방하거나, 프라이버시를 침해하거나, 중상모략으로 명예를 손상시키는 내용인 경우
  ② 서비스의 안정적인 운영에 지장을 주거나 줄 울려가 있는 경우
  ③ 범죄적 행위에 관련된다고 인정되는 내용인 경우
  ④ 회사의 지적재산권, 타인의 지적재산권 등 기타 권리를 침해하는 내용인 경우 
  ⑤ 회사에서 규정한 게시기간을 초과한 경우 ⑥ 기타 관계법령에 위반된다고 판단되는 경우

  제 14 조 (게시물에 대한 권리 및 책임)
  게시물에 대한 저작권을 포함한 모든 권리 및 책임은 이를 게시한 회원에게 있습니다.

  제 15 조 (메일의 내용)
  ① 회사는 회원의 메일 내용을 편집하거나 감시하지 않습니다. 
  ② 메일의 내용에 대한 책임은 각 회원에게 있습니다. 
  ③ 회원은 허가를 받지 않고서 음란물이나 불온한 내용, 정크메일(junk mail), 스펨메일(spam mail) 및 타인에게 피해를 주거나 미풍양속을 해치는 메일을 보내서는 안됩니다. 
  ④ 전기통신법, 제 53 조와 전기통신사업법 시행령 16 조(불온통신), 통신사업법 제 53 조 3항에 의거, 음란물이나 불온한 내용을 전송함으로서 발생하는 모든 법적인 책임은 회원에게 있으며 회사는 책임지지 않습니다. 
  ⑤ 본 서비스를 이용하여 타인에게 피해를 주거나 미풍양속을 해치는 행위를 하신 회원이 아이디 (ID)와 메일은 보호 받을 수 없습니다.

  제 16 조 (서비스 이용시간)
  ① 서비스는 회사의 업무상 또는 기술상의 장애, 기타 특별한 사유가 없는 한 연중무휴, 1일 24시간 이용할 수 있습니다. 다만, 설비의 점검 등 회사가 필요한 경우 또는 설비의 장애, 서비스 이용의 폭주 등 불가항력으로 이하여 서비스 이용에 지장이 있을 경우 예외적으로 서비스 이용의 전부 또는 일부에 대하여 제한할 수 있습니다. 
  ② 회사는 제공하는 서비스 중 일부에 대한 서비스 이용시간을 별도로 정할 수 있으며, 이 경우 그 이용시간을 사전에 회원에게 공지 또는 통지 합니다.

  제 17 조 (서비스 이용 책임)
  회원은 서비스를 이용하여 불법 상품을 판매하는 영업 활동을 할 수 없으며 특히 해킹, 돈벌기광고, 음란사이트를 통한 상업행위, 상용 S/W 불법배포 등을 할 수 없습니다. 
  이를 어기고 발생한 영업활동의 결과 및 손실, 관계기관에 의한 구속 등 법적 조치등에 관해서는 회사가 책임을 지지 않습니다.

  제 18 조 (서비스 제한 및 정지)
  회사는 전시, 사변, 전재지변 또는 이에 준하는 국가비상사태가 발생하거나 발생할 우려가 있는 경우와 전기통신사업법에 의한 기간통신 사업자가 전기통신서비스를 중지하는 둥 기타 불가항력적 사유가 있는 경우에는 서비스의 전부 또는 일부를 제한 하거나 정지할 수 있습니다. 
  회사는 제 1 항의 규정에 의하여 서비스의 이용을 제한하거나 정지한 때에는 그 사유 및 제한기간 등을 지체 없이 회원에게 알려야 합니다.

  제 19 조 (개인정보 보호 정책)
  개인정보 관리 책임자: {hotelName}, Tel: {hotelPhone}, Email: {hotelEmail}
  * 개인정보 침해 신고센타 (전화 : 118, URL : https://privacy.kisa.or.kr/)

  제 20 조 (계약해지 및 이용제한)
  ① 회원이 이용계약을 해지하고자 하는 때에는 회원 본인이 회사에 해지신청을 하여야 합니다. ② 회사는 회원이 다음 각 호의 1 에 해당하는 행위를 하였을 경우 사전통지 없이 이용계약을 해지하거나 또는 기간을 정하여 서비스를 중지할 수 있습니다.
    가. 타인의 서비스 ID 및 비밀번호를 도용한 경우
    나. 서비스 운영을 고의로 방해한 경우
    다. 공공질서 및 미풍양속에 저해되는 내용을 고의로 유포시킨 경우
    라. 회원이 국익 또는 사회적 공익을 저해할 목적으로 서비스이용을 계획 또는 실행하는 경우
    마. 타인의 명예를 손상시키거나 불이익을 주는 행위를 한 경우
    바. 서비스의 안정적 운영을 방해할 목적으로 다량이 정보를 전송하거나 광고성 정보를 전송한 경우
    사. 정보통신설비의 오작동이나 정보 등의 파괴를 유발시키는 컴퓨터 바이러스프로그램등을 유포하는 경우
    아. 회사, 다른 회원 또는 타인의 지적재산권을 침해하는 경우
    자. 정보통신윤리위원회 등 외부기관의 시정요구가 있거나 불법선거 운동과 관련하여 선거관리위원회의 유권해석을 받은 경우
    차. 타인의 개인정보, 이용자 ID 및 비밀번호를 부정하게 사용하는 경우
    카. 회사의 서비스 정보를 이용하여 얻은 정보를 회사의 사전 승낙 없이 복제 또는 유통시키거나 상업적으로 이용하는 경우
    타. 회원이 자신의 홈페이지와 게시판에 음란물을 게재하거나 음란사이트를 링크하는 경우
    파. 본 약관을 포함하여 기타 회사가 정한 이용조건 및 관계법령에 위반한 경우

  제 21 조 (손해배상)
  회사는 서비스 요금이 무료인 동안의 서비스 이용과 관련하여 회원에게 발생한 어떠한 손해에 관하여도 책임을 지지 않습니다.

  제 22 조 (면책조항)
  ① 회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우 에는 서비스 제공에 관한 책임이 면제됩니다. 
  ② 회사는 회원이 귀책사유로 인하여 서비스 이용의 장애가 발생한 경우에는 책임이 면제됩니다. 
  ③ 회사는 회원이 회사의 서비스 제공으로부터 기대되는 이익을 얻지 못하였거나, 서비스 자료에 대한 취사 선택 또는 이용으로 발생하는 손해 등에 대해서는 책임이 면제됩니다. 
  ④ 회사는 회원이 서비스에 게재한 정보, 자료, 사실의 신뢰도, 정확성 등 그 내용에 관하여는 책임이 면제됩니다.

  제 23 조 (관할법원)
  본 약관 상이 분쟁에 대해 소송이 제기될 경우 회사의 본사 소재지를 관할하는 법원을 전속 관할법원으로 합니다. 
  [부칙] (시행일) 이 약관은 2019년 12월 1일부터 시행합니다.
  `,

  'signup.policy2': `
  본 회원가입(서비스계약)과 관련하여 「개인정보보호법」 제15조,제22조 및 제24조에 따라 귀사가 아래와 같이 본인의 개인정보를 수집•이용하는데 동의합니다.

  1. 개인정보의 수집•이용 목적
  본인식별, 상품서비스제공, 법령상 의무이행, 분쟁처리, 민원처리 등
  
  2. 수집하는 개인정보의 항목
  <필수항목>
  개인식별정보: 성명,아이디,비밀번호
  상품서비스제공정보: 이메일주소, 휴대전화번호

  3. 개인정보의 보유•이용기간
  수집•동의일로부터 개인정보의 수집•이용 목적 달성 시까지

  4. 동의를 거부할 권리 및 동의를 거부할 경우의 불이익
  상기 개인정보의 필수적인 수집이용에 관한 사항에 대한 동의가 없는 경우 거래관계의 설정 또는 유지가 불가능할 수 있음을 알려드립니다.

  5. 위탁처리 기관
  - 업체 : 주식회사 바로자바
  - 업무: 웹사이트 관리 및 시스템 관리

  6. 개인정보처리의 위탁 및 수탁
  회사는 정보주체의 동의 없이 개인정보의 처리를 외부 업체에 위탁하지 않습니다.
  회사는 아래와 같이 개인정보의 처리를 위탁하고 있으며, 관련 법령에 따라 위탁계약 시 개인정보가 안전하게 관리될 수 있도록 필요한 사항을 규정하고 있습니다.

  7. 위탁처리기관
  업체 : 주식회사 바로자바 (업무 웹사이트관리 및 시스템 관리)
    가. 마케팅 활용에 관한 사항
    - {hotelName} 마케팅 활용(TM활용 등)을 목적으로 하는 개인정보 수집 활용 동의 합니다. 
    - 마케팅 활용에 대해 거부 할 수 있으며 미동의시에도 회원 가입이 가능합니다.

    나. 개인 정보 제공에 관한 사항
    - {hotelName}은(는) 고객에 대하여 보다 더 질 높은 서비스 제공 등을 위해 아래와 같이 개인정보를 제공하고 있습니다.

    다. 제휴사 제공항목 이용목적 제공기간
    - 성명,연락처,이메일, 구매정보, 회원번호 
    - 제휴서비스제공
    - 포인트 적립 및 사용에 대한

    라. 승인과 정산 시 활용
    - 서비스 및 신상품 홍보
    - 소식지 제공

    마. 회원탈퇴 시 동의를 거부할 권리 및 동의를 거부할 경우의 불이익
    - 위 개인정보의 제공에 관련한 동의는 회원서비스제공을 위하여 필수적이므로 위 사항에 동의하셔야만 원할할 회원서비스 제공이 가능합니다. 
    - 동의 후에도 언제든지 제공에 대한 동의를 철회할 수 있으나 동의를 철회하는 경우 회원 서비스 제공을 받을 수 없습니다.
  `,

  'signup.policy3': `
  개인정보 수집, 이용에 대한 동의 절차
  제1조 개인 정보 수집 범위
  - {hotelName}은(는) 별도의 사용자 등록이 없이 언제든지 사용할 수 있습니다. 
  - {hotelName}은(는) 이용자들에게 맞춤식 서비스를 비롯해, 보다 향상된 양질의 서비스를 제공하기 위해 이용자 개인의 정보를 수집하고 있습니다. 
  - {hotelName}은(는) 이용자의 사전 동의 없이는 이용자의 개인 정보를 함부로 공개하지 않습니다.

  제2조 개인정보의 수집목적 및 이용 목적
  - {hotelName}은(는) 프로그램상의 신규서비스 개발이나 보완시에 기존 이용자들이 회사에 제공하였던 개인정보를 바탕으로 더 효과적인 작업을 진행할 수 있으며, 수집되는 개인정보와 황용내역은 아래와 같습니다.
    1. 객실 예약시에 수집하는 필수 개인정보는 성명, 연락처, 이메일, 신용카드
    2. 고객센터에서 수집하는 필수 개인정보는 성명, 이메일, 연락처입니다. 해당 입력정보는 고지사항 전달, 불만 처리 및 답변 등 원활한 의사소통 경로의 확보를 위해 활용됩니다.
    3. 미팅룸 및 연회장 예약 문의시에 수집하는 필수 개인정보는 회사명, 예약자명, 연락처, 이메일입니다. 또한 예약 문의시에 이용자들은 추가적인 서비스의 제공을 요청할 수 있습니다. 이 입력된 예약정보는 원활한 예약업무 및 문의 답변을 위해 활용됩니다.

  제3조 개인 정보의 보유기간 및 이용기간
  - {hotelName}은(는) 위에서 이용자들이 제공한 개인정보 항목들만 수집하여 사용하며, 개인정보 이용목적으로 사용한 후에는 그 수집된 개인정보는 파기됩니다. 다만, 소비자에 관한 법률, 관계법령 등의 규정에 따라 그 정보의 목적으로 사용이 끝날 때까지 정보를 보유해야 하는 경우에는, 그 후에 고객의 모든 정보는 삭제 또는 파기됩니다. 
  - 수집된 개인정보는 {hotelName}이(가) 제공하는 서비스를 받으시는 동안 안전하게 보유되고, 이용목적에 적합한 경우에만 이용하게 됩니다.
  `,

  'signup.policy4': `
  1. 수신 동의한 이메일, SMS로 할인 쿠폰, 이벤트 등의 특별한 혜택 및 상품 정보를 받아 볼 수 있습니다.
  2. 수신 거부 시 사용 가능한 쿠폰 및 예약 알림 등이 발송되지 않습니다.
  - 항목 : 이메일, SMS
  - 전송자 : 호텔명
  - 전송자 연락처 : 호텔 이메일
  - 이용목적 : 새로운 서비스 및 이벤트 정보 등의 안내
  - 보유기간 : 별도 동의 철회 시 까지 (또는) 회원 탈퇴 후 3일 까지
  
  3. 파기절차 및 방법
  - 파기절차 : 회사는 파기 사유가 발생한 개인정보를 선정하고 개인 정보 보호책임자 승인 후 개인정보를 파기함
  - 파기방법 : 회사는 전자적 파일 형태로 처리된 정보는 기록을 재생 할 수 없도록 파기하며, 종이 문서에 의하여 처리된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기함
  
  4. 동의한 개인 정보는 {hotelName}에서 새로운 서비스 및 이벤트 정보 등의 안내를 하기 위해 {hotelName} 관리자 데이터베이스에 수집되고있으며, 제3자는 광고성 정보를 보낼 수 없습니다.
  `,
};
