/* eslint-disable react-hooks/exhaustive-deps, jsx-a11y/anchor-is-valid */
import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

import { store } from '../../../shared/store';
import TextareaView from '../../Common/TextareaView';

const Wrapper = styled.section`
  .gdlr-color-wrapper {
    background-color: #0a0a0a;
    padding-top: 60px;
  }

  .column-service-image {
    img {
      width: 100%;
      height: auto;
    }
  }
`;

const About = observer(() => {
  const intl = useIntl();

  return (
    <Wrapper id="about">
      <div className="gdlr-color-wrapper gdlr-show-all no-skin">
        <div className="container">
          {window.toJS(store.hotel.homepage.curAbout).map((item, idx) => (
            <div className="four columns" key={`about_${idx}`}>
              <div className="gdlr-ux column-service-ux">
                <div className="gdlr-item gdlr-column-service-item gdlr-type-2">
                  <div className="column-service-image">
                    <img src={item.images[0].url} alt="" />
                  </div>
                  <div className="column-service-content-wrapper">
                    <h3 className="column-service-title">{item.post.title}</h3>
                    <div className="column-service-divider"></div>
                    <div className="column-service-content gdlr-skin-content">
                      <TextareaView style={{ textAlign: 'center' }} clamp="3">
                        {item.post.content}
                      </TextareaView>
                      <a
                        href="#!"
                        onClick={store.handleClickMore(item, 'post')}
                        className="excerpt-read-more"
                      >
                        {intl.formatMessage({
                          id: 'label.readMore',
                        })}
                        &nbsp;
                        <i className="fa fa-long-arrow-right icon-long-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}

          <div className="clear"></div>
        </div>
      </div>
      <div className="clear"></div>
    </Wrapper>
  );
});

export default About;
