/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useCallback } from 'react';
import { ConfigProvider } from 'antd';
import { observer, useLocalStore } from 'mobx-react-lite';
import { useQuery, useApolloClient } from 'react-apollo-hooks';
import Jimp from 'jimp';
import WOW from 'wow.js';
import koKR from 'antd/es/locale/ko_KR';
import Cookies from 'js-cookie';

import changeLocale from '../../utils/changeLocale';
import { GET_HOTEL } from './AppQueries';
import { store } from '../../shared/store';
import { GET_USER } from '../../shared/queries';
import Loading from '../Common/Loading';
import MyInfo from '../Common/User/MyInfo';
import DetailModal from '../Common/DetailModal';
import Popup from '../Common/Popup';
import ThemeComp from './ThemeComp';

const AppPresenter = observer((props) => {
  const localStore = useLocalStore(() => ({
    loading: true,
    theme: 0,
    antdLocale: koKR,
    jimpReload: false,
  }));

  let host = window.location.host;
  if (/localhost/.test(host)) {
    host = 'http://www.barojaba.com';
  }
  const hotelQuery = useQuery(GET_HOTEL, {
    variables: {
      homepageUrl: host,
      lang: props.lang.current,
      stayDate: [
        store.startDate.format('YYYY-MM-DD'),
        store.endDate.format('YYYY-MM-DD'),
      ],
      isHomepageSite: true,
    },
    fetchPolicy: 'network-only',
  });
  const client = useApolloClient();

  const getUser = useCallback(async () => {
    const hotel = window.localStorage.getItem('hotel');
    let user;
    try {
      user = await client.query({
        query: GET_USER,
        variables: { hotel },
        fetchPolicy: 'network-only',
      });
    } catch (e) {}

    if (user && user.data && user.data.memberUser) {
      store.user = user.data.memberUser;
      const token = Cookies.get(`${hotel}_jwt`);
      if (token && user.data.memberUser.loginTime) {
        let expires = new Date(parseInt(user.data.memberUser.loginTime));
        expires.setMinutes(expires.getMinutes() + 59);
        Cookies.set(`${hotel}_jwt`, token, { expires });
      }
    } else {
      store.user = null;
    }
  }, []);

  useEffect(() => {
    store.hotelPackages = [];
    store.hotelPromotions = [];
    if (hotelQuery.data.hotel) {
      const hotel = hotelQuery.data.hotel;
      localStore.theme = hotel.homepageTheme;
      if (/barojaba.com/.test(window.location.host)) {
        localStore.theme = 0;
      }
      if (/localhost/.test(window.location.host)) {
        localStore.theme = 3;
      }

      for (let i = 0; i < hotel.products.length; i++) {
        if (hotel.products[i].productType === 'package') {
          store.hotelPackages.push(hotel.products[i]);
        } else {
          store.hotelPromotions.push(hotel.products[i]);
        }
      }

      store.hotel = hotel;
      if (Cookies.get(`${hotel._id}_jwt`)) {
        getUser();
      }
      if (
        !window.localStorage.getItem('hotel') ||
        window.localStorage.getItem('hotel') !== String(hotel._id)
      ) {
        window.localStorage.setItem('hotel', hotel._id);
        localStore.jimpReload = true;
      }
      localStore.loading = false;
    }
  }, [hotelQuery.data]);

  useEffect(() => {
    window.localStorage.setItem('lang', props.lang.current);
    store.curLang = props.lang.current;
    localStore.antdLocale = changeLocale(props.lang.current);
  }, [props.lang.current]);

  useEffect(() => {
    if (props.auth.isLoggedIn) {
      getUser();
    } else {
      store.user = null;
    }
  }, [props.auth.isLoggedIn]);

  useEffect(() => {
    if (
      store.hotel &&
      store.hotel.images &&
      store.hotel.images[0] &&
      (!window.localStorage.getItem('jimp_header') || localStore.jimpReload)
    ) {
      Jimp.read('/hotel-img/' + store.hotel.images[0].name).then(
        async (image) => {
          image = await image.scale(0.3).quality(70).blur(2).greyscale();
          await image.color([{ apply: 'darken', params: [10] }]);

          const headerSrc = await image.getBase64Async(Jimp.MIME_PNG);

          window.localStorage.setItem('jimp_header', headerSrc);
          localStore.jimpReload = false;
        },
      );
    }
  }, [store.hotel, localStore.jimpReload]);

  useEffect(() => {
    new WOW().init();
  }, []);

  // if (hotelQuery.loading || localStore.loading) {
  //   return <Loading />;
  // }

  return (
    <ConfigProvider locale={localStore.antdLocale}>
      <ThemeComp theme={localStore.theme} />
      {(/localhost/.test(window.location.host) ||
        /barojaba.com/i.test(window.location.host)) &&
      /^\/themes\/\d+$/i.test(window.location.pathname) ? (
        <>
          {store.user ? <MyInfo /> : null}
          <DetailModal />
          {/* <Popup /> */}
        </>
      ) : (
        !/barojaba.com/i.test(window.location.host) && (
          <>
            {store.user ? <MyInfo /> : null}
            <DetailModal />
            {/* <Popup /> */}
          </>
        )
      )}
    </ConfigProvider>
  );
});

export default AppPresenter;
