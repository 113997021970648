import React from 'react';
import { Modal, Result, Icon, Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { store } from '../../../shared/store';
import { useIntl } from '../../../utils/intl';
import ResultHeader from '../ResultHeader';

const { Paragraph } = Typography;

const StyledText = styled.div`
  ${props => props.color && `color: ${props.color}`};
  ${props => props.size && `font-size: ${props.size}`};
  ${props => props.primary && `color: ${props.theme.primaryColor}`};
`;

const SignUpResult = observer(props => {
  const intl = useIntl();
  return (
    <Modal
      visible={props.resultVisible}
      onCancel={props.handleResultClose}
      footer={null}
    >
      <div style={{ marginTop: '30px', border: '1px dashed #c8c8c8' }}>
        <ResultHeader title={store.hotel.curIntro.name} />
        <Result
          status="success"
          title={
            <StyledText primary={true}>
              {intl.formatMessage({ id: 'label.sign.up.success' })}
            </StyledText>
          }
          subTitle={
            <StyledText size="20px">
              {intl.formatMessage({ id: 'label.thanks' })}
            </StyledText>
          }
          style={{ padding: '10px', paddingTop: '34px' }}
        >
          <Paragraph>
            <StyledText size="12px">
              <Icon style={{ color: '#3498db' }} type="info-circle" />
              &nbsp;
              {intl.formatMessage(
                { id: 'label.sign.up.result.info1' },
                { hotelName: store.hotel.curIntro.name },
              )}
            </StyledText>
          </Paragraph>
          <Paragraph>
            <StyledText size="12px">
              <Icon style={{ color: '#3498db' }} type="info-circle" />
              &nbsp;{intl.formatMessage({ id: 'label.sign.up.result.info2' })}
            </StyledText>
          </Paragraph>
          <Paragraph>
            <StyledText size="12px">
              <Icon style={{ color: '#3498db' }} type="info-circle" />
              &nbsp;
              {intl.formatMessage(
                { id: 'label.sign.up.result.info3' },
                { hotelName: store.hotel.curIntro.name },
              )}
            </StyledText>
          </Paragraph>
        </Result>
      </div>
    </Modal>
  );
});

export default SignUpResult;
