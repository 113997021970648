import Cookies from 'js-cookie';

export const setUserLogin = (_, { token }, { cache }) => {
  return new Promise(resolve => {
    const hotelId = window.localStorage.getItem('hotel');
    const expires = new Date();
    expires.setMinutes(expires.getMinutes() + 59);
    Cookies.set(`${hotelId}_jwt`, token, { expires });
    cache.writeData({
      data: {
        auth: {
          __typename: 'Auth',
          isLoggedIn: true,
        },
      },
    });
    resolve();
  });
};
export const setUserLogout = (_, __, { cache }) => {
  return new Promise(resolve => {
    const hotelId = window.localStorage.getItem('hotel');
    Cookies.remove(`${hotelId}_jwt`);
    cache.writeData({
      data: {
        auth: {
          __typename: 'Auth',
          isLoggedIn: false,
        },
      },
    });
    window.location.href = '/';
    resolve();
  });
};
