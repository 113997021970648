/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import ScriptLoader from 'react-script-loader-hoc';
import { observer, useLocalStore } from 'mobx-react-lite';
import { isBrowser } from 'react-device-detect';
import { Reset } from 'styled-reset';

import { store } from '../../../shared/store';
import { useScript } from '../../../utils/useScript';
import Loading from '../../Common/Loading';
import ThemeStyle from './ThemeStyle';
import Header from './Header';
import Banner from './Banner';
import About from './About';
import Room from './Room';
import Footer from './Footer';
import Facility from './Facility';
import SpecialOffer from './SpecialOffer';
import HashTag from './HashTag';
import Location from '../../Common/Location';

const scripts = [
  '/themes/theme1/js/jquery.matchHeight-min.js',
  '/themes/theme1/js/jquery-scrolltofixed-min.js',
  '/themes/theme1/js/owl.carousel.min.js',
  '/themes/theme1/js/jquery-ui-1.10.4.custom.min.js',
  '/themes/theme1/js/jquery.ba-outside-events.min.js',
  '/themes/theme1/js/jqueryui.js',
  '/themes/theme1/js/jquery.vide.min.js',
  '/themes/theme1/js/tab.js',
  '/themes/theme1/js/transition.js',
  '/themes/theme1/js/scripts.js',
];

const ContentContainer = observer(() => {
  useScript(scripts);

  if (!store.hotel.homepage || !store.hotel.homepage.menus) return <Loading />;

  return (
    <>
      <div id="main-wrapper">
        <Header />
        <Banner className={isBrowser ? 'wow fadeIn' : ''} />
        <main>
          {store.hotel.homepage.menus['about'] && (
            <About className={isBrowser ? 'wow fadeIn' : ''} />
          )}
          {store.hotel.homepage.menus['specialOffers'] && (
            <SpecialOffer className={isBrowser ? 'wow fadeIn' : ''} />
          )}
          {store.hotel.homepage.menus['rooms'] && (
            <Room className={isBrowser ? 'wow fadeIn' : ''} />
          )}
          {store.hotel.homepage.menus['facilities'] && (
            <Facility className={isBrowser ? 'wow fadeIn' : ''} />
          )}
          {store.hotel.instagrams && store.hotel.instagrams.length ? (
            <HashTag className={isBrowser ? 'wow fadeIn' : ''} />
          ) : null}
        </main>
        <Location />
        <Footer />
      </div>
    </>
  );
});

const Container = observer(({ scriptsLoadedSuccessfully }) => {
  const localStore = useLocalStore(() => ({
    loaded: false,
  }));

  useEffect(() => {
    if (scriptsLoadedSuccessfully) {
      setTimeout(() => {
        localStore.loaded = true;
      }, 100);
    }
  }, [scriptsLoadedSuccessfully]);

  if (!scriptsLoadedSuccessfully) return <Loading />;

  return (
    <>
      <Reset />
      <ThemeStyle>
        <Helmet>
          {store.hotel.logo && (
            <link
              rel="shortcut icon"
              href={store.hotel.logo.url}
              type="image/x-icon"
            />
          )}
          {!/localhost/.test(window.location.host) &&
          store.hotel.images &&
          store.hotel.images[0] ? (
            <>
              <meta property="og:image" content={store.hotel.images[0].url} />
              <meta
                property="og:description"
                content={`${store.hotel.name}에 오신 것을 환영합니다. 홈페이지에서 예약하는 것이 가장 저렴합니다.`}
              />
              <meta
                property="og:title"
                content={`${store.hotel.name} 공식 홈페이지`}
              />
            </>
          ) : (
            <meta
              name="description"
              content={`${store.hotel.name}에 오신 것을 환영합니다. 홈페이지에서 예약하는 것이 가장 저렴합니다.`}
            />
          )}
          <title>{store.hotel.curIntro.name}</title>
        </Helmet>
        {localStore.loaded && <ContentContainer />}
      </ThemeStyle>
    </>
  );
});

export default ScriptLoader('/themes/theme1/js/jquery-2.1.4.min.js')(Container);
