/* eslint-disable react-hooks/exhaustive-deps, jsx-a11y/anchor-is-valid, no-script-url */
import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { store } from '../../../shared/store';

const Header = observer(() => {
  const menus = useCallback(className => {
    const value = [];
    const menus = window.toJS(store.hotel.homepage.menus);
    if (menus['about']) {
      value.push(
        <li className={className} key="menu_about">
          <a href="#about">About</a>
        </li>,
      );
    }
    if (menus['specialOffers']) {
      value.push(
        <li className={className} key="menu_specialOffers">
          <a href="#specialOffers">Special Offers</a>
        </li>,
      );
    }
    if (menus['rooms']) {
      value.push(
        <li className={className} key="menu_rooms">
          <a href="#rooms">Rooms</a>
        </li>,
      );
    }
    if (menus['facilities']) {
      value.push(
        <li className={className} key="menu_facilities">
          <a href="#facilities">Facilities</a>
        </li>,
      );
    }
    return value;
  }, []);

  useEffect(() => {
    (function($) {
      $('.body-wrapper.float-menu').each(function() {
        var sub_area = $('#gdlr-header-substitute');
        var header_wrapper = sub_area.siblings('.gdlr-header-wrapper');
        var header_area = header_wrapper.children('.gdlr-header-inner');
        var header_style_1 = $('body').hasClass('header-style-1');

        var logo = header_area.find('.gdlr-logo');
        var logo_img = header_area.find('.gdlr-logo img');
        var navigation = header_area.find('.gdlr-navigation-wrapper');
        var navigation_gimmick = $('#gdlr-navigation-gimmick');
        var original = {
          logo_top: logo.css('margin-top'),
          logo_bottom: logo.css('margin-bottom'),
          nav_top: navigation.css('margin-top'),
          gimmick_top: navigation_gimmick.css('top'),
        };

        $(window).on('scroll', function() {
          if (
            header_area.hasClass('gdlr-fixed-header') &&
            ($(this).width() < 959 ||
              $(this).scrollTop() <= header_wrapper.height())
          ) {
            if (logo_img.attr('data-trans')) {
              logo_img.attr('src', logo_img.attr('data-trans'));
            }

            var header_clone = header_area.clone(true);
            header_clone.appendTo($('body')).animate(
              { opacity: 0 },
              {
                duration: 300,
                queue: false,
                complete: function() {
                  $(this).remove();
                },
              },
            );

            header_wrapper.height('auto');
            header_area
              .appendTo(header_wrapper)
              .removeClass('gdlr-fixed-header')
              .css({ opacity: 1 });

            if (header_style_1) {
              logo.css({
                'margin-top': original.logo_top,
                'margin-bottom': original.logo_bottom,
              });
              logo_img.css({ width: '100%' });
            } else {
              logo.css({ display: 'block' });
            }
            navigation.css({ 'margin-top': original.nav_top });

            navigation_gimmick.css({ top: original.gimmick_top });
          } else if (
            !header_area.hasClass('gdlr-fixed-header') &&
            $(this).width() > 959 &&
            $(this).scrollTop() > header_wrapper.height()
          ) {
            if (logo_img.attr('data-normal')) {
              logo_img.attr('data-trans', logo_img.attr('src'));
              logo_img.attr('src', logo_img.attr('data-normal'));
            }

            header_wrapper.height(header_wrapper.height());
            header_area
              .appendTo($('body'))
              .addClass('gdlr-fixed-header')
              .css({ opacity: 0 })
              .animate({ opacity: 1 }, { duration: 300, queue: false });

            navigation.css({ 'margin-top': '36px' });
            original.gimmick_top = navigation_gimmick.css('top');
            if (header_style_1) {
              logo.css({ 'margin-top': '20px', 'margin-bottom': '23px' });
              logo_img.css({ width: '80%' });
              navigation_gimmick.css({ top: '66px' });
            } else {
              logo.css({ display: 'none' });
              navigation_gimmick.css({ top: '28px' });
            }
          }
        });
      });
    })(window.jQuery);
  }, []);

  return (
    <header className="gdlr-header-wrapper">
      <div className="gdlr-header-inner">
        <div className="gdlr-header-container container">
          <div className="gdlr-logo">
            <div className="gdlr-logo-inner">
              <a href="/">
                <img src={store.hotel.logo.url} alt="" />{' '}
              </a>
              <div
                className="gdlr-responsive-navigation dl-menuwrapper"
                id="gdlr-responsive-navigation"
              >
                <button className="dl-trigger">Open Menu</button>
                <ul
                  id="menu-main-menu"
                  className="dl-menu gdlr-main-mobile-menu"
                >
                  <li className="menu-item current-menu-item">
                    <a href="/">Home</a>
                  </li>
                  {menus('menu-item')}
                  {store.hotel.instagrams && store.hotel.instagrams.length ? (
                    <li className="menu-item">
                      <a href="#hashtag">HashTags</a>
                    </li>
                  ) : null}
                  <li className="menu-item">
                    <a href="#location">Location</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="gdlr-navigation-wrapper">
            <nav
              className="gdlr-navigation"
              id="gdlr-main-navigation"
              role="navigation"
            >
              <ul id="menu-main-menu-1" className="sf-menu gdlr-main-menu">
                <li className="menu-item current-menu-item menu-item gdlr-normal-menu">
                  <a href="/">Home</a>
                </li>
                {menus('menu-itemmenu-item gdlr-normal-menu')}
                {store.hotel.instagrams && store.hotel.instagrams.length ? (
                  <li className="menu-itemmenu-item gdlr-normal-menu">
                    <a href="#hashtag">HashTags</a>
                  </li>
                ) : null}
                <li className="menu-itemmenu-item gdlr-normal-menu">
                  <a href="#location">Location</a>
                </li>
              </ul>
            </nav>
            <div
              className="gdlr-navigation-gimmick"
              id="gdlr-navigation-gimmick"
            ></div>
            <div className="clear"></div>
          </div>
          <div className="clear"></div>
        </div>
      </div>
    </header>
  );
});

export default Header;
