import styled from 'styled-components';

const BestPriceWrapper = styled.div`
  width: 100%;
  font-weight: bold;

  .animate span {
    display: inline-block;
    font-weight: bold;
  }

  .animate span:nth-of-type(2) {
    animation-delay: 0.05s;
  }
  .animate span:nth-of-type(3) {
    animation-delay: 0.1s;
  }
  .animate span:nth-of-type(4) {
    animation-delay: 0.15s;
  }
  .animate span:nth-of-type(5) {
    animation-delay: 0.2s;
  }
  .animate span:nth-of-type(6) {
    animation-delay: 0.25s;
  }
  .animate span:nth-of-type(7) {
    animation-delay: 0.3s;
  }
  .animate span:nth-of-type(8) {
    animation-delay: 0.35s;
  }
  .animate span:nth-of-type(9) {
    animation-delay: 0.4s;
  }
  .animate span:nth-of-type(10) {
    animation-delay: 0.45s;
  }
  .animate span:nth-of-type(11) {
    animation-delay: 0.5s;
  }
  .animate span:nth-of-type(12) {
    animation-delay: 0.55s;
  }
  .animate span:nth-of-type(13) {
    animation-delay: 0.6s;
  }
  .animate span:nth-of-type(14) {
    animation-delay: 0.65s;
  }
  .animate span:nth-of-type(15) {
    animation-delay: 0.7s;
  }
  .animate span:nth-of-type(16) {
    animation-delay: 0.75s;
  }
  .animate span:nth-of-type(17) {
    animation-delay: 0.8s;
  }
  .animate span:nth-of-type(18) {
    animation-delay: 0.85s;
  }
  .animate span:nth-of-type(19) {
    animation-delay: 0.9s;
  }
  .animate span:nth-of-type(20) {
    animation-delay: 0.95s;
  }

  .one {
    margin-right: 10px;
  }

  .one span {
    color: #24a8e6;
    opacity: 0;
    transform: translate(-150px, -50px) rotate(-180deg) scale(3);
    animation: revolveScale 3s infinite;
    @keyframes revolveScale {
      10% {
        transform: translate(20px, 20px) rotate(30deg) scale(0.3);
      }
      20% {
        transform: translate(0) rotate(0) scale(1);
        opacity: 1;
      }
      100% {
        transform: translate(0) rotate(0) scale(1);
        opacity: 1;
      }
    }
  }
`;

export default BestPriceWrapper;
