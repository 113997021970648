import React from 'react';
import { observer } from 'mobx-react-lite';

const Footer = observer(() => {
  return (
    <div id="footer">
      <a className="scroll" href="#home">
        <div className="back-top">&#xf102;</div>
      </a>
      <div className="container">
        <div className="sixteen columns">
          <p>
            <img src="/themes/theme0/images/logo.png" width="96px" alt="" />
          </p>
          <p>사업자등록번호: 134-87-39191</p>
          {/* <div className="social-bottom">
            <ul className="list-social1">
              <li className="icon-soc1">
                <a href="#">&#xf099;</a>
              </li>
              <li className="icon-soc1">
                <a href="#">&#xf09a;</a>
              </li>
              <li className="icon-soc1">
                <a href="#">&#xf09b;</a>
              </li>
              <li className="icon-soc1">
                <a href="#">&#xf0d5;</a>
              </li>
            </ul>
          </div> */}
          <p>
            <small>©2019 BAROJABA Inc. all rights reserved.</small>
          </p>
        </div>
      </div>
    </div>
  );
});

export default Footer;
