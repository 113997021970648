const theme = {
  primaryColor: '#bf953f',
  secondaryColor: '#13c2c2',
  blueColor: '#3498db',
  greyIconColor: 'rgba(0,0,0,.25)',
  shadow: `
  -webkit-box-shadow: 0px 4px 10px 0px rgba(170, 170, 170, 1);
  -moz-box-shadow: 0px 4px 10px 0px rgba(170, 170, 170, 1);
  box-shadow: 0px 4px 10px 0px rgba(170, 170, 170, 1);
  `,
  breakpoints: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
  },
};

export default theme;
