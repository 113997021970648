import gql from 'graphql-tag';
import {
  hotelFragment,
  roomFragment,
  productFragment,
  benefitFragment,
  homepageFragment,
} from '../../shared/fragments';

export const GET_LOGIN_DATA = gql`
  {
    auth {
      isLoggedIn @client
    }
    theme {
      themeData @client
    }
    lang {
      current @client
    }
  }
`;

export const GET_HOTEL = gql`
  ${hotelFragment}
  ${roomFragment}
  ${productFragment}
  ${benefitFragment}
  ${homepageFragment}
  query Hotel(
    $homepageUrl: String
    $lang: String
    $stayDate: [String]
    $isHomepageSite: Boolean
  ) {
    hotel(homepageUrl: $homepageUrl) {
      ...HotelFragment
      curIntro(lang: $lang) {
        name
        address1
        address2
        cancellationPolicy
        nearbyPlace
        description
      }
      rooms {
        ...RoomFragment
        hotelProducts(stayDate: $stayDate) {
          ...ProductFragment
          hotelBenefits {
            ...BenefitFragment
          }
        }
        curIntro(lang: $lang) {
          name
          description
        }
        curFacility(lang: $lang)
      }
      products {
        ...ProductFragment
        curIntro(lang: $lang) {
          name
          description
        }
      }
      homepage(isHomepageSite: $isHomepageSite) {
        ...HomepageFragment
        curAbout(lang: $lang)
        curFacility(lang: $lang)
      }
      instagrams {
        url
        image
      }
    }
  }
`;
