/* eslint-disable react-hooks/exhaustive-deps, jsx-a11y/anchor-is-valid, no-script-url */
import React, { useEffect, useCallback } from 'react';
import { DatePicker, Input } from 'antd';
import { observer, useLocalStore } from 'mobx-react-lite';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { useIntl } from 'react-intl';
import moment from 'moment';
import Cookies from 'js-cookie';

import { store } from '../../../shared/store';
import { TodayPriceWidget } from '../../Common/TodayPrice';

const BannerContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  margin-top: 0;

  .select-clone {
    max-height: 170px;
    overflow-y: auto;
  }
  .booking-datepicker {
    width: 100%;
    margin-bottom: 0;
  }

  ${breakpoint('xs', 'lg')`
    margin-top: 0;
    align-items: center;

    .mobile-full-width {
      width: 100% !important;      
    }
    .booking-datepicker {
      width: 100%;
      margin-bottom: 30px;
    }
  `};
  ${breakpoint('md', 'lg')`
    margin-top: 100px;
  `};
`;

const disabledCheckIn = current => {
  // Can not select days before today and today
  return current && current < moment().subtract(1, 'days');
};

const Banner = observer(props => {
  const intl = useIntl();
  const localStore = useLocalStore(() => ({
    checkIn: moment(),
    checkOut: moment().add(1, 'days'),
    adult: intl.formatMessage({ id: 'label.booking.adults' }, { value: 2 }),
    room: intl.formatMessage({ id: 'label.booking.rooms' }, { value: 1 }),
    options: {
      adults: [],
      rooms: [],
    },
  }));

  const disabledCheckOut = useCallback(
    current => {
      // Can not select days before today and today
      return current && current <= localStore.checkIn;
    },
    [localStore.checkIn],
  );

  const handleChangeDate = useCallback(
    type => date => {
      localStore[type] = date;
      if (type === 'checkIn') {
        localStore.checkOut = null;
      }
    },
    [],
  );
  const handleChangeSelect = useCallback(
    (type, value) => e => {
      localStore[type] = value;
    },
    [],
  );
  const handleSubmit = useCallback(e => {
    if (!localStore.checkIn) {
      return window.alert({
        title: intl.formatMessage({ id: 'error.checkIn.required' }),
        centered: true,
      });
    }
    if (!localStore.checkOut) {
      return window.alert({
        title: intl.formatMessage({ id: 'error.checkOut.required' }),
        centered: true,
      });
    }
    if (!localStore.adult) {
      return window.alert({
        title: intl.formatMessage({ id: 'error.adult.required' }),
        centered: true,
      });
    }
    if (!localStore.room) {
      return window.alert({
        title: intl.formatMessage({ id: 'error.room.required' }),
        centered: true,
      });
    }

    const checkIn = localStore.checkIn.format('YYYY-MM-DD');
    const checkOut = localStore.checkOut.format('YYYY-MM-DD');
    const adult = localStore.adult.replace(/[^\d]/g, '');
    const room = localStore.room.replace(/[^\d]/g, '');
    store.goToLink(
      `https://booking.barojaba.com/?h=${
        store.hotel._id
      }&chkIn=${checkIn}&chkOut=${checkOut}&adult=${adult}&room=${room}&session=${
        Cookies.get(`${store.hotel._id}_jwt`)
          ? Cookies.get(`${store.hotel._id}_jwt`)
          : ''
      }`,
    );
  }, []);

  useEffect(() => {
    localStore.options.adults = [];
    localStore.options.rooms = [];

    for (let i = 0; i < 10; i++) {
      localStore.options.adults.push(i + 1);
      localStore.options.rooms.push(i + 1);
    }
    window.handleChangeSelect = handleChangeSelect;
  }, []);

  return (
    <section id="banner" {...props}>
      <div className="banner-bg">
        {window.toJS(store.hotel.images).map(item => (
          <div className="banner-bg-item" key={item.url}>
            <img src={item.url} alt="" />
          </div>
        ))}
      </div>

      <div className="css-table">
        <div className="css-table-cell">
          {/* <!-- Start Banner-Search --> */}

          <div className="banner-search">
            <BannerContainer className="container">
              <TodayPriceWidget otaMaxHeight="100px" />
              <div id="hero-tabs" className="banner-search-inner">
                <ul className="custom-list tab-title-list clearfix">
                  {window.toJS(store.hotel.images).map((_, idx) => (
                    <li
                      className={`tab-title${idx === 0 ? ' active' : ''}`}
                      key={`tab_title_${idx}`}
                    >
                      <a href="#" />
                    </li>
                  ))}
                </ul>
                <ul className="custom-list tab-content-list">
                  {window.toJS(store.hotel.images).map((_, idx) => (
                    <li
                      className={`tab-content${idx === 0 ? ' active' : ''}`}
                      key={`tab_content_${idx}`}
                    >
                      <div className="default-form">
                        <span className="arrival mobile-full-width">
                          <DatePicker
                            className="booking-datepicker"
                            value={localStore.checkIn}
                            onChange={handleChangeDate('checkIn')}
                            placeholder={intl.formatMessage({
                              id: 'label.checkIn',
                            })}
                            suffixIcon={<i className="fa fa-calendar"></i>}
                            allowClear={false}
                            disabledDate={disabledCheckIn}
                          />
                        </span>
                        <span className="departure mobile-full-width">
                          <DatePicker
                            className="booking-datepicker"
                            value={localStore.checkOut}
                            onChange={handleChangeDate('checkOut')}
                            placeholder={intl.formatMessage({
                              id: 'label.checkOut',
                            })}
                            suffixIcon={<i className="fa fa-calendar"></i>}
                            allowClear={false}
                            disabledDate={disabledCheckOut}
                          />
                        </span>
                        <span className="adults select-box mobile-full-width">
                          <Input
                            className="value-holder"
                            value={localStore.adult}
                            placeholder={intl.formatMessage({
                              id: 'label.personnel',
                            })}
                            disabled={true}
                          />
                          <i className="fa fa-sort arrow-down"></i>
                          <select
                            name="adults"
                            data-placeholder={intl.formatMessage({
                              id: 'label.personnel',
                            })}
                            data-select-type="adult"
                          >
                            <option>
                              {intl.formatMessage({ id: 'label.personnel' })}
                            </option>
                            {window
                              .toJS(localStore.options.adults)
                              .map((value, idx) => (
                                <option
                                  value={value}
                                  key={`booking_adults_${idx}`}
                                >
                                  {intl.formatMessage(
                                    { id: 'label.booking.adults' },
                                    { value },
                                  )}
                                </option>
                              ))}
                          </select>
                        </span>
                        <span className="children select-box mobile-full-width">
                          <Input
                            className="value-holder"
                            value={localStore.room}
                            placeholder={intl.formatMessage({
                              id: 'label.room',
                            })}
                            disabled={true}
                          />
                          <i className="fa fa-sort arrow-down"></i>
                          <select
                            name="rooms"
                            data-placeholder={intl.formatMessage({
                              id: 'label.room',
                            })}
                            data-select-type="room"
                          >
                            <option>
                              {intl.formatMessage({ id: 'label.room' })}
                            </option>
                            {window
                              .toJS(localStore.options.rooms)
                              .map((value, idx) => (
                                <option
                                  value={value}
                                  key={`booking_rooms_${idx}`}
                                >
                                  {intl.formatMessage(
                                    { id: 'label.booking.rooms' },
                                    { value },
                                  )}
                                </option>
                              ))}
                          </select>
                        </span>
                        <span className="submit-btn mobile-full-width">
                          <button
                            className="btn btn-transparent mobile-full-width"
                            onClick={handleSubmit}
                          >
                            {intl.formatMessage({ id: 'label.booking' })}
                          </button>
                        </span>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </BannerContainer>
          </div>
          {/* <!-- End Banner-Search --> */}
        </div>
      </div>
    </section>
  );
});

export default Banner;
