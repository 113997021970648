import React from 'react';
import { observer } from 'mobx-react-lite';

const Home = observer(() => {
  return (
    <div id="home">
      <div className="home-text">
        <div className="line"></div>
        <div className="home-big-text">BAROJABA Inc.</div>
        <div className="home-small-text">
          호텔 다이렉트 부킹 활성화를 위한 최고의 솔루션
        </div>
        <div className="line1"></div>
      </div>

      <video
        id="video_background"
        preload="auto"
        autoPlay={true}
        loop="loop"
        muted="muted"
        volume="0"
        poster="/themes/theme0/images/1.jpg"
        onTimeUpdate={() => {
          const vid = document.getElementById('video_background');
          if (vid && vid.currentTime < 1) {
            window.$('.home-text').removeClass('fadeOut');
            window.$('.home-text').addClass('fadeIn');
          } else if (vid && vid.currentTime > 7) {
            window.$('.home-text').removeClass('fadeIn');
            window.$('.home-text').addClass('fadeOut');
          }
        }}
      >
        <source src="/themes/theme0/videos/video.webm" type="video/webm" />
        <source src="/themes/theme0/videos/video.mp4" type="video/mp4" />
        <source src="/themes/theme0/videos/video.ogg" type="video/ogg" />
      </video>
    </div>
  );
});

export default Home;
