import enUS from 'antd/es/locale/en_US';
import koKR from 'antd/es/locale/ko_KR';
import jaJP from 'antd/es/locale/ja_JP';
import zhCN from 'antd/es/locale/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
import 'moment/locale/ja';
import 'moment/locale/ko';

const changeLocale = lang => {
  let value;
  switch (lang) {
    case 'kr':
      value = koKR;
      moment.locale('ko');
      break;
    case 'us':
      value = enUS;
      moment.locale('en-gb');
      break;
    case 'jp':
      value = jaJP;
      moment.locale('ja');
      break;
    case 'cn':
      value = zhCN;
      moment.locale('zh-cn');
      break;
  }
  return value;
};

export default changeLocale;
