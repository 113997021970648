import React from 'react';
import { observer } from 'mobx-react-lite';

const Header = observer(() => {
  return (
    <nav id="menu-wrap" className="menu-back cbp-af-header">
      <div className="container">
        <div className="sixteen columns">
          <div className="logo" />
          <ul className="slimmenu">
            <li>
              <a className="scroll" href="#home">
                home
              </a>
            </li>
            <li>
              <a className="scroll" href="#about">
                about
              </a>
            </li>
            <li>
              <a className="scroll" href="#solution">
                solution
              </a>
            </li>
            {/* <li>
              <a className="scroll" href="#services">
                services
              </a>
            </li> */}
            <li>
              <a className="scroll" href="#contact">
                contact
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
});

export default Header;
