import styled, { css } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

const Style = css`
  @import url('/themes/theme3/plugins/gdlr-hostel/gdlr-hotel.css');
  @import url('/themes/theme3/css/style.css');
  @import url('/themes/theme3/plugins/superfish/css/superfish.css');
  @import url('/themes/theme3/plugins/dl-menu/component.css');
  @import url('/themes/theme3/plugins/font-awesome-new/css/font-awesome.min.css');
  @import url('/themes/theme3/plugins/fancybox/jquery.fancybox.css');
  @import url('/themes/theme3/plugins/flexslider/flexslider.css');
  @import url('/themes/theme3/css/style-responsive.css');
  @import url('/themes/theme3/css/style-custom.css');
  @import url('/themes/theme3/plugins/masterslider/public/assets/css/masterslider.main.css');
  @import url('/themes/theme3/css/custom.css?ver=2.6');
  @import url('https://fonts.googleapis.com/css?family=Merriweather:300,300i,400,700|Open+Sans:300,300i,400,400i,600,700,800|Droid+Serif:300,300i,400,600,700');
  @import url('https://fonts.googleapis.com/css?family=Open+Sans:300,700|Merriweather:regular');
`;

const ThemeStyle = styled.div`
  ${Style}

  .facilities-list {
    padding: 0;
    margin: 0;
    width: 100%;
    list-style: none;
    display: flex;

    li {
      width: 33%;
      font-size: 12px;
      margin-bottom: 5px;
    }
  }

  .gdlr-item-title {
    color: #fff;
  }
  .gdlr-modern-room {
    .booking {
      color: #fff;
      font-size: 14px;
    }
  }

  ${breakpoint('xs', 'lg')``};
`;

export default ThemeStyle;
