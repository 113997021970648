import gql from 'graphql-tag';
import { memberUserFragment } from './fragments';

export const SET_LANG = gql`
  mutation SetLang($current: string) {
    setLang(current: $current) @client
  }
`;

export const SET_STEPS = gql`
  mutation SetSteps($current: number) {
    setSteps(current: $current) @client
  }
`;

export const SET_USER_LOGIN = gql`
  mutation SetUserLogin($token: String) {
    setUserLogin(token: $token) @client
  }
`;

export const SET_USER_LOGOUT = gql`
  mutation SetUserLogout {
    setUserLogout @client
  }
`;

export const LOGIN = gql`
  mutation Login(
    $email: String!
    $password: String!
    $isMember: Boolean
    $hotel: ObjectId
  ) {
    login(
      email: $email
      password: $password
      isMember: $isMember
      hotel: $hotel
    ) {
      ok
      error
      token
    }
  }
`;

export const GET_USER = gql`
  ${memberUserFragment}
  query MemberUser($hotel: ObjectId) {
    memberUser(hotel: $hotel) {
      ...MemberUserFragment
    }
  }
`;

export const GET_HOTEL_META = gql`
  query HotelMeta($key: String) {
    hotelMeta(key: $key) {
      key
      value
    }
  }
`;
