/* eslint-disable react-hooks/exhaustive-deps, jsx-a11y/anchor-is-valid */
import React, { useEffect, useCallback } from 'react';
import { DatePicker } from 'antd';
import { observer, useLocalStore } from 'mobx-react-lite';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import moment from 'moment';
import Cookies from 'js-cookie';

import { store } from '../../../shared/store';

const Wrapper = styled.section`
  .gdlr-color-wrapper {
    background-color: #322b23;
    padding-top: 48px;
    padding-bottom: 27px;
  }
  .gdlr-hotel-availability-wrapper {
    margin-bottom: 20px;
  }

  input {
    color: #eaeaea;
    height: 42px;
    background-color: #5f5243;
    border: none;
    border-radius: 0px;
  }
`;

const disabledCheckIn = current => {
  // Can not select days before today and today
  return current && current < moment().subtract(1, 'days');
};

const Booking = observer(() => {
  const intl = useIntl();
  const localStore = useLocalStore(() => ({
    checkIn: moment(),
    checkOut: moment().add(1, 'days'),
    adult: '2',
    room: '1',
    options: {
      adults: [],
      rooms: [],
    },
  }));

  const disabledCheckOut = useCallback(
    current => {
      // Can not select days before today and today
      return (
        current &&
        current.format('YYYY-MM-DD') <= localStore.checkIn.format('YYYY-MM-DD')
      );
    },
    [localStore.checkIn],
  );

  const handleChangeDate = useCallback(
    type => date => {
      localStore[type] = date;
      if (type === 'checkIn') {
        localStore.checkOut = null;
      }
    },
    [],
  );
  const handleChangeSelect = useCallback(
    type => e => {
      localStore[type] = e.target.value;
    },
    [],
  );

  const handleSubmit = useCallback(e => {
    e.preventDefault();

    if (!localStore.checkIn) {
      window.alert({
        title: intl.formatMessage({ id: 'error.checkIn.required' }),
        centered: true,
      });
      return false;
    }
    if (!localStore.checkOut) {
      window.alert({
        title: intl.formatMessage({ id: 'error.checkOut.required' }),
        centered: true,
      });
      return false;
    }
    if (!localStore.adult) {
      window.alert({
        title: intl.formatMessage({ id: 'error.adult.required' }),
        centered: true,
      });
      return false;
    }
    if (!localStore.room) {
      window.alert({
        title: intl.formatMessage({ id: 'error.room.required' }),
        centered: true,
      });
      return false;
    }

    const checkIn = localStore.checkIn.format('YYYY-MM-DD');
    const checkOut = localStore.checkOut.format('YYYY-MM-DD');
    const adult = localStore.adult.replace(/[^\d]/g, '');
    const room = localStore.room.replace(/[^\d]/g, '');
    store.goToLink(
      `https://booking.barojaba.com/?h=${
        store.hotel._id
      }&chkIn=${checkIn}&chkOut=${checkOut}&adult=${adult}&room=${room}&session=${
        Cookies.get(`${store.hotel._id}_jwt`)
          ? Cookies.get(`${store.hotel._id}_jwt`)
          : ''
      }`,
    );
    return false;
  }, []);

  useEffect(() => {
    localStore.options.adults = [];
    localStore.options.rooms = [];

    for (let i = 0; i < 10; i++) {
      localStore.options.adults.push(i + 1);
      localStore.options.rooms.push(i + 1);
    }
  }, []);

  return (
    <Wrapper id="content-section-2">
      <div className="gdlr-color-wrapper gdlr-show-all gdlr-skin-dark-brown">
        <div className="container">
          <div className="gdlr-hotel-availability-wrapper">
            <form
              className="gdlr-hotel-availability gdlr-item"
              id="gdlr-hotel-availability"
              onSubmit={handleSubmit}
            >
              <div className="gdlr-reservation-field gdlr-resv-datepicker">
                <span className="gdlr-reservation-field-title">Check In</span>
                <div className="gdlr-datepicker-wrapper">
                  <DatePicker
                    className="form-control"
                    value={localStore.checkIn}
                    onChange={handleChangeDate('checkIn')}
                    placeholder={intl.formatMessage({
                      id: 'label.checkIn',
                    })}
                    suffixIcon={
                      <span className="glyphicon glyphicon-calendar"></span>
                    }
                    allowClear={false}
                    disabledDate={disabledCheckIn}
                  />
                  {/* <input
                    type="text"
                    id="gdlr-check-in"
                    className="gdlr-datepicker"
                    autoComplete="off"
                    data-dfm="d M yy"
                    data-block="[]"
                  />
                  <input
                    type="hidden"
                    className="gdlr-datepicker-alt"
                    name="gdlr-check-in"
                    autoComplete="off"
                  /> */}
                </div>
              </div>
              <div className="gdlr-reservation-field gdlr-resv-datepicker">
                <span className="gdlr-reservation-field-title">Check Out</span>
                <div className="gdlr-datepicker-wrapper">
                  <DatePicker
                    className="form-control"
                    value={localStore.checkOut}
                    onChange={handleChangeDate('checkOut')}
                    placeholder={intl.formatMessage({
                      id: 'label.checkOut',
                    })}
                    suffixIcon={
                      <span className="glyphicon glyphicon-calendar"></span>
                    }
                    allowClear={false}
                    disabledDate={disabledCheckOut}
                  />
                </div>
              </div>
              <div className="gdlr-reservation-field gdlr-resv-combobox ">
                <span className="gdlr-reservation-field-title">Adults</span>
                <div className="gdlr-combobox-wrapper">
                  <select
                    onChange={handleChangeSelect('adult')}
                    value={localStore.adult}
                  >
                    {window
                      .toJS(localStore.options.adults)
                      .map((value, idx) => (
                        <option value={value} key={`booking_adults_${idx}`}>
                          {intl.formatMessage(
                            { id: 'label.booking.adults' },
                            { value },
                          )}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div className="gdlr-reservation-field gdlr-resv-combobox ">
                <span className="gdlr-reservation-field-title">Rooms</span>
                <div className="gdlr-combobox-wrapper">
                  <select
                    onChange={handleChangeSelect('room')}
                    value={localStore.room}
                  >
                    {window.toJS(localStore.options.rooms).map((value, idx) => (
                      <option value={value} key={`booking_rooms_${idx}`}>
                        {intl.formatMessage(
                          { id: 'label.booking.rooms' },
                          { value },
                        )}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="gdlr-hotel-availability-submit">
                <input type="hidden" name="hotel_data" value="1" />
                <input type="hidden" name="gdlr-room-number" value="1" />
                <input
                  type="submit"
                  className="gdlr-reservation-bar-button gdlr-button with-border"
                  value={intl.formatMessage({ id: 'label.booking' })}
                />
              </div>
              <div className="clear"></div>
            </form>
          </div>
          <div className="clear"></div>
          <div className="clear"></div>
        </div>
      </div>
      <div className="clear"></div>
    </Wrapper>
  );
});

export default Booking;
