import React from 'react';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';

import { store } from '../../../shared/store';
import TextareaView from '../../Common/TextareaView';

const Facility = observer(props => {
  const intl = useIntl();

  return (
    <div id="facilities" {...props}>
      <div className="service-section container-fluid">
        <div className="container">
          <div className="section-header">
            <h3>Our Facilities</h3>
          </div>
          {window.toJS(store.hotel.homepage.curFacility).map((item, idx) => (
            <div
              className="col-md-4 col-sm-6 col-xs-6 service-box"
              key={`facility_list_${idx}`}
            >
              <img src={item.images[0].url} alt="" />
              <div className="service-content">
                <h5>{item.post.title}</h5>
                <TextareaView single>{item.post.content}</TextareaView>
                <a
                  href="#!"
                  className="read-more"
                  title="Read More"
                  onClick={store.handleClickMore(item, 'post')}
                >
                  {intl.formatMessage({
                    id: 'label.readMore',
                  })}
                </a>
                {item.url && (
                  <a
                    href="#!"
                    className="link"
                    onClick={() => store.goToLink(item.url)}
                  >
                    <i className="fa fa-caret-right"></i>
                  </a>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
});

export default Facility;
