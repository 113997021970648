import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { useIntl } from 'react-intl';

import { store } from '../../../shared/store';
import StyledDivider from '../../StyledComp/StyledDivider';
import TextareaView from '../../Common/TextareaView';

const Wrapper = styled.div`
  button {
    width: 100%;
  }
  textarea {
    text-align: left;
  }

  .sidebar {
    display: block;
  }

  ${breakpoint('xs', 'lg')`  
    .sidebar {
      display: none;
    }
    textarea {
      text-align: center;
    }
  `}
`;

const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    text-align: left;
  }

  ${breakpoint('xs', 'lg')`
    display: block;
    div {
      text-align: center;
    }
  `}
`;

const SpecialOffer = observer(props => {
  const intl = useIntl();

  return (
    <Wrapper id="specialoffers" {...props}>
      <div className="custom-listing">
        <section id="listing">
          <div className="container">
            <div className="row">
              <div className="sidebar col-md-3">
                <div className="special-offers sidebar">
                  <h5 className="title-section">PACKAGES</h5>
                  <div className="offers-content">
                    <ul className="custom-list">
                      {window.toJS(store.hotelPackages).map((p, pIdx) => (
                        <div key={`sidebar_package_${pIdx}`}>
                          <li>
                            <div className="thumbnail">
                              {p.images[0] && (
                                <img
                                  src={p.images[0].url}
                                  alt=""
                                  className="img-responsive"
                                />
                              )}
                            </div>
                            <h5 className="title">
                              <a href={`#package_${pIdx + 1}`}>
                                {p.curIntro.name}
                              </a>
                            </h5>
                          </li>
                          <StyledDivider margin="15px 0" />
                        </div>
                      ))}
                    </ul>
                  </div>

                  <h5
                    className="title-section"
                    style={{ borderTop: '1px solid #ededed' }}
                  >
                    PROMOTIONS
                  </h5>
                  <div className="offers-content">
                    <ul className="custom-list">
                      {window.toJS(store.hotelPromotions).map((p, pIdx) => (
                        <div key={`sidebar_promotion_${pIdx}`}>
                          <li>
                            <div className="thumbnail">
                              {p.images[0] && (
                                <img
                                  src={p.images[0].url}
                                  alt=""
                                  className="img-responsive"
                                />
                              )}
                            </div>
                            <h5 className="title">
                              <a href={`#promotion_${pIdx + 1}`}>
                                {p.curIntro.name}
                              </a>
                            </h5>
                          </li>
                          <StyledDivider margin="15px 0" />
                        </div>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="listing-content col-md-9">
                <div className="listing-pagination">
                  <h5 className="title pull-left">Available Special Offers</h5>
                </div>

                {window.toJS(store.hotelPackages).map((p, pIdx) => {
                  return (
                    <div
                      className="listing-room-list"
                      id={`package_${pIdx + 1}`}
                      key={`package_list_${pIdx}`}
                    >
                      <div className="thumbnail">
                        <div className="thumbnail-slider">
                          {p.images.map((image, imgIdx) => (
                            <img
                              src={image.url}
                              alt=""
                              className="img-responsive"
                              key={`${pIdx}_image_${imgIdx}`}
                            />
                          ))}
                        </div>
                      </div>
                      <div className="listing-room-content">
                        <div className="row">
                          <div className="col-md-12">
                            <header>
                              <Row className="row">
                                <div className="col-md-9 col-sm-9">
                                  <div
                                    className="pull-left"
                                    style={{ width: '100%' }}
                                  >
                                    <h5
                                      className="title"
                                      style={{ width: '100%' }}
                                    >
                                      <a href="#!">{p.curIntro.name}</a>
                                    </h5>
                                    <p style={{ width: '100%' }}>
                                      {p.applyDate[0]} ~ {p.applyDate[1]}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-md-3 col-sm-3">
                                  <button
                                    className="btn btn-transparent-gray"
                                    onClick={store.handleClickMore(
                                      p,
                                      'curIntro',
                                    )}
                                  >
                                    {intl.formatMessage({
                                      id: 'label.readMore',
                                    })}
                                  </button>
                                </div>
                              </Row>
                            </header>
                            <div className="listing-facitilities">
                              <div className="row">
                                <div className="col-md-9 col-sm-9">
                                  <TextareaView
                                    single
                                    style={{ textAlign: 'left' }}
                                  >
                                    {p.curIntro.description}
                                  </TextareaView>
                                </div>

                                <div className="col-md-3 col-sm-3">
                                  <button
                                    className="btn btn-gray-dark booking-btn"
                                    onClick={() =>
                                      store.goToLink(
                                        `https://booking.barojaba.com/?h=${store.hotel._id}`,
                                      )
                                    }
                                  >
                                    {intl.formatMessage({
                                      id: 'label.booking',
                                    })}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}

                {window.toJS(store.hotelPromotions).map((p, pIdx) => {
                  return (
                    <div
                      className="listing-room-list"
                      id={`promotion_${pIdx + 1}`}
                      key={`promotion_list_${pIdx}`}
                    >
                      <div className="thumbnail">
                        <div className="thumbnail-slider">
                          {p.images.map((image, imgIdx) => (
                            <img
                              src={image.url}
                              alt=""
                              className="img-responsive"
                              key={`${pIdx}_image_${imgIdx}`}
                            />
                          ))}
                        </div>
                      </div>
                      <div className="listing-room-content">
                        <div className="row">
                          <div className="col-md-12">
                            <header>
                              <Row className="row">
                                <div className="col-md-9 col-sm-9">
                                  <div
                                    className="pull-left"
                                    style={{ width: '100%' }}
                                  >
                                    <h5
                                      className="title"
                                      style={{ width: '100%' }}
                                    >
                                      <a href="#!">{p.curIntro.name}</a>
                                    </h5>
                                    <p style={{ width: '100%' }}>
                                      {p.applyDate[0]} ~ {p.applyDate[1]}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-md-3 col-sm-3">
                                  <button
                                    className="btn btn-transparent-gray"
                                    onClick={store.handleClickMore(
                                      p,
                                      'curIntro',
                                    )}
                                  >
                                    {intl.formatMessage({
                                      id: 'label.readMore',
                                    })}
                                  </button>
                                </div>
                              </Row>
                            </header>
                            <div className="listing-facitilities">
                              <div className="row">
                                <div className="col-md-9 col-sm-9">
                                  <TextareaView
                                    single
                                    style={{ textAlign: 'left' }}
                                  >
                                    {p.curIntro.description}
                                  </TextareaView>
                                </div>

                                <div className="col-md-3 col-sm-3">
                                  <button className="btn btn-gray-dark booking-btn">
                                    {intl.formatMessage({
                                      id: 'label.booking',
                                    })}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </section>
      </div>
    </Wrapper>
  );
});

export default SpecialOffer;
