import gql from 'graphql-tag';
import {
  crawlerDataFragment,
  managementFragment,
} from '../../../shared/fragments';

export const GET_OTA_DATAS = gql`
  ${crawlerDataFragment}
  query CrawlerDatas(
    $hotel: ObjectId
    $checkInDate: String
    $isOTA: Boolean
    $otaOptional: [String]
  ) {
    crawlerDatas(
      hotel: $hotel
      checkInDate: $checkInDate
      isOTA: $isOTA
      otaOptional: $otaOptional
    ) {
      ...CrawlerDataFragment
    }
  }
`;

export const GET_RANGE_MANAGEMENT = gql`
  ${managementFragment}
  query RangeManagements(
    $hotel: ObjectId
    $startMonth: String
    $endMonth: String
  ) {
    rangeManagements(
      hotel: $hotel
      startMonth: $startMonth
      endMonth: $endMonth
    ) {
      ...ManagementFragment
    }
  }
`;
