export const setSteps = (_, { current }, { cache }) => {
  cache.writeData({
    data: {
      steps: {
        __typename: 'Steps',
        current,
      },
    },
  });
  return null;
};
