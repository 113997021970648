import React from 'react';
import { Affix, Button, Icon } from 'antd';
import { observer } from 'mobx-react-lite';
import { store } from '../../../shared/store';
import { useIntl } from 'react-intl';

const Footer = observer(() => {
  const intl = useIntl();

  return (
    <footer id="footer">
      {/* <!-- Start Footer-Top --> */}
      <div className="footer-top">
        <div className="container">
          <div className="row">
            {store.hotel.logo && (
              <div className="col-md-12 logo-footer">
                <img
                  src={store.hotel.logo.url}
                  alt=""
                  className="img-responsive"
                />
              </div>
            )}
            <div className="col-md-12 widget widget-about">
              <ul>
                <li>{store.hotel.curIntro.name}</li>
                {store.hotel.curIntro.zipcode &&
                  store.hotel.curIntro.zipcode.length && (
                    <li>
                      {intl.formatMessage({ id: 'label.zipcode' })}.{' '}
                      {store.hotel.curIntro.zipcode}
                    </li>
                  )}

                <li>
                  {intl.formatMessage({ id: 'label.address' })}.{' '}
                  {store.hotel.curIntro.address1}
                  <br />
                  {store.hotel.curIntro.address2}
                </li>
                <li>
                  {intl.formatMessage({ id: 'label.fax' })}. {store.hotel.fax}
                </li>
                <li>
                  {intl.formatMessage({ id: 'label.tel' })}. {store.hotel.phone}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- End Footer-Top --> */}

      {/* <!-- Start Footer-Copyrights --> */}
      <div className="footer-copyrights">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <p>©2019 BAROJABA Inc. all rights reserved.</p>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- End Footer-Copyrights --> */}

      <Affix offsetBottom={50}>
        <Button
          type="link"
          className="top-btn"
          onClick={() => window.scrollTo(0, 0)}
        >
          <Icon type="up-circle" />
        </Button>
      </Affix>
    </footer>
  );
});

export default Footer;
