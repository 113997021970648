export const setLang = (_, { current }, { cache }) => {
  window.localStorage.setItem('lang', current);
  cache.writeData({
    data: {
      lang: {
        __typename: 'Lang',
        current,
      },
    },
  });
  window.location.reload();
  return null;
};
