/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Modal } from 'antd';
import { observer, useLocalStore } from 'mobx-react-lite';
import styled from 'styled-components';
import TodayPriceWidget from './TodayPriceWidget';

const StyledModal = styled(Modal)`
  background: transparent !important;
  max-width: 400px;

  .ant-modal-body,
  .ant-modal-content,
  .ant-modal-footer {
    box-shadow: none !important;
    background: transparent !important;
  }

  .ant-modal-footer {
    width: 100%;
    background: rgba(0, 0, 0, 0.7) !important;
    color: white;
    border: none;

    label {
      color: white;
    }
  }
  .ant-modal-content {
    .ant-modal-close-x {
      background: rgba(0, 0, 0, 0.7) !important;
      color: white;
      border-radius: 50%;
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    }
  }
  .ant-modal-body {
    padding: 0;
    padding-top: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const TodayPricePopup = observer(() => {
  const localStore = useLocalStore(() => ({
    visible: false,
  }));

  return (
    <StyledModal
      visible={localStore.visible}
      onCancel={() => (localStore.visible = false)}
      footer={null}
      maskClosable={false}
      centered={true}
      forceRender={true}
    >
      <TodayPriceWidget style={{ width: '100%' }} popupStore={localStore} />
    </StyledModal>
  );
});

export default TodayPricePopup;
