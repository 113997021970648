import { useEffect } from 'react';
import { store } from '../shared/store';

let cachedScripts = {};
export function useScript(srcs) {
  const onScriptLoad = src => () => {
    cachedScripts[src].loaded = true;
    store.forceUpdate = !store.forceUpdate;
  };

  const onScriptError = src => () => {
    delete cachedScripts[src];
  };

  useEffect(() => {
    for (let i = 0; i < srcs.length; i++) {
      let src = srcs[i];

      let scriptLoader = cachedScripts[src];
      if (scriptLoader) {
        if (!scriptLoader.loaded) {
          let script = scriptLoader.script;
          script.addEventListener('load', onScriptLoad(src));
          script.addEventListener('error', onScriptError(src));
        }
      } else {
        // Create script
        let script = document.createElement('script');
        script.src = src;
        script.async = false;

        // Script event listener callbacks for load and error

        script.addEventListener('load', onScriptLoad(src));
        script.addEventListener('error', onScriptError(src));

        // Add script to document body
        document.body.appendChild(script);

        cachedScripts[src] = { loaded: false, script };
      }
    }

    return () => {
      for (const src in cachedScripts) {
        if (cachedScripts[src]) {
          const script = cachedScripts[src].srcipt;
          if (script) {
            script.removeEventListener('load', onScriptLoad(src));
            script.removeEventListener('error', onScriptError(src));
          }
        }
      }
    };
  }, [srcs]);
}
