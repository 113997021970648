import gql from 'graphql-tag';
import {
  reservationFragment,
  memberUserFragment,
} from '../../../shared/fragments';

export const SAVE_MEMBER_USER = gql`
  mutation SaveMemberUser(
    $hotel: ObjectId!
    $data: InputMemberUser!
    $src: String
    $label: String
    $fromEmail: String
  ) {
    saveMemberUser(
      hotel: $hotel
      data: $data
      src: $src
      label: $label
      fromEmail: $fromEmail
    )
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation ForgotPassword(
    $hotel: ObjectId!
    $data: InputForgotPassword
    $src: String
    $label: String
    $fromEmail: String
  ) {
    forgotPassword(
      hotel: $hotel
      data: $data
      src: $src
      label: $label
      fromEmail: $fromEmail
    )
  }
`;

export const DELETE_MEMBER_USER = gql`
  mutation DeleteMemberUser($hotel: ObjectId!, $userId: ObjectId!) {
    deleteMemberUser(hotel: $hotel, userId: $userId)
  }
`;

export const USER_RESERVATIONS = gql`
  ${reservationFragment}
  ${memberUserFragment}
  query UserReservations(
    $hotel: ObjectId
    $memberUser: ObjectId
    $startDate: DateTime
    $endDate: DateTime
    $email: String
    $orderId: String
    $status: String
  ) {
    userReservations(
      hotel: $hotel
      memberUser: $memberUser
      startDate: $startDate
      endDate: $endDate
      email: $email
      orderId: $orderId
      status: $status
    ) {
      ...ReservationFragment
      memberUser(hotel: $hotel) {
        ...MemberUserFragment
      }
    }
  }
`;
