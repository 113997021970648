/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { isBrowser } from 'react-device-detect';

import { store } from '../../../shared/store';
import TextareaView from '../../Common/TextareaView';

const Room = observer(props => {
  const intl = useIntl();

  useEffect(() => {
    if (isBrowser) {
      const timer = setInterval(() => {
        if (window.$('.owl-carousel.rooms').data('owlCarousel')) {
          clearInterval(timer);
          window
            .$('.owl-carousel.rooms')
            .on('translated.owl.carousel', function(e) {
              if (e.item.index > e.item.count) {
                e.relatedTarget.next();
              }
            });
        }
      }, 100);
    }
  }, []);

  return (
    <div id="rooms" {...props}>
      <div className="offer-section container-fluid">
        <div className="container">
          <div className="section-header section-header-2">
            <h3>Room Offer</h3>
          </div>
          <div className="offer-carousel rooms">
            {window.toJS(store.hotel.rooms).map((room, rIdx) => {
              if (room.curFacility) {
                room.facilities = [];
                let idx = 0;
                for (let i = 0; i < room.curFacility.length; i++) {
                  if (
                    room.facilities[idx] &&
                    room.facilities[idx].length === 3
                  ) {
                    idx++;
                  }

                  if (!room.facilities[idx]) {
                    room.facilities[idx] = [];
                  }
                  room.facilities[idx].push(room.curFacility[i].label);
                  if (room.facilities[idx].length === 3) {
                    idx++;
                  }
                }
              }

              return (
                <div
                  className="col-md-12 col-sm-12 col-xs-12"
                  key={`room_list_${rIdx}`}
                >
                  <div className="offer-box">
                    <i>
                      {room.images && room.images[0] && (
                        <img src={room.images[0].url} alt="" />
                      )}
                    </i>
                    <div className="offer-content">
                      <h5>{room.curIntro.name}</h5>
                      <TextareaView single style={{ textAlign: 'left' }}>
                        {room.curIntro.description}
                      </TextareaView>
                      <span>
                        <div className="row">
                          {room.facilities.map((items, itemsIdx) => {
                            if (itemsIdx >= 2) {
                              return null;
                            }
                            return (
                              <div
                                className="col-xs-12"
                                key={`${rIdx}_facilities_${itemsIdx}`}
                              >
                                <ul className="facilities-list">
                                  {items.map((item, itemIdx) => (
                                    <li
                                      key={`${rIdx}_facilities_${itemsIdx}_${itemIdx}`}
                                    >
                                      <span>{item}</span>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            );
                          })}
                        </div>
                      </span>
                      <a
                        className="zoom"
                        href="#!"
                        onClick={store.handleClickMore(room, 'curIntro', true)}
                      >
                        <i className="fa fa-plus"></i>
                      </a>
                      <a
                        href="#!"
                        title={intl.formatMessage({
                          id: 'label.booking',
                        })}
                        className="book-now"
                        onClick={() =>
                          store.goToLink(
                            `https://booking.barojaba.com/?h=${store.hotel._id}`,
                          )
                        }
                      >
                        {intl.formatMessage({
                          id: 'label.booking',
                        })}
                      </a>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
});

export default Room;
