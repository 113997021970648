import React from 'react';
import { observer } from 'mobx-react-lite';

const Parallax1 = observer(() => {
  return (
    <div id="sep1">
      <div className="parallax1"></div>
      <div className="container">
        <div className="sixteen columns">
          <div id="sync1" className="owl-carousel">
            <div className="item twit">
              <div className="icon-twit">
                <img
                  src="/themes/theme0/images/logo1.png"
                  width="40px"
                  alt=""
                />
              </div>
              <p>
                <a href="#!">BAROJABA</a>는 호텔의 의견을 적극 반영하여 최적의
                솔루션을 제공해 드립니다.
              </p>
            </div>
            <div className="item twit">
              <div className="icon-twit">
                <img
                  src="/themes/theme0/images/logo1.png"
                  width="40px"
                  alt=""
                />
              </div>
              <p>
                <a href="#!">BAROJABA</a>는 예약자와 관리자 모두 사용하기 편리한
                시스템을 추구하는 호텔 IT 통합 솔루션 전문 기업입니다.
              </p>
            </div>
            <div className="item twit">
              <div className="icon-twit">
                <img
                  src="/themes/theme0/images/logo1.png"
                  width="40px"
                  alt=""
                />
              </div>
              <p>
                <a href="#!">BAROJABA</a>는 항상 파트너와 함께 발전하기 위해
                노력하고 있습니다.
              </p>
            </div>
          </div>

          <div id="sync2" className="owl-carousel">
            <div className="item"></div>
            <div className="item"></div>
            <div className="item"></div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Parallax1;
