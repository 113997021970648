export const validateToUrl = (intl, msg) => (rule, value, callback) => {
  const regExp = /^(((http(s?)):\/\/)?)([0-9a-zA-Zㄱ-ㅎ가-힣-]+\.)+[a-zA-Z]{2,6}(:[0-9]+)?(\/\S*)?/;
  if (value && !regExp.test(value)) {
    callback(intl ? intl.formatMessage({ id: 'error.homepage.valid' }) : msg);
  } else {
    callback();
  }
};

export const validateToFax = (intl, msg) => (rule, value, callback) => {
  if (value) {
    const tel = value
      .trim()
      .replace(/-/g, '')
      .replace(/\s/g, '');
    let condition = !/^[0-9]{2,3}[0-9]{3,4}[0-9]{4}$/.test(tel);
    if (condition) {
      callback(intl ? intl.formatMessage({ id: 'error.fax.valid' }) : msg);
    } else {
      callback();
    }
  } else {
    callback();
  }
};

export const validateToPhone = (intl, msg) => (rule, value, callback) => {
  if (value) {
    const tel = value
      .trim()
      .replace(/-/g, '')
      .replace(/\s/g, '');
    let condition;
    if (window.localStorage.getItem('lang') === 'kr') {
      condition = !/^010[0-9]{4}[0-9]{4}$/.test(tel);
    } else {
      condition = !/^[0-9]{2,3}[0-9]{3,4}[0-9]{4}$/.test(tel);
    }
    if (condition) {
      callback(intl ? intl.formatMessage({ id: 'error.phone.valid' }) : msg);
    } else {
      callback();
    }
  } else {
    callback();
  }
};

export const validateToArrLen = (intl, msg) => (rule, value, callback) => {
  if (value && !value.length) {
    callback(intl ? intl.formatMessage({ id: 'error.arr.len' }) : msg);
  } else {
    callback();
  }
};
