/* eslint-disable react-hooks/exhaustive-deps, jsx-a11y/anchor-is-valid, no-script-url */
import React from 'react';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';

import { store } from '../../../shared/store';
import TextareaView from '../../Common/TextareaView';

const About = observer(props => {
  const intl = useIntl();

  return (
    <div id="about" {...props}>
      <div className="whychooseus-section container-fluid">
        <div className="container">
          <div className="section-header section-header-2">
            <h3>
              <span>{store.hotel.curIntro.name}</span>
              <br />
              <span>{store.hotel.stars}</span>
            </h3>
            <TextareaView>{store.hotel.curIntro.description}</TextareaView>
          </div>
          {window.toJS(store.hotel.homepage.curAbout).map((item, idx) => (
            <div className="col-md-6 col-sm-12 col-xs-6" key={`about_${idx}`}>
              <div className="choose-us-box">
                <div className="col-md-12 col-sm-12 col-xs-12 no-padding choose-us-block">
                  <div className="col-md-5 col-sm-4 col-xs-12 choose-us-cover">
                    <i>
                      <img src={item.images[0].url} alt="choose-us" />
                    </i>
                  </div>
                  <div className="col-md-7 col-sm-8 col-xs-12 choose-us-content">
                    <h5>{item.post.title}</h5>
                    <TextareaView style={{ textAlign: 'left' }} clamp="3">
                      {item.post.content}
                    </TextareaView>
                    <a
                      href="#!"
                      title="read-more"
                      onClick={store.handleClickMore(item, 'post')}
                    >
                      {intl.formatMessage({
                        id: 'label.readMore',
                      })}
                    </a>
                    {item.url && (
                      <a
                        href="#!"
                        onClick={() => store.goToLink(item.url)}
                        style={{ marginLeft: '15px' }}
                      >
                        {intl.formatMessage({ id: 'label.goToLink' })}
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
});

export default About;
