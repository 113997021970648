export const setTheme = (_, { themeData }, { cache }) => {
  cache.writeData({
    data: {
      theme: {
        __typename: 'Theme',
        themeData: JSON.stringify(themeData),
      },
    },
  });
  return null;
};
