import React from 'react';

export const useForceUpdate = () => {
  const [value, set] = React.useState(true); // boolean state
  return () => set(!value); // toggle the state to force render
};

export const randomString = stringLen => {
  const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz';
  let randomstring = '';
  for (let i = 0; i < stringLen; i++) {
    const rnum = Math.floor(Math.random() * chars.length);
    randomstring += chars.substring(rnum, rnum + 1);
  }
  return randomstring;
};
