/* eslint-disable react-hooks/exhaustive-deps, jsx-a11y/anchor-is-valid, no-script-url */
import React, { useEffect } from 'react';
import { Card } from 'antd';
import { observer } from 'mobx-react-lite';
import Swiper from 'swiper';
import { isMobile } from 'react-device-detect';

import { store } from '../../../shared/store';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;

  .swiper-container {
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;

    .swiper-wrapper {
      max-height: 100% !important;
    }
  }
  .swiper-slide {
    width: 100%;
    text-align: center;
    font-size: 18px;
    background: #fff;
    height: calc((100% - 30px) / 2);
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  a {
    &:hover {
      img {
        transform: scale(1.2);
      }
    }
  }

  figcaption {
    background: rgba(255, 255, 255, 0.5);
  }
`;

const StyledImg = styled.img`
  transform: scale(1);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
`;

const Instagram = observer(() => {
  useEffect(() => {
    const opt = {
      // loop: true,
      // init: false,
      slidesPerView: 2,
      slidesPerColumn: 2,
      slidesPerColumnFill: 'row',
      spaceBetween: 5,
      grabCursor: true,
      autoplay: {
        delay: 3000,
      },
      pagination: {
        el: '#instagram .swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '#instagram .swiper-button-next',
        prevEl: '#instagram .swiper-button-prev',
      },
    };
    if (!isMobile) {
      opt.breakpoints = {
        1024: {
          slidesPerView: 4,
          slidesPerColumn: 2,
          slidesPerColumnFill: 'row',
          spaceBetween: 5,
        },
      };
    }
    new Swiper('#instagram .swiper-container', opt);
  }, []);

  return (
    <Wrapper id="instagram">
      <Card size="small">
        <div className="swiper-container">
          <div className="swiper-wrapper">
            {window.toJS(store.hotel.instagrams).map((item, idx) => (
              <div className="swiper-slide" key={`instagram_list_${idx}`}>
                <a href={item.url} target="_blank">
                  <figure className="imghvr-fade">
                    <StyledImg src={item.image} alt="" />
                    <figcaption />
                  </figure>
                </a>
              </div>
            ))}
          </div>
          <div className="swiper-pagination" />
          <div className="swiper-button-next" />
          <div className="swiper-button-prev" />
        </div>
      </Card>
    </Wrapper>
  );
});

export default Instagram;
