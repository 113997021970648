/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect } from 'react';
import Helmet from 'react-helmet';
import { Select, Affix } from 'antd';
import { observer, useLocalStore } from 'mobx-react-lite';
import styled from 'styled-components';
import { useQuery } from 'react-apollo-hooks';
import Iframe from 'react-iframe';

import { GET_HOTEL_META } from '../../../shared/queries';
import Loading from '../../Common/Loading';

const { Option } = Select;

const Wrapper = styled.div`
  .frame {
    position: absolute;
    top: 0;
    border: none;
    height: 100vh;
  }
`;

const Themes = observer(props => {
  const localStore = useLocalStore(() => ({
    themes: [],
    selectedTheme: null,
    comp: '',
  }));

  const {
    data: { hotelMeta },
    loading,
  } = useQuery(GET_HOTEL_META, {
    variables: { key: 'homepageThemes' },
    fetchPolicy: 'network-only',
  });

  const handleChange = useCallback(value => {
    localStore.selectedTheme = value;
  }, []);

  useEffect(() => {
    if (hotelMeta && hotelMeta.value) {
      localStore.themes = hotelMeta.value;
    }
  }, [hotelMeta]);

  if (loading) {
    return <Loading />;
  }

  return (
    <Wrapper>
      <Helmet>
        <link rel="shortcut icon" href="/favicon.ico" type="image/x-icon" />
        <meta
          name="description"
          content={`호텔 다이렉트 부킹 활성화를 위한 최고의 솔루션. BAROJABA는 예약자와 관리자 모두 사용하기 편리한 시스템을 추구하는 호텔 IT 통합 솔루션 전문 기업입니다.`}
        />
        <title>BAROJABA Inc.</title>
      </Helmet>

      {!props.match.params.theme && (
        <Affix offsetTop={0}>
          <Select
            value={localStore.selectedTheme}
            style={{ width: 120, zIndex: 2147483647 }}
            onChange={handleChange}
          >
            {window.toJS(localStore.themes).map((item, idx) => (
              <Option value={String(item.value)} key={`theme_${idx}`}>
                {item.label}
              </Option>
            ))}
          </Select>
        </Affix>
      )}

      {localStore.selectedTheme && (
        <Iframe
          // url={`http://localhost:3000/themes/${localStore.selectedTheme}`}
          url={`https://www.barojaba.com/themes/${localStore.selectedTheme}`}
          width="100%"
          className="frame"
          position="absolute"
        />
      )}
    </Wrapper>
  );
});

export default Themes;
