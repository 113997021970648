/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import ScriptLoader from 'react-script-loader-hoc';
import { observer, useLocalStore } from 'mobx-react-lite';
import { isBrowser } from 'react-device-detect';

import { store } from '../../../shared/store';
import { useScript } from '../../../utils/useScript';
import Loading from '../../Common/Loading';
import ThemeStyle from './ThemeStyle';
import Header from './Header';
import Footer from './Footer';
import Banner from './Banner';
import Booking from './Booking';
import About from './About';
import SpecialOffer from './SpecialOffer';
import Room from './Room';
import Facility from './Facility';
import HashTag from './HashTag';
import Location from '../../Common/Location';
import HeaderTop from '../../Common/HeaderTop';
import GlobalStyle from './GlobalStyle';
import TodayPricePopup from '../../Common/TodayPrice/TodayPricePopup';

const scripts = [
  '/themes/theme3/js/jquery/jquery-migrate.min.js',
  '/themes/theme3/js/jquery/ui/core.min.js',
  '/themes/theme3/js/jquery/ui/datepicker.min.js',
  '/themes/theme3/plugins/gdlr-hostel/gdlr-hotel.js',
  '/themes/theme3/plugins/superfish/js/superfish.js',
  '/themes/theme3/js/hoverIntent.min.js',
  '/themes/theme3/plugins/dl-menu/jquery.dlmenu.js',
  '/themes/theme3/js/jquery.easing.js',
  '/themes/theme3/js/jquery.transit.min.js',
  '/themes/theme3/plugins/fancybox/jquery.fancybox.pack.js',
  '/themes/theme3/plugins/fancybox/helpers/jquery.fancybox-media.js',
  '/themes/theme3/plugins/fancybox/helpers/jquery.fancybox-thumbs.js',
  '/themes/theme3/plugins/flexslider/jquery.flexslider.js',
  '/themes/theme3/js/jquery.isotope.min.js',
  '/themes/theme3/js/gdlr-script.js',
  '/themes/theme3/plugins/masterslider/public/assets/js/masterslider.min.js',
];

const ContentContainer = observer(() => {
  useScript(scripts);

  useEffect(() => {
    window.$('body').data('rsssl', '1');
    window
      .$('body')
      .addClass(
        'home page-template-default page page-id-3720 _masterslider _msp_version_3.2.2  hotelmaster-button-classic-style header-style-2 hotelmaster-classic-style hotelmaster-single-classic-style',
      );
  }, []);

  if (!store.hotel.homepage || !store.hotel.homepage.menus) return <Loading />;

  return (
    <div
      className="body-wrapper float-menu gdlr-icon-light gdlr-header-solid"
      data-home="#"
    >
      <TodayPricePopup />
      <HeaderTop btncolor="#d9d9d9" />
      <Header />
      <div id="gdlr-header-substitute"></div>
      <div className="content-wrapper">
        <div className="gdlr-content">
          <div className="with-sidebar-wrapper">
            <Banner />
            <Booking />
            {store.hotel.homepage.menus['about'] && <About />}

            {store.hotel.homepage.menus['specialOffers'] && <SpecialOffer />}

            {store.hotel.homepage.menus['rooms'] && <Room />}

            {store.hotel.homepage.menus['facilities'] && <Facility />}

            {store.hotel.instagrams && store.hotel.instagrams.length ? (
              <HashTag />
            ) : null}

            <Location />
          </div>
        </div>
        <div className="clear"></div>
      </div>
      <Footer />
    </div>
  );
});

const Container = observer(({ scriptsLoadedSuccessfully }) => {
  const localStore = useLocalStore(() => ({
    loaded: false,
  }));

  useEffect(() => {
    if (scriptsLoadedSuccessfully) {
      setTimeout(() => {
        window.$ = window.jQuery;
        localStore.loaded = true;
      }, 100);
    }
  }, [scriptsLoadedSuccessfully]);

  if (!scriptsLoadedSuccessfully) return <Loading />;

  return (
    <>
      <GlobalStyle />
      <ThemeStyle>
        <Helmet>
          {store.hotel.logo && (
            <link
              rel="shortcut icon"
              href={store.hotel.logo.url}
              type="image/x-icon"
            />
          )}
          {!/localhost/.test(window.location.host) &&
          store.hotel.images &&
          store.hotel.images[0] ? (
            <>
              <meta property="og:image" content={store.hotel.images[0].url} />
              <meta
                property="og:description"
                content={`${store.hotel.name}에 오신 것을 환영합니다. 홈페이지에서 예약하는 것이 가장 저렴합니다.`}
              />
              <meta
                property="og:title"
                content={`${store.hotel.name} 공식 홈페이지`}
              />
            </>
          ) : (
            <meta
              name="description"
              content={`${store.hotel.name}에 오신 것을 환영합니다. 홈페이지에서 예약하는 것이 가장 저렴합니다.`}
            />
          )}
          <title>{store.hotel.curIntro.name}</title>
          <script type="text/javascript">{`
            var objectL10n = {
              "closeText": "Done",
              "currentText": "Today",
              "monthNames": ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
              "monthNamesShort": ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
              "monthStatus": "Show a different month",
              "dayNames": ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
              "dayNamesShort": ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
              "dayNamesMin": ["S", "M", "T", "W", "T", "F", "S"],
              "firstDay": "1"
            };
          `}</script>
          <style>
            {`
              body, input, textarea, select, .gdlr-reservation-room .gdlr-reservation-room-info a {
                font-family: 'Merriweather' !important;
              }
            `}
          </style>
        </Helmet>
        {localStore.loaded && <ContentContainer />}
      </ThemeStyle>
    </>
  );
});

export default ScriptLoader(
  '/themes/theme3/js/jquery/jquery.js',
  '/themes/theme3/plugins/dl-menu/modernizr.custom.js',
)(Container);
