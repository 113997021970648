import styled, { css } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

const Style = css`
  @import url('/themes/theme1/css/style.css');
  @import url('/themes/theme1/css/owl.carousel.css');
  @import url('https://fonts.googleapis.com/css?family=Montserrat:400,700');
  @import url('https://fonts.googleapis.com/css?family=Open+Sans');
  @import url('https://fonts.googleapis.com/css?family=Libre+Baskerville:400italic');

  a {
    color: #333 !important;
  }

  section {
    background: transparent !important;
  }

  main > div,
  main > section {
    background-color: white !important;
  }

  main > div:nth-child(2n),
  main > section:nth-child(2n) {
    background-color: #f0f0f0 !important;
  }

  /* Header */
  .contact-info.custom-list.list-inline {
    white-space: nowrap;
  }

  #header {
    z-index: 1;
  }
  .header-logo {
    z-index: 1;
  }
  .header-logo .header-logo-inner {
    height: 130px !important;
  }
  .header-btn {
    padding: 0 20px;
    font-size: 12px;
    text-transform: uppercase;
    cursor: pointer;
    border: 1px solid #ededed;
    border-width: 0 1px;
    line-height: 20px;
  }
  .header-btn .fa {
    margin-right: 10px;
  }
  .header-nav ul li {
    white-space: nowrap;
  }

  /* Banner */
  .banner-search-inner {
    padding: 0 !important;
  }
  .ui-datepicker-calendar tr .ui-datepicker-week-end:first-child {
    color: #e74c3c;
  }
  .ui-datepicker-calendar tr .ui-datepicker-week-end:last-child {
    color: #2980b9;
  }
  .ui-datepicker .ui-datepicker-calendar tbody .ui-state-default {
    color: #333;
  }
  .ui-state-disabled,
  .ui-state-disabled span {
    color: #c8c8c8 !important;
  }

  /* about */
  .overlay-shadow {
    background-color: transparent !important;
  }
  .overlay-shadow a {
    color: white !important;
    background-color: rgba(0, 0, 0, 0.6) !important;
  }
  .overlay-shadow a:hover {
    color: black !important;
    background-color: rgba(255, 255, 255, 0.6) !important;
  }

  /* Room, Product List */
  .tab-title-list {
    display: none;
  }
  .custom-list input::placeholder {
    color: white;
  }
  .thumbnail-slider {
    height: auto;
  }
  .listing-room-content header {
    padding: 0 !important;
  }
  .facilities-list li {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    float: left;
    width: 33%;
  }
  .sidebar ul {
    max-height: 600px;
    overflow-y: auto;
  }
  .sidebar ul li {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0 !important;
  }
  .sidebar .thumbnail {
    height: 35px !important;
  }
  .sidebar .title {
    width: 100%;
    padding: 0 !important;
    text-align: center;
  }
  #facilities section {
    background-color: #f0f0f0;
  }

  .custom-listing
    .listing-content
    .listing-room-list
    .listing-room-content
    .btn.btn-gray-dark {
    display: block !important;
  }
  .custom-listing .listing-content .listing-room-list {
    min-height: auto;
  }
  .custom-listing .listing-content .listing-room-list .thumbnail {
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .custom-listing
    .listing-content
    .listing-room-list
    .thumbnail
    .thumbnail-slider
    .owl-wrapper-outer {
    display: flex;
    height: 100%;
  }
  .custom-listing .owl-wrapper,
  .custom-listing .owl-item {
    display: flex !important;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
  }
  .custom-listing .thumbnail img {
    max-height: 200px;
  }
  .btn-block {
    width: 100% !important;
  }
  .booking-btn {
    margin-bottom: 15px;
  }

  #listing .listing-content .listing-room-list .thumbnail img {
    max-width: 200px !important;
    max-height: 200px !important;
    width: auto !important;
    height: auto !important;
    margin: 0 auto !important;
  }

  #facilities .thumbnail img {
    max-width: 100% !important;
    max-height: 350px !important;
  }

  /* Footer */
  #footer .footer-top .widget {
    color: #ffffff;
    opacity: 0.3;
    margin-bottom: 10px;
  }
  #footer ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  #footer ul li {
    margin-right: 15px;
  }

  /* Swiper */
  .swiper-container .swiper-wrapper {
    max-height: 400px !important;
    display: flex;
    justify-content: flex-start;
    align-items: center !important;
  }
  .swiper-container .swiper-wrapper .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .swiper-container .swiper-wrapper .swiper-slide img {
    max-height: 400px !important;
    max-width: 100% !important;
    width: auto !important;
    height: auto !important;
  }

  .ant-calendar-input-wrap,
  .ant-calendar-input {
    display: none;
  }
  .ant-calendar-footer {
    display: none;
  }

  .top-btn {
    float: right;
    opacity: 0.7;
  }
  .top-btn:hover {
    opacity: 1;
  }
  .top-btn i {
    width: 35px;
    height: 35px;
    font-size: 35px;
    background: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ${breakpoint('xs', 'lg')`
    .textarea-view {
      text-align: center;
      
      p {
        text-align: center !important;
      }
    }
  `}
`;

const ThemeStyle = styled.div`
  ${Style}
`;

export default ThemeStyle;
