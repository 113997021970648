import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { store } from '../../../shared/store';

const Footer = observer(() => {
  const menus = useCallback(() => {
    const value = [];
    const menus = window.toJS(store.hotel.homepage.menus);
    if (menus['about']) {
      value.push(
        <li key="menu_about">
          <a href="#about">About</a>
        </li>,
      );
    }
    if (menus['specialOffers']) {
      value.push(
        <li key="menu_specialOffers">
          <a href="#specialOffers">Special Offers</a>
        </li>,
      );
    }
    if (menus['rooms']) {
      value.push(
        <li key="menu_rooms">
          <a href="#rooms">Rooms</a>
        </li>,
      );
    }
    if (menus['facilities']) {
      value.push(
        <li key="menu_facilities">
          <a href="#facilities">Facilities</a>
        </li>,
      );
    }
    return value;
  }, []);

  return (
    <footer id="footer-main" className="footer-main container-fluid no-padding">
      <div className="top-footer">
        <div className="container">
          <aside className="col-md-4 col-sm-6 col-xs-6 ftr-widget widget_about text-center">
            <img src={store.hotel.logo.url} alt="logo" />
          </aside>
          <aside className="col-md-offset-1 col-md-3 col-sm-6 col-xs-6 ftr-widget widget_hours">
            <h3 className="widget-title">Check-In/Out</h3>
            <p>
              Check-In <span>-</span> {store.hotel.checkInTime}
            </p>
            <p>
              Check-Out <span>-</span> {store.hotel.checkOutTime}
            </p>
          </aside>
          <aside className="col-md-4 col-sm-6 col-xs-6 ftr-widget widget_destination">
            <h3 className="widget-title">Address</h3>
            <p>{store.hotel.zipcode}</p>
            <p>{store.hotel.curIntro.address1}</p>
            <p>{store.hotel.curIntro.address2}</p>
            {store.hotel.phone && store.hotel.phone.length ? (
              <p>Tel. {store.hotel.phone}</p>
            ) : null}
            {store.hotel.fax && store.hotel.fax.length ? (
              <p>Fax. {store.hotel.fax}</p>
            ) : null}
          </aside>
        </div>
      </div>

      <div className="bottom-footer container-fluid">
        <div className="container">
          <div className="col-md-4 col-sm-12 col-xs-12 coyright-content">
            <p>©2019 BAROJABA Inc. all rights reserved.</p>
          </div>
          <div className="col-md-8 col-sm-12 col-xs-12">
            <nav className="navbar navbar-default ow-navigation">
              <div className="navbar-header">
                <button
                  aria-controls="ftr-navbar"
                  aria-expanded="false"
                  data-target="#ftr-navbar"
                  data-toggle="collapse"
                  className="navbar-toggle collapsed"
                  type="button"
                >
                  <span className="sr-only">Toggle navigation</span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                </button>
              </div>
              <div className="navbar-collapse collapse" id="ftr-navbar">
                <ul className="nav navbar-nav">
                  <li className="active">
                    <a title="Home" href="/">
                      Home
                    </a>
                  </li>
                  {menus()}
                  {store.hotel.instagrams && store.hotel.instagrams.length ? (
                    <li>
                      <a href="#hashtag">HashTags</a>
                    </li>
                  ) : null}
                  <li>
                    <a href="#location">Location</a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </footer>
  );
});

export default Footer;
