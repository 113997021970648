import React from 'react';
import PropTypes from 'prop-types';
import { Theme0, Theme1, Theme2, Theme3 } from '../Themes';

const ThemeComp = props => {
  let comp;
  switch (props.theme) {
    case 0:
      comp = <Theme0 />;
      break;
    case 1:
      comp = <Theme1 />;
      break;
    case 2:
      comp = <Theme2 />;
      break;
    case 3:
      comp = <Theme3 />;
      break;
    default:
      break;
  }
  return comp;
};

ThemeComp.propTypes = {
  theme: PropTypes.number,
};

export default ThemeComp;
