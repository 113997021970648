/* eslint-disable react-hooks/exhaustive-deps*/
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-relativetimeformat/polyfill';

import React, { Fragment } from 'react';
import { graphql } from 'react-apollo';
import { RawIntlProvider, createIntl } from 'react-intl';

import AppPresenter from './AppPresenter';
import { GET_LOGIN_DATA } from './AppQueries';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from '../../global-styles';
import locale from '../../locale';
import { IntlContextProvider } from '../../utils/intl';
import theme from '../../utils/styled-theme';

const AppContainer = ({ data }) => {
  const intl = createIntl({
    locale: navigator.language,
    messages: locale[data.lang.current],
  });
  return (
    <ThemeProvider theme={theme}>
      <RawIntlProvider value={intl} key={data.lang.current}>
        <IntlContextProvider>
          <Fragment>
            <GlobalStyle />
            <AppPresenter {...data} />
          </Fragment>
        </IntlContextProvider>
      </RawIntlProvider>
    </ThemeProvider>
  );
};

export default graphql(GET_LOGIN_DATA)(AppContainer);
