/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useCallback } from 'react';
import { observer, useLocalStore } from 'mobx-react-lite';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { useIntl } from 'react-intl';

import { store } from '../../../shared/store';
import TextareaView from '../../Common/TextareaView';
import StyledDivider from '../../StyledComp/StyledDivider';

const Wrapper = styled.div`
  #listing {
    background: #fff;
  }

  button {
    width: 100%;
  }

  .sidebar {
    display: block;
  }

  .listing-facitilities {
    text-align: left;
  }

  ${breakpoint('xs', 'lg')`  
    .sidebar {
      display: none;
    }
    .listing-facitilities {
      text-align: center;
    }
  `}
`;

const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  div,
  textarea {
    text-align: left;
  }

  ${breakpoint('xs', 'lg')`
    display: block;
    div, textarea {
      text-align: center;
    }
  `}
`;

const Room = observer(props => {
  const intl = useIntl();

  const localStore = useLocalStore(() => ({
    isScrollToFixed: false,
  }));

  // useEffect(() => {
  //   if (window.$.fn.scrollToFixed && !localStore.isScrollToFixed) {
  //     window.$(document).ready(function() {
  //       window.$('#room_sidebar').scrollToFixed({
  //         marginTop: 10,
  //         limit:
  //           window.$('#room').offset().top +
  //           window.$('#room').outerHeight() -
  //           window.$('#room_sidebar').outerHeight() -
  //           100,
  //         preAbsolute: function() {
  //           setTimeout(() => {
  //             window
  //               .$('#room_sidebar')
  //               .css(
  //                 'top',
  //                 window.$('#room').outerHeight() -
  //                   window.$('#room_sidebar').outerHeight() -
  //                   160,
  //               );
  //             window.$('#room_sidebar').css('left', '15px');
  //           }, 5);
  //         },
  //       });
  //     });

  //     localStore.isScrollToFixed = true;
  //   }
  // }, [store.forceUpdate]);

  return (
    <Wrapper id="room" {...props}>
      <div className="custom-listing">
        <section id="listing">
          <div className="container">
            <div className="row">
              <div
                className="sidebar col-md-3"
                style={{ position: 'relative' }}
              >
                <div className="special-offers sidebar" id="room_sidebar">
                  <h5 className="title-section">ROOMS</h5>
                  <div className="offers-content">
                    <ul className="custom-list">
                      {window.toJS(store.hotel.rooms).map((room, rIdx) => (
                        <div key={`sidebar_room_${rIdx}`}>
                          <li>
                            <div className="thumbnail">
                              {room.images[0] && (
                                <img
                                  src={room.images[0].url}
                                  alt=""
                                  className="img-responsive"
                                />
                              )}
                            </div>
                            <h5 className="title">
                              <a href={`#room_${rIdx + 1}`}>
                                {room.curIntro.name}
                              </a>
                            </h5>
                          </li>
                          <StyledDivider margin="15px 0" />
                        </div>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="listing-content col-md-9">
                <div className="listing-pagination">
                  <h5 className="title pull-left">Available Rooms</h5>
                </div>

                {window.toJS(store.hotel.rooms).map((room, rIdx) => {
                  if (room.curFacility) {
                    room.facilities = [];
                    let idx = 0;
                    for (let i = 0; i < room.curFacility.length; i++) {
                      if (
                        room.facilities[idx] &&
                        room.facilities[idx].length === 3
                      ) {
                        idx++;
                      }

                      if (!room.facilities[idx]) {
                        room.facilities[idx] = [];
                      }
                      room.facilities[idx].push(room.curFacility[i].label);
                      if (room.facilities[idx].length === 3) {
                        idx++;
                      }
                    }
                  }

                  return (
                    <div
                      className="listing-room-list"
                      id={`room_${rIdx + 1}`}
                      key={`room_list_${rIdx}`}
                    >
                      <div className="thumbnail">
                        <div className="thumbnail-slider">
                          {room.images.map((image, imgIdx) => (
                            <img
                              src={image.url}
                              alt=""
                              className="img-responsive"
                              key={`${rIdx}_image_${imgIdx}`}
                            />
                          ))}
                        </div>
                      </div>
                      <div className="listing-room-content">
                        <div className="row">
                          <div className="col-md-12">
                            <header>
                              <Row className="row">
                                <div className="col-md-9">
                                  <div
                                    className="pull-left"
                                    style={{ width: '100%' }}
                                  >
                                    <h5
                                      className="title"
                                      style={{ width: '100%' }}
                                    >
                                      <a href="#!">{room.curIntro.name}</a>
                                    </h5>
                                    <TextareaView
                                      single
                                      style={{ textAlign: 'left' }}
                                    >
                                      {room.curIntro.description}
                                    </TextareaView>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <button
                                    className="btn btn-transparent-gray"
                                    onClick={store.handleClickMore(
                                      room,
                                      'curIntro',
                                      true,
                                    )}
                                  >
                                    {intl.formatMessage({
                                      id: 'label.readMore',
                                    })}
                                  </button>
                                </div>
                              </Row>
                            </header>
                            <div className="listing-facitilities">
                              <div className="row">
                                <div className="col-md-9 col-sm-9">
                                  <div className="row">
                                    {room.facilities.map((items, itemsIdx) => {
                                      if (itemsIdx >= 2) {
                                        return null;
                                      }
                                      return (
                                        <div
                                          className="col-xs-12"
                                          key={`${rIdx}_facilities_${itemsIdx}`}
                                        >
                                          <ul className="facilities-list custom-list">
                                            {items.map((item, itemIdx) => (
                                              <li
                                                key={`${rIdx}_facilities_${itemsIdx}_${itemIdx}`}
                                              >
                                                <span>{item}</span>
                                              </li>
                                            ))}
                                          </ul>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>

                                <div className="col-md-3 col-sm-3">
                                  <button
                                    className="btn btn-gray-dark booking-btn"
                                    onClick={() =>
                                      store.goToLink(
                                        `https://booking.barojaba.com/?h=${store.hotel._id}`,
                                      )
                                    }
                                  >
                                    {intl.formatMessage({
                                      id: 'label.booking',
                                    })}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </section>
      </div>
    </Wrapper>
  );
});

export default Room;
